<template>
	<el-dialog width="30%" title="拖动下方滑块完成拼图" :visible.sync="dialog_visible" @opened="opened" @closed="closed">
		<div class="verfiybg" ref="container" :style="'background-image: url(' + src + ');'">
			<div class="vchip" :style="'width: ' + chipWidth + 'px; height: ' + chipHeight + 'px; left: ' + left + 'px;'"></div>
			<div class="vchip" :style="'width: ' + chipWidth + 'px; height: ' + chipHeight + 'px; left: ' + move + 'px; background-image: url(' + src + '); background-size: ' + width + 'px; background-position: -' + left + 'px -80px;'"></div>
			<div class="vbar" v-if="succeed">
				<div class="vtext succeed">{{msg}}</div>
			</div>
			<div class="vbar" v-else>
				<div class="vtext">{{msg}}</div>
				<div class="df aic jcfe vbtn pr20" :style="'cursor: grab; box-sizing: border-box; width: ' + slider + 'px'" @mousedown="mousedown" @mousemove="mousemove" @mouseup="mouseup">
					<i class="el-icon-right fs30 cff"></i>
				</div>
			</div>
		</div>
	</el-dialog>
</template>



<script>
	// import Config from "@/utils/config";
	export default {
		props: {
			// 碎片宽度
			chipWidth: {
				type: Number,
				default: 50
			},
			// 碎片高度
			chipHeight: {
				type: Number,
				default: 50
			},
			// 误差
			abs: {
				type: Number,
				default: 1
			},
			// 倒计时s
			down: {
				type: Number,
				default: 99
			},
			// 背景图
			src: {
				type: String,
				default: 'http://www.bishantang.com.cn/wp-content/themes/twentytwentythree/assets/images/index/part4_img1.jpg'
			},
			text: {
				type: String,
				default: '请拖动滑块完成拼图验证发送验证码'
			}
		},
		computed: {
			// 百分比转px
			left () {
				return Math.round(this.width * (this.percent/100));
			}
		},
		data () {
			return {
				msg: '',
				move: 0,
				time: 0,
				start: 0,
				width: 0,
				timer: null,
				height: 0,
				slider: 0,
				percent: 0,
				succeed: false,
				is_down: false,
				dialog_visible: false
			}
		},
		methods: {
			open () {
				this.dialog_visible = true;
			},
			opened () {
				const { offsetWidth } = this.$refs.container;
				this.width = offsetWidth;
				this.height = offsetWidth;
				this.reload(false, this.text);
			},
			closed () {
				if (this.succeed) return false;
				this.$emit('cancel');
			},
			upPercent () {
				this.percent = Math.floor(Math.random() * (80 - 20 + 1)) + 20;
			},
			reload (succeed = false, t = '') {
				this.msg = t || this.text;
				this.succeed = succeed;
				if (!succeed) {
					this.move = 0;
					this.start = 0;
					this.slider = this.chipWidth;
					this.upPercent();
				}
			},
			mousedown (e) {
				this.start = e.clientX;
				this.is_down = true;
			},
			mousemove (e) {
				if (!this.is_down) return false;
				const move = e.clientX - this.start;
				if (move < 0) return false;
				const slider = move + this.chipWidth;
				if (slider < this.chipWidth) return false;
				if (slider < this.width) {
					this.move = move;
					this.slider = slider;
				} else {
					this.move = this.width - this.chipWidth;
					this.slider = this.width;
				}
			},
			mouseup () {
				this.is_down = false;
				// // 松开验证坐标是否一致
				const abs = Math.abs(this.move - this.left);
				if (abs > this.abs) {
					this.move = 0;
					this.slider = this.chipWidth;
					return false;
				}
				this.succeed = true;
				this.dialog_visible = false;
				this.$emit('pass');
			}
		}
	}
</script>

<style>
	.verfiybg {
		width: 100%;
		height: 300px;
		position: relative;
		background-size: cover;
		background-color: #f1f1f1;
		background-position: center top;
	}
	.verfiybg .vchip {
		background-color: rgba(0, 0, 0, 1);
		position: absolute;
		top: 80px;
		left: 0;
	}
	.verfiybg .vbar {
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.vbar .vbtn {
		background-color: #3c9cff;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
	}
	.vbar .vtext {
		color: #999;
		font-size: 16px;
		text-align: center;
		line-height: 50px;
	}
	.vbar .vtext.succeed {
		color: #fff;
	}
	.verfiybg .vreload {
		position: absolute;
		top: 20px;
		right: 20px;
	}
</style>
