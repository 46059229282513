<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item v-if="admin_user.shop_id">
					<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item v-else>
					<el-select v-model="params.shop_id" placeholder="城市站点" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="goods_type_id">
					<el-select v-model="params.goods_type_id" placeholder="选择类型" filterable clearable>
						<el-option v-for="type in goods_types" :key="type.id" :label="type.name" :value="type.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getPerformances({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table ref="performances_table" class="scroll-wrapper absolute" height="100%" :data="performances" :size="theme.size">
					<el-table-column type="selection" width="42"></el-table-column>
					<el-table-column label="归属部门" min-width="140" v-if="admin_user.shop_id">
						<template slot-scope="scope">{{scope.row | format_department(oa_users, oa_departments)}}</template>
					</el-table-column>
					<el-table-column label="城市站点" min-width="100" v-else :formatter="(r) => { return r.shop ? r.shop.name : theme.group; }"></el-table-column>
					<el-table-column label="业务专员" min-width="140">
						<template slot-scope="scope">{{scope.row | format_user(oa_users)}}</template>
					</el-table-column>
					<el-table-column prop="client_fee.no" label="回款流水" min-width="140"></el-table-column>
					<el-table-column prop="goods_type.name" label="产品类型"></el-table-column>
					<el-table-column prop="money" label="金额">
						<el-link slot-scope="scope" type="primary" :disabled="!$utils.update('performances')" @click="performance = {...scope.row, client_fee_no: scope.row.client_fee ? scope.row.client_fee.no : '', money: scope.row.money/100}; performances_dialog = true;">{{scope.row.money/100}} 元</el-link>
					</el-table-column>
					<el-table-column prop="updated_at" label="更新时间" min-width="160"></el-table-column>
					<el-table-column prop="created_at" label="创建时间" min-width="160"></el-table-column>
					<el-table-column prop="name" label="操作" width="80">
						<el-dropdown slot="header" @command="batchAction">
							<el-button type="text">操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="create" :disabled="!$utils.create('performances')">添加绩效</el-dropdown-item>
								<el-dropdown-item command="export">导出选中</el-dropdown-item>
								<el-dropdown-item command="delete">删除选中</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<template slot-scope="scope">
							<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete('performances') || scope.row.deleted_at">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getPerformances({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getPerformances({ ...params, page: v }) }"
			background>
		</el-pagination>
		<template slot="other">
			<el-dialog :title="performance.id ? '编辑绩效' : '创建绩效'" width="380px" :visible.sync="performances_dialog" @closed="performance = {}" append-to-body destroy-on-close>
				<el-form label-width="60px" ref="performance_form" :model="performance" :rules="performance_rules" :size="theme.size" status-icon>
					<el-alert type="warning" style="margin-top: -20px; margin-bottom: 20px;">
						<div class="lh1_5">
							<p>绩效默认由系统客户回款审核通过后自动计算创建，</p>
							<p>若计算有误或其他特殊要求才手动执行增删改纠正。</p>
						</div>
					</el-alert>
					<el-form-item label="回款" prop="client_fee_no">
						<el-input v-model="performance.client_fee_no" placeholder="请输入回款流水" clearable></el-input>
					</el-form-item>
					<el-form-item label="类型" prop="goods_type_id">
						<el-select v-model="performance.goods_type_id" placeholder="请选择类型" filterable clearable>
							<el-option label="无" :value="0"></el-option>
							<el-option v-for="type in goods_types" :key="type.id" :label="type.name" :value="type.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="专员" prop="user_id">
						<el-select v-model="performance.user_id" placeholder="请选择专员" filterable clearable>
							<el-option v-for="user in users" :key="user.id" :label="oa_users[user.id] ? oa_users[user.id].name : user.name" :value="user.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="金额" prop="money">
						<el-input-number v-model="performance.money" :precision="2" :step="0.01" :min="0.01" :max="1655.35" label="金额"></el-input-number>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('performance_form')">{{performance.id ? '提交保存' : '提交创建'}}</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</template>
	</main-table-a>
</template>


<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		computed: {
			...mapState(['theme', 'admin_user', 'oa_users', 'oa_departments', 'goods']),
			tree_departments () {
				return [];
				// return this.$utils.buildTree(this.oa_departments||[]);
			},
			goods_types () {
				return this.goods.goods_types || [];
			}
		},
		filters: {
			format_user ({ user }, users) {
				if (!user) return '公海';
				if (!users[user.id]) return user.name;
				return users[user.id].name;
			},
			format_department ({ user }, users, departments) {
				if (!user) return '公海';
				if (!users[user.id]) return '未关联OA';
				const { main_department } = users[user.id];
				if (!departments[main_department]) return '未知部门';
				return departments[main_department].name;
			}
		},
		methods: {
			onDelete ({id, user}) {
				this.$confirm('删除后不能恢复', '确定要删除【'+(this.oa_users[user.id] ? this.oa_users[user.id].name : user.name)+'】绩效目标吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_PERFORMANCES, {params: {action: 'delete', id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getPerformances(this.params);
					this.$message.success(msg);
				})
			},
			async getPerformances (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_PERFORMANCES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.performances = result.data;
				this.shops = result.shops;
				this.users = result.users;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'performance_form':
							this.submitPerformances(this.performance);
						break;
					}
				});
			},
			async submitPerformances (data) {
				const res = await this.$http.post(this.$api.URI_PERFORMANCES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.getPerformances({...this.params, page: 1});
						this.$refs['performance_form'].resetFields();
						this.performances_dialog = false;
					}
				})
			},
			batchAction (m) {
				if (m === 'create') {
					this.performances_dialog = true;
					this.performance = {};
					return;
				}
				const { selection } = this.$refs.performances_table;
				if (!selection.length) return this.$message.error('请选择操作内容');
				switch (m) {
					case 'export':
							selection.forEach((c) => {
								c.f_department = this.oa_departments[c.user_id] ? this.oa_departments[c.user_id].name : '未知';
								c.f_user = this.oa_users[c.user_id] ? this.oa_users[c.user_id].name : c.user.name;
								c.f_no = c.client_fee.no || '无';
								c.f_t = c.goods_type.name || '无';
								c.f_m = c.money/100;
							});
							this.$confirm(`此操作将导出${selection.length}条内容, 是否继续？`, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								var file = "绩效导出" + this.$moment().format('YYYYMMDDHHmmss');
								var header = [['ID', '归属部门', '业务专员', '回款流水', '产品类型', '金额', '更新时间', '创建时间']];
								var filter = ['id', 'f_department', 'f_user', 'f_no', 'f_t', 'f_m', 'updated_at', 'created_at'];
								this.$utils.handleDownload(header, filter, selection, file, "绩效明细");
							})
						break;
					case 'delete':
							this.$confirm(`此操作将永久删除${selection.length}条内容, 是否继续？`, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								this.$error({
									type: 'success',
									duration: 2000,
									message: '接口还没接上。。。'
								});
							})
						break;
				}
			}
		},
		data() {
			return {
				shops: [],
				users: [],
				performances: [],
				performances_dialog: false,
				performance: {},
				params: {
					perPage: 10,
					keywords: '',
				},
				performance_rules: {
					money: [{ required: true, message: '必须填写金额', trigger: 'blur'}],
					user_id: [{ required: true, message: '必须选择专员', trigger: 'blur'}],
				}
			}
		},
		async created () {
			this.getPerformances(this.params, true);
		}
	};
</script>