<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :size="theme.size">
				<el-form-item>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" @change="getOrders(params)" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.category_id" placeholder="分类" @change="getOrders(params)" filterable clearable>
						<el-option v-for="category in categories" :key="category.id" :label="category.name" :value="category.id"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table class="scroll-wrapper ssss absolute" :max-height="(theme.height - 160)+'px'" :header-cell-style="{backgroundColor: '#e1d6ba', color: '#000'}" :data="goods" :size="theme.size" @sort-change="sortChange" show-summary>
					<el-table-column label="销售" prop="name" min-width="87">
						<el-checkbox slot="header" class="c0 fw6" v-model="params.new" :true-label="1" :false-label="0" @change="getOrders(params)">当月客户</el-checkbox>
					</el-table-column>
					<el-table-column align="center" v-for="type in goods_types" :key="type.id" :label="type.name">
						<el-table-column :prop="'count_'+type.id" label="家" min-width="45" align="center"></el-table-column>
						<el-table-column :prop="'quantity_'+type.id" label="张" min-width="45" align="center"></el-table-column>
					</el-table-column>
					<el-table-column label="其它" align="center">
						<el-table-column prop="count_other" label="家" min-width="45" align="center"></el-table-column>
						<el-table-column prop="quantity_other" label="张" min-width="45" align="center"></el-table-column>
					</el-table-column>
					<el-table-column align="center" label="合计">
						<el-table-column prop="count" label="家" min-width="45" align="center"></el-table-column>
						<el-table-column prop="quantity" label="张" min-width="45" align="center"></el-table-column>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div slot="footer" class="p20"></div>
	</main-table-a>
</template>

<style type="">
	.ssss .el-table__footer-wrapper tbody td.el-table__cell {
		background-color: #c7000a;
		font-weight: 600;
		font-size: 13px;
		color: #fff;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			cellStyle ({row}) {
				if (row.name == '公海') return {color: '#c7000a', fontWeight: 900};
			},
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getOrders(this.params);
			},
			async getOrders (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_ORDERS, {params: {...params, action: 'statistics'}, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.goods_types = result.goods_types;
				this.categories = result.categories;
				this.clients = result.clients;
				this.goods = result.goods;
			},
			getSummaries(param) {
				const { columns, data } = param;
				const sums = [];
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = '合计';
						return;
					}
					const values = data.map(item => Number(item[column.property]));
					if (!values.every(value => isNaN(value))) {
						sums[index] = values.reduce((prev, curr) => {
							const value = Number(curr);
							if (!isNaN(value)) {
								return prev + curr;
							} else {
								return prev;
							}
						}, 0);
					} else {
						sums[index] = 'N/A';
					}
					sums[5] = ((sums[3]/sums[1])*100).toFixed(2);
					sums[6] = ((sums[4]/sums[1])*100).toFixed(2);
				});
				return sums;
			}
		},
		data() {
			const start = new Date();
			start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
			return {
				goods_types: [],
				categories: [],
				clients: [],
				goods: [],
				params: {
					date: [start, new Date()]
				}
			}
		},
		async created () {
			this.getOrders(this.params, true);
		}
	};
</script>