<template>
	<main-table-a>
		<div slot="table" class="df f1 fdc">
			<div class="df fww">
				<div class="p10 mr20 mb20 br5 miw100 cp" :style="'box-shadow: 0 0 10px 5px #f1f1f1; background-color: ' + (status.checked ? '#b7a06a' : '#e2d6ba')" v-for="(status, s) in status_tabs" :key="s" @click="toggleStatus(s)">
					<div class="df jcsb mb5">
						<div class="fs16 fw6 c3">{{status.count || 0}}</div>
					</div>
					<div class="fs14 c6">{{status.name}}</div>
				</div>
				<div class="mb10">
					<el-button type="primary" size="mini" @click="show_fillter = !show_fillter" :plain="!show_fillter">
						{{show_fillter ? '收起' : '筛选'}}
						<el-tooltip>
							<div slot="content" class="lh1_5">
								<p>本列表不支持添加创建，数据来源于人事在员工列表办理，或员工申请办理</p>
								<p>若出现同一个姓名多条重复记录，说明有岗位转正调整更新。以最新一条记录为准</p>
							</div>
							<i class="el-icon-question ch"></i>
						</el-tooltip>
					</el-button>
				</div>
			</div>
			<el-form :inline="true" :model="params" :size="theme.size" v-if="show_fillter">
				<el-form-item>
					<el-input v-model="params.keyword" placeholder="申请流水/理由" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employees" placeholder="请选择申请人" filterable  multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getTransfers({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table ref="employee_table" class="bill scroll-wrapper pa" height="100%" :data="tables" :size="theme.size">
					<el-table-column label="申请人" prop="employee.name" min-width="90"></el-table-column>
					<el-table-column label="申请流水" min-width="180">
						<el-link slot-scope="scope" type="primary" :disabled="!$utils.update($api.URI_POSITIONS_TRANSFERS)">{{scope.row.no}}</el-link>
					</el-table-column>
					<el-table-column label="岗位名称" prop="name" min-width="160"></el-table-column>
					<el-table-column label="性质" prop="official" min-width="100" :formatter="({official}) => { return official ? '正式' : '试用'; }"></el-table-column>
					<el-table-column label="理由" prop="comment" min-width="160"></el-table-column>
					<el-table-column label="申请时间" prop="created_at" min-width="150"></el-table-column>
					<el-table-column label="状态" min-width="60">
						<template slot-scope="scope">
							<el-tooltip effect="dark" placement="top" :content="'OA审核编号'+scope.row.approval.sp_no" v-if="scope.row.approval">
								<span>{{statuses[scope.row.status] || '未知'}}</span>
							</el-tooltip>
							<span v-else>{{statuses[scope.row.status] || '未知'}}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" min-width="60">
						<template slot-scope="scope">
							<el-link type="primary" @click="delTransfer(scope.row)">删除</el-link>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getTransfers({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getTransfers({ ...params, page: v }) }"
			background>	
		</el-pagination>
		<template slot="other">
			<departure-show ref="departure" :status="statuses" @refresh="getTransfers(params)"></departure-show>
		</template>
	</main-table-a>
</template>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import mainTableA from '@/components/main-table-a';
	import departureShow from '@/pages/hrm/departures/show';

	export default {
		components: {
			mainTableA,
			departureShow
		},
		computed: {
			...mapState(['theme'])
		},
		filters: {
			diff_hired (t) {
				if (!t) return "未办理入职登记！";
				if (!t.hired_at) return "未办理入职登记！";
				var now = moment();
				if (t.separated_at) {
					now = moment(t.separated_at);
				}
				const diff_days = Math.abs(now.diff(t.hired_at, 'days'));
				const years = Math.floor(diff_days / 365);
				const months = Math.floor((diff_days % 365) / 30.4375);
				const days = Math.floor((diff_days % 365) % 30.4375);
				// 注意：以上代码中的% 30.4375是近似一个月30天的计算，对于大部分情况是准确的，但是在闰年二月会有误差，如果需要精确的月份差异，可以通过遍历每一个月来计算。
				return `已加入 ${years} 年 ${months} 月 ${days} 天`;
			},
			diff_days (t) {
				if (!t) return '无';
				return moment(t.separated_at).diff(t.hired_at, 'day');
			}
		},
		methods: {
			toggleStatus (s) {
				const { status } = this.params
				const index = status.indexOf(s);
				if (index > -1) {
					status.splice(index, 1);
					this.status_tabs[s].checked = false;
				} else {
					status.push(s);
					this.status_tabs[s].checked = true;
				}
				this.getTransfers(this.params);
			},
			async getTransfers (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_POSITIONS_TRANSFERS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.tables = result.data;
				this.shops = result.shops;
				this.statuses = result.status;
				this.employees = result.employees;
				if (result.counts) {
					var status_tabs = {};
					const status = params.status || [];
					for (var s in this.statuses) {
						status_tabs[s] = {
							name: this.statuses[s],
							count: result.counts[s] || 0,
							checked: status.indexOf(s) >= 0 ? true : false
						};
					}
					this.status_tabs = status_tabs;
				}
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			delTransfer ({ id, employee, separated_at }) {
				if (this.$moment().isAfter(separated_at)) return this.$message.error('已超过离职时间不能操作删除！');
				this.$confirm(employee.name + ' 的离职申请 ', '确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleTransfers({action: 'delete', id});
				});
			},
			async handleTransfers (params) {
				const res = await this.$http.get(this.$api.URI_POSITIONS_TRANSFERS, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getTransfers(this.params);
				this.$message.success(msg);
			}
		},
		data() {
			return {
				tables: [],
				shops: [],
				statuses: [],
				employees: [],
				status_tabs: [],
				show_fillter: false,
				employee: {},
				params: {
					status: [],
					perPage: 10,
				}
			}
		},
		created () {
			this.getTransfers(this.params, true);
		}
	};
</script>