<template>
	<div style="height: 100%;">
		<main-table-a>
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="请输入店铺名称关键词" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.brand" placeholder="品牌" filterable clearable>
						<el-option v-for="brand in brands" :key="brand.id" :label="brand.name" :value="brand.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.market" placeholder="商圈" filterable clearable>
						<el-option v-for="market in markets" :key="market.id" :label="market.name" :value="market.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getShops({ ...params, page: 1})">查询</el-button>
					<el-button type="primary" @click="create_drawer = true" :disabled="!$utils.create('shops')">新增门店</el-button>
				</el-form-item>
			</el-form>
			<el-table class="scroll-wrapper absolute" height="100%" :data="shops" :size="theme.size">
				<el-table-column prop="number" label="编号"></el-table-column>
				<el-table-column prop="name" label="名称"></el-table-column>
				<el-table-column prop="brand.name" label="品牌"></el-table-column>
				<el-table-column prop="goods_count" label="产品"></el-table-column>
				<el-table-column prop="orders_count" label="订单"></el-table-column>
				<!-- 因为本系统共享用户账户，而首次登录进入店铺默认创建钱包 -->
				<el-table-column prop="wallets_count" label="用户"></el-table-column>
				<el-table-column prop="clients_count" label="客户"></el-table-column>
				<el-table-column prop="articles_count" label="文章"></el-table-column>
				<el-table-column prop="updated_at" label="更新时间" width="180" v-if="theme.width >= 1080"></el-table-column>
				<el-table-column prop="created_at" label="创建时间" width="180" v-if="theme.width >= 1080"></el-table-column>
				<el-table-column prop="name" label="更多操作" width="160">
					<template slot-scope="scope">
						<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.update('shops')" v-if="scope.row.deleted_at">恢复</el-button>
						<template v-else>
							<el-tooltip placement="left">
								<vue-qr slot="content" :margin="10" :size="100" :text="'http://m'+scope.row.number+'.dajing.ren'"></vue-qr>
								<el-button type="text" @click="$utils.openWindow('http://m'+scope.row.number+'.dajing.ren')">浏览</el-button>
							</el-tooltip>
							<el-button type="text" @click="$utils.toRoute('/shop/basic?no='+scope.row.number)">编辑</el-button>
							<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.restore('shops')">删除</el-button>
						</template>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getShops({ ...params, page: 1, perPage: v}) }"
				@current-change="(v) => { getShops({ ...params, page: v}) }"
				background>
			</el-pagination>
		</main-table-a>
		<template slot="other">
			<el-drawer title="新增门店" :visible.sync="create_drawer" @closed="resetForm('shop_form')">
				<div class="pl15 pr15">
					<el-form ref="shop_form" label-width="80px" :model="shop" :rules="shop_rules" :size="theme.size">
						<el-form-item prop="name" label="名称">
							<el-input v-model="shop.name" placeholder="请输入门店名称" clearable></el-input>
						</el-form-item>
						<el-form-item prop="regions" label="地区">
							<el-cascader style="width: 100%;" placeholder="请选择所属区域" v-model="shop.regions" :props="regions" filterable clearable></el-cascader>
						</el-form-item>
						<el-form-item prop="address" label="详细地址">
							<el-input v-model="shop.address" placeholder="请输入店铺详细地址。不要重复输入已选择的地区【省/市/县/区/镇/街道】" clearable></el-input>
						</el-form-item>
						<el-form-item prop="brand_id" label="品牌">
							<el-select v-model="shop.brand_id" placeholder="请选择所属品牌" filterable clearable>
								<el-option v-for="brand in brands" :key="brand.id" :label="brand.name" :value="brand.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item prop="market_id" label="商圈">
							<el-select v-model="shop.market_id" placeholder="请选择所属商圈" filterable>
								<el-option v-for="market in markets" :key="market.id" :label="market.name" :value="market.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="负责人">
							<el-input v-model="shop.principal" placeholder="请输入门店负责人" clearable></el-input>
						</el-form-item>
						<el-form-item label="联系电话">
							<el-input v-model="shop.mobile" placeholder="请输入门店联系电话" clearable></el-input>
						</el-form-item>
						<el-form-item prop="opening_at" label="营业时间" :rules="[{type: 'array', message: '营业时间必须为数组'} ]">
							<el-time-picker is-range value-format="H:mm:s" v-model="shop.opening_at" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"></el-time-picker>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('shop_form')">立即创建</el-button>
							<el-button @click="resetForm('shop_form')">取消</el-button>
						</el-form-item>
					</el-form>
				</div>
			</el-drawer>
		</template>
	</div>
</template>

<style>
	.el-select,
	.el-date-editor--timerange.el-input__inner {
		width: 100%;
	}
</style>
<script>
	import { mapState } from 'vuex';
	import vueQr from 'vue-qr';
	import mainTableA from '@/components/main-table-a';
	
	export default {
		components: {
			vueQr,
			mainTableA
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			onDelete (r) {
				this.$confirm('确定要删除【'+r.name+'】店铺吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_SHOPS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getShops(this.params);
						}
					});
				})
			},
			resetForm (ref) {
				this.create_drawer = false;
				this.$refs[ref].resetFields();
				this.shop = {};
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					this.submitShops(this.shop);
				})
			},
			async submitShops (data) {
				const res = await this.$http.post(this.$api.URI_SHOPS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						if (this.create_drawer) {
							this.create_drawer = false;
							this.getShops(this.params);
						}
					}
				});
			},
			async getShops (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_SHOPS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.shops = result.data;
				this.brands = result.brands;
				this.markets = result.markets;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				create_drawer: false,
				markets: [],
				brands: [],
				shops: [],
				shop: {},
				shop_rules: {
					name: [
						{ required: true, message: '请输入店铺名称', trigger: 'blur' },
						{ min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
					],
					regions: [
						{ required: true, message: '请选择店铺所在区域', trigger: 'blur' },
						{ type: 'array', message: '请选择区域不是数组' }
					]
				},
				regions: {
					lazy: true,
					value: 'id',
					label: 'name',
					checkStrictly: true,
					lazyLoad: async (node, resolve) => {
						const { value } = node;
						const res = await this.$http.get(this.$api.URI_REGIONS, {params: {action: 'region', region: value?value:0}, headers: {loading: true}});
						resolve(res.data.result);
					}
				},
				params: {
					perPage: 10,
				}
			}
		},
		created () {
			this.getShops(this.params, true);
		}
	};
</script>