<template>
	<div>
		<el-result icon="info" title="店铺暂未绑定小程序" subTitle="授权绑定微信小程序后可查看操作" v-if="!createType">
			<template slot="extra">
				<div class="df jcsb" style="width: 700px;">
					<div class="p20 bcf1" style="width: 49%;">
						<h4>已经拥有微信小程序</h4>
						<p class="c6 mt20 tal lh1_5">授权已有的微信小程序，完成支付方式设置，发布快速获得商城小程序</p>
						<el-button style="width: 100%;" class="mt20" type="primary" :size="theme.size" @click="$emit('update:createType', 'authorize')">授权微信小程序</el-button>
					</div>
					<div class="p20 bcf1" style="width: 49%;">
						<h4>还没有微信小程序</h4>
						<p class="c6 mt20 tal lh1_5">复用店铺认证信息快速创建小程序。 注：在后台创建的小程序享受免认证费用。</p>
						<el-button style="width: 100%;" class="mt20" type="primary" :size="theme.size" @click="$emit('update:createType', 'quick')">快速注册</el-button>
					</div>
				</div>
			</template>
		</el-result>
		<template v-if="createType == 'authorize'">
			<el-page-header @back="$emit('update:createType', null)" content="授权发布小程序"></el-page-header>
			<el-steps class="p20" :active="1">
				<el-step title="已注册微信小程序，立即授权发布">
					<div slot="description">
						<p>使用微信小程序管理员帐号扫码进行授权，授权过程中请勾选所有权限以确保小程序功能完整性。如未注册小程序，可以从公众号后台免微信认证创建小程序或直接前往“微信公众平台”注册企业主体的小程序帐号。</p>
						<el-button class="mt20" type="primary" :size="theme.size" @click="createPreauth(45476876)">立即授权</el-button>
					</div>
				</el-step>
				<el-step title="设置小程序支付方式">
					<div slot="description">
						<p>开通微信支付能力，请提前完成店铺认证并且保证店铺主体和小程序主体一致</p>
						<el-button class="mt20" type="primary" :size="theme.size">支付设置</el-button>
					</div>
				</el-step>
				<el-step title="完成所有准备，提交审核并发布小程序">
					<div slot="description">
						<p>提交微信审核（最长14个工作日），审核通过后即可立即发布版本</p>
						<el-button class="mt20" type="primary" :size="theme.size">前往发布</el-button>
					</div>
				</el-step>
			</el-steps>
		</template>
		<template v-if="createType == 'quick'">
			<el-page-header @back="$emit('update:createType', null)" content="创建发布小程序"></el-page-header>
			<el-steps class="p20" :active="1">
				<el-step title="快速创建试用小程序">
					<div slot="description">
						<p>小程序管理员扫码授权，填写小程序名称即在1分钟内生试用小程序。试用小程序支持商家上架自定义商品、装修店铺等，暂不支持支付。请在14天内完成小程序转正操作，否则将被回收</p>
						<el-button class="mt20" type="primary" :size="theme.size" @click="dialogVisible = true;">立即创建</el-button>
					</div>
				</el-step>
				<el-step title="完成小程序主体认证，补充基本信息完成小程序转正">
					<div slot="description">
						<p>填写法人实名认证过的微信号，引导法人扫脸完成认证，认证成功后， 完善小程序名称、头像基本信息即可获得一个正式在微信内可搜索的小程序。<br>小程序转正中：仅支持非个人主体认证的店铺进行小程序转正</p>
						<el-button class="mt20" type="primary" :size="theme.size" @click="create_dialog = true;">立即转正</el-button>
					</div>
				</el-step>
				<el-step title="设置小程序支付方式">
					<div slot="description">
						<p>选择微信支付-自有（开通微信支付）或微信支付-代销（如需使用代销能力，请提前完成店铺认证并且保证店铺主体和小程序主体一致）</p>
						<el-button class="mt20" type="primary" :size="theme.size">支付设置</el-button>
					</div>
				</el-step>
				<el-step title="完成所有准备，提交审核并发布小程序">
					<div slot="description">
						<p>提交微信审核（最长14个工作日），审核通过后即可立即发布版本</p>
						<el-button class="mt20" type="primary" :size="theme.size">前往发布</el-button>
					</div>
				</el-step>
			</el-steps>
		</template>
		<el-dialog width="380px" title="快速创建试用小程序" :visible.sync="dialogVisible">
			<div class="tac">
				<vue-qr :margin="10" :size="200" text="http://m.dajing.ren"></vue-qr>
				<div>
					<i class="el-icon-mobile fs28 mr10"></i>
					<span class="dib tal">请打开 <b class="c07c160">微信</b> 扫一扫<br>创建试用小程序</span>
				</div>
			</div>
		</el-dialog>
		<el-dialog width="600px" title="补充小程序基本信息" :visible.sync="create_dialog">
			<el-form ref="create_form" label-width="120px" :model="create_form" :rules="create_rules" :size="theme.size" status-icon>
				<el-form-item label="企业名称" prop="enterprise_name">
					<el-input placeholder="请输入企业名称。需与工商部门登记信息一致，如果是“无主体名称个体工商户”则填“个体户+法人姓名”，例如“个体户张三”" autocomplete="off" v-model="create_form.enterprise_name" clearable></el-input>
				</el-form-item>
				<el-form-item label="企业代码" prop="code">
					<el-input placeholder="请输入企业代码" autocomplete="off" v-model="create_form.code" maxlength="18" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item label="企业代码类型" prop="code_type">
					<el-radio-group v-model="create_form.code_type" @change="create_form.code = ''">
						<el-radio :label="1">统一社会信用代码</el-radio>
						<el-radio :label="2">组织机构代码</el-radio>
						<el-radio :label="3">营业执照注册号</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="法人微信号" prop="legal_persona_wechat">
					<el-input placeholder="请输入法人微信号" autocomplete="off" v-model="create_form.legal_persona_wechat" maxlength="18" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item label="法人姓名" prop="legal_persona_name">
					<el-input placeholder="请输入法人姓名。需与微信号认证一致，并绑定银行卡" autocomplete="off" v-model="create_form.legal_persona_name" clearable></el-input>
				</el-form-item>
				<el-form-item label="联系电话" prop="component_phone">
					<el-input placeholder="请输入联系电话" autocomplete="off" v-model="create_form.component_phone" maxlength="11" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item label="法人身份证号" prop="legal_persona_idcard">
					<el-input placeholder="请输入法人身份证号" autocomplete="off" v-model="create_form.legal_persona_idcard" maxlength="18" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('create_form')">确 定</el-button>
					<el-button @click="resetForm('create_form')">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<style>

</style>
<script>
	import { mapState } from 'vuex';
	import vueQr from 'vue-qr';
	
	export default {
		props: {
			createType: {
				type: String,
				default: null
			}
		},
		components: {
			vueQr
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			async createPreauth (n) {
				const p = {action: 'createPreauth', appid: 'wxd784626b3f1e9f2a'}
				const res = await this.$http.post(this.$api.URI_WECHAT_COMPONENT, p);
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				const redirect_uri = encodeURIComponent("http://sa.dajing.ren/authorizer?number="+n);
				window.location.href = 'https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid='+p.appid+'&pre_auth_code='+result+'&redirect_uri='+redirect_uri+'&auth_type=3';
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
				})
			},
			resetForm (ref) {
				this.create_dialog = false;
				this.$refs[ref].resetFields();
				this.create_form = {
					code_type: 1
				}
			},
		},
		data() {
			var validEnterpriseCode = (rule, value, callback) => {
				if (!value) return callback(new Error('必须输入企业代码'));
				if (this.create_form.code_type == 1 && this.create_form.code.length != 18) return callback(new Error('输入企业代码长度必须为18位'));
				if (this.create_form.code_type == 2 && this.create_form.code.length != 9) return callback(new Error('输入企业代码长度必须为9位。例：xxxxxxxx-x'));
				if (this.create_form.code_type == 3 && this.create_form.code.length != 15) return callback(new Error('输入企业代码长度必须为15位'));
			}
			return {
				// createType: null,
				dialogVisible: false,
				create_dialog: false,
				create_form: {
					code_type: 1
				},
				create_rules: {
					enterprise_name: [{ required: true, message: '必须输入企业名', trigger: 'blur' }],
					code_type: [{ required: true, message: '必须选择企业代码类型', trigger: 'blur' }],
					code: [{ required: true, message: '必须输入企业代码', trigger: 'blur' }, { validator: validEnterpriseCode, trigger: 'blur' }],
					legal_persona_wechat: [{ required: true, message: '必须输入法人微信号', trigger: 'blur' }],
					legal_persona_name: [{ required: true, message: '必须输入法人姓名', trigger: 'blur' }],
					component_phone: [{ required: true, message: '必须输入联系电话', trigger: 'blur' }],
					legal_persona_idcard: [{ required: true, message: '必须输入法人身份证号码', trigger: 'blur' }]
				}
			}
		},
		created () {
			// let { query } = this.$route;
			// this.createType = query.createType;
		}
	};
</script>