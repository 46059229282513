<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="品牌名称" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.company_id" placeholder="所属企业" filterable clearable>
						<el-option v-for="company in companies" :key="company.id" :label="company.name" :value="company.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getBrands({ ...params, page: 1})" plain>查询</el-button>
					<el-button type="primary" @click="create_drawer = true" :disabled="!$utils.create('brands')">添加品牌</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table class="scroll-wrapper absolute" height="100%" :data="brands" :size="theme.size">
					<el-table-column label="LOGO" width="60">
						<el-image slot-scope="scope" class="brand_logo" :src="scope.row.logo" :preview-src-list="[scope.row.logo]">
							<i slot="error" class="el-icon-picture-outline"></i>
						</el-image>
					</el-table-column>
					<el-table-column prop="name" label="名称">
						<template slot-scope="scope">
							<h3>{{scope.row.name}} {{scope.row.ename}}#{{scope.row.id}}</h3>
							<div>
								<el-checkbox :value="scope.row.published_at != null" :size="theme.size" :disabled="!$utils.checkAuth('brands', 'publishing') || scope.row.deleted_at" @change="changeBrands({ action: 'publishing', id: scope.row.id})">发布</el-checkbox>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="description" label="描述"></el-table-column>
					<el-table-column prop="goods_count" label="商品数量"></el-table-column>
					<el-table-column prop="shops_count" label="店铺数量"></el-table-column>
					<el-table-column prop="company.name" label="所属企业" width="280" :formatter="(r) => { return r.company ? r.company.name : '未知'; }"></el-table-column>
					<el-table-column prop="at" label="操作时间" width="210" v-if="theme.width >= 1080">
						<template slot-scope="scope">
							<p>创建：{{scope.row.created_at}}</p>
							<p>更新：{{scope.row.updated_at}}</p>
							<p v-if="scope.row.published_at">发布：{{scope.row.published_at}}</p>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="更多操作" width="140">
						<template slot-scope="scope">
							<template v-if="!scope.row.deleted_at">
								<el-button type="text" @click="$utils.openWindow($config.ROOTPATH+'brands/'+scope.row.id)">浏览</el-button>
								<el-button type="text" @click="handleEdit(scope.row)" :disabled="!$utils.update('brands')">编辑</el-button>
								<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete('brands')">删除</el-button>
							</template>
							<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore('brands')" v-else>恢复</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:total="params.total"
			:page-size="params.perPage"
			:current-page="params.page"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			@size-change="(v) => { getBrands({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getBrands({ ...params, perPage: v }) }"
			background>
		</el-pagination>
		<template slot="other">
			<el-drawer :title="brand.id?'编辑品牌':'添加品牌'" :visible.sync="create_drawer" @closed="resetForm('create_form')">
				<div style="padding: 0 15px">
					<el-form ref="create_form" label-width="80px" :model="brand" :rules="brand_rules" :size="theme.size" status-icon>
						<el-form-item label="品牌名称" prop="name">
							<el-input placeholder="请输入品牌名称" autocomplete="off" v-model="brand.name"></el-input>
						</el-form-item>
						<el-form-item label="英文名称" prop="ename">
							<el-input placeholder="请输入英文名称" autocomplete="off" v-model="brand.ename"></el-input>
						</el-form-item>
						<el-form-item label="所属企业" prop="company_id">
							<el-select placeholder="所属企业" style="width: 100%;" v-model="brand.company_id" filterable>
								<el-option v-for="company in companies" :key="company.id" :label="company.name" :value="company.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="品牌描述" prop="description">
							<el-input type="textarea" rows="3" v-model="brand.description" placeholder="用一段话简单描述品牌内容。" maxlength="562" show-word-limit clearable></el-input>
						</el-form-item>
						<el-form-item label="LOGO" prop="logo">
							<el-upload
								class="category_uploader"
								:data="{type: brand_logo_cfg.type}"
								:accept="brand_logo_cfg.ext.map(e => { return '.'+e; }).join(',')"
								:on-success="(r, f, l) => { !r.code ? (brand = {...brand, logo: r.result.url}) : $message.error(r.msg)}"
								:show-file-list="false"
								:action="$api.URI_UPLOADS"
								:before-upload="beforeBrandLogo">
								<img v-if="brand.logo" :src="brand.logo" style="width: 80px; height: 80px; display: block;">
								<i v-else class="el-icon-plus" style="width: 80px; height: 80px; color: #8c939d; font-size: 28px; line-height: 80px; text-align: center;"></i>
								<div slot="tip" class="el-upload__tip">只能上传像素为80*80px内，大小不超过 {{brand_logo_cfg.format_size_unit}}，格式为 {{brand_logo_cfg.ext.join('/').toUpperCase()}} 的图像</div>
							</el-upload>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('create_form')">确 定</el-button>
							<el-button @click="resetForm('create_form')">取 消</el-button>
						</el-form-item>
					</el-form>
				</div>
			</el-drawer>
		</template>
	</main-table-a>
</template>

<style>
	.category_uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		position: relative;
		overflow: hidden;
		cursor: pointer;
	}
	.category_uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.brand_logo {
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		background-color: #f1f1f1;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		computed: {
			...mapState(['theme']),
			brand_logo_cfg () {
				return this.$utils.uploadConfig('brand_logo');
			}
		},
		methods: {
			handleEdit (r) {
				this.create_drawer = true;
				this.brand = {...r};
			},
			beforeBrandLogo (file) {
				const { size, format_size_unit } = this.brand_logo_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传LOGO图标不能超过 '+format_size_unit);
				return is;
			},
			onRestore (r) {
				this.doAction({action: 'restore', id: r.id}, '确定要恢复【'+r.name+'】品牌吗？');
			},
			onDelete (r) {
				if (r.goods_count) return this.$message.error('不能删除！【'+r.name+'】品牌下还有 '+r.goods_count+' 条内容。');
				this.doAction({action: 'delete', id: r.id}, '确定要删除【'+r.name+'】品牌吗？');
			},
			doAction (p, t) {
				this.$confirm(t, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_BRANDS, {params: p, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getBrands(this.params);
					this.$message.success(msg);
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					this.submitBrand(this.brand);
				})
			},
			resetForm (ref) {
				this.create_drawer = false;
				this.$refs[ref].resetFields();
				this.brand = {};
			},
			async submitBrand (data) {
				const res = await this.$http.post(this.$api.URI_BRANDS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.getBrands(this.params);
						this.create_drawer = false;
					}
				});
			},
			async getBrands (p, l = false) {
				const res = await this.$http.get(this.$api.URI_BRANDS, {params: p, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.brands = result.data;
				this.companies = result.companies;
				this.params = {
					...p,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				create_drawer: false,
				companies: [],
				brands: [],
				brand: {
					name: '',
				},
				params: {
					perPage: 10,
					keywords: '',
				},
				brand_rules: {
					name: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }]
				}
			}
		},
		async created () {
			this.getBrands(this.params, true);
		}
	};
</script>