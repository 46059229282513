<template>
	<div class="c6 m0a fs12 tac"  style="width: 1200px; line-height: 40px;">CopyRight 2019 - {{year}} {{$config.COMPANY}} 版权所有 <a href="https://beian.miit.gov.cn/">粤ICP备34235444号</a></div>
</template>


<script>
	export default {
		computed: {
			year () {
				var d = new Date();
				return d.getFullYear();
			}
		}
	}
</script>