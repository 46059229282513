<template>
	<el-dialog width="480px" :title="order.id ? '修改订单' : '添加订单'" :visible.sync="order_dialog" :fullscreen="theme.width < 480" :close-on-click-modal="false" :before-close="closeImporting" append-to-body destroy-on-close>
		<div v-if="importing && import_count">
			<h3>正在导入订单 {{import_index}}/{{import_count}}</h3>
			<el-progress :percentage="Math.round((import_index/import_count)*100)"></el-progress>
		</div>
		<el-form ref="order_form" label-width="80px" style="max-height: 56vh;" class="scroll-wrapper pr10 oa" v-else :model="order" :rules="order_rules" :size="theme.size" status-icon>
			<template v-if="order.id">
				<el-form-item prop="field" label="修改字段" required>
					<el-select v-model="order.field" placeholder="请选择修改字段" filterable>
						<el-option label="优惠金额" value="discount_price" :disabled="order.status != 'paying'"></el-option>
						<el-option label="配送运费" value="freight_price" :disabled="order.status != 'paying'"></el-option>
						<el-option label="实付金额" value="paid_price" :disabled="order.status != 'paying'"></el-option>
						<el-option label="收货信息" value="address"></el-option>
					</el-select>
				</el-form-item>
				<template v-if="order.field === 'address'">
					<el-form-item prop="linkman" label="收货人" :rules="[{ required: false, message: '必须填写收货人', trigger: ['blur', 'change']}]">
						<el-input v-model="order.linkman" placeholder="请输入收货人">
							<!-- <el-select slot="prepend" style="width: 100px;" placeholder="关联用户" v-model="order.user_id" v-if="is_order_form_user" filterable clearable>
								<el-option v-for="user in users" :key="user.id" :label="user.name" :value="user.id"></el-option>
							</el-select> -->
						</el-input>
					</el-form-item>
					<el-form-item prop="mobile" label="手机号码" :rules="[{ required: false, message: '必须填写手机号码', trigger: ['blur', 'change']}, { pattern: /^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8}$/, message: '请填写正确手机号码', trigger: ['blur', 'change']}]">
						<el-input v-model="order.mobile" placeholder="请输入手机号码">
							<!-- <el-select slot="prepend" style="width: 100px;" placeholder="关联店铺" v-model="order.shop_id" v-if="is_order_form_shop" filterable clearable>
								<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
							</el-select> -->
						</el-input>
					</el-form-item>
					<el-form-item prop="regions" label="收货地区" :rules="[{ required: false, message: '必须选择收货地区', trigger: ['blur', 'change']}]">
						<el-cascader style="width: 100%;" placeholder="请选择订单收货地区。支持输入搜索" v-model="order.regions" :options="tree_regions" :props="{expandTrigger: 'hover', value: 'id', label: 'name'}" filterable></el-cascader>
					</el-form-item>
					<el-form-item prop="address" label="详细地址" :rules="[{ required: false, message: '必须填写详细地址', trigger: ['blur', 'change']}]">
						<el-input v-model="order.address" placeholder="请输入收货详细地址" clearable>
							<!-- <el-select slot="prepend" style="width: 100px;" v-model="order.express" placeholder="物流平台" filterable>
								<el-option v-for="(express, e) in expresses" :key="e" :label="express" :value="e"></el-option>         
							</el-select> -->
						</el-input>
					</el-form-item>
					<el-form-item prop="express" label="物流平台">
						<el-select v-model="order.express" placeholder="物流平台" filterable>
							<el-option v-for="(express, e) in expresses" :key="e" :label="express" :value="e"></el-option>         
						</el-select>
					</el-form-item>
					<el-form-item prop="remarks" label="备注说明">
						<el-input v-model="order.remarks" placeholder="请输入备注说明" clearable></el-input>
					</el-form-item>
				</template>
				<el-form-item label="优惠金额" prop="discount_price" v-if="order.field === 'discount_price'" :rules="[{ required: true, message: '必须填写优惠金额'}, { type: 'number', min: 0, max: order.goods_price, message: '优惠金额必须大于等于0或小于等于'+order.goods_price, trigger: ['blur', 'change']}]">
					<el-input-number v-model.number="order.discount_price" placeholder="请输入修改后的金额" :precision="2" :step="0.01" :min="0.01"></el-input-number> 元
				</el-form-item>
				<el-form-item label="配送运费" prop="freight_price" v-if="order.field === 'freight_price'" :rules="[{ required: true, message: '必须填写配送运费'}, { type: 'number', min: 0, message: '配送运费必须为大于等于0', trigger: ['blur', 'change']}]">
					<el-input-number v-model.number="order.freight_price" placeholder="请输入修改后的金额" :precision="2" :step="0.01" :min="0.01"></el-input-number> 元
				</el-form-item>
				<el-form-item label="实付金额" prop="paid_price" v-if="order.field === 'paid_price'" :rules="[{ required: true, message: '必须填写实付金额'}, { type: 'number', min: 1, message: '实付金额必须为大于等于1', trigger: ['blur', 'change']}]">
					<el-input-number v-model.number="order.paid_price" placeholder="请输入修改后的金额" :precision="2" :step="0.01" :min="0.01"></el-input-number> 元
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('order_form')">提交保存</el-button>
				</el-form-item>
			</template>
			<template v-else>
				<el-form-item prop="out_no" label="来源单号">
					<el-input v-model="order.out_no" placeholder="请输入来源单号">
						<el-select slot="prepend" style="width: 100px;" placeholder="来源平台" v-model="order.source" filterable clearable>
							<el-option v-for="(source, s) in sources" :key="s" :label="source" :value="s"></el-option>
						</el-select>
					</el-input>
				</el-form-item>
				<el-form-item prop="linkman" label="收货人" :rules="[{ required: true, message: '必须填写收货人', trigger: ['blur', 'change']}]">
					<el-input v-model="order.linkman" placeholder="请输入收货人">
						<!-- <el-select slot="prepend" style="width: 100px;" placeholder="关联用户" v-model="order.user_id" v-if="is_order_form_user" filterable clearable>
							<el-option v-for="user in users" :key="user.id" :label="user.name" :value="user.id"></el-option>
						</el-select> -->
					</el-input>
				</el-form-item>
				<el-form-item prop="mobile" label="手机号码" :rules="[{ required: true, message: '必须填写手机号码', trigger: ['blur', 'change']}, { pattern: /^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8}$/, message: '请填写正确手机号码', trigger: ['blur', 'change']}]">
					<el-input v-model="order.mobile" placeholder="请输入手机号码">
						<!-- <el-select slot="prepend" style="width: 100px;" placeholder="关联店铺" v-model="order.shop_id" v-if="is_order_form_shop" filterable clearable>
							<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
						</el-select> -->
					</el-input>
				</el-form-item>
				<el-form-item prop="regions" label="收货地区" :rules="[{ required: true, message: '必须选择收货地区', trigger: ['blur', 'change']}]">
					<el-cascader style="width: 100%;" placeholder="请选择订单收货地区。支持输入搜索" v-model="order.regions" :options="tree_regions" :props="{expandTrigger: 'hover', value: 'id', label: 'name'}" filterable></el-cascader>
				</el-form-item>
				<el-form-item prop="address" label="详细地址" :rules="[{ required: true, message: '必须填写详细地址', trigger: ['blur', 'change']}]">
					<el-input v-model="order.address" placeholder="请输入收货详细地址" clearable></el-input>
				</el-form-item>
				<el-form-item prop="express" label="物流平台">
					<el-select v-model="order.express" placeholder="物流平台" filterable>
						<el-option v-for="(express, e) in expresses" :key="e" :label="express" :value="e"></el-option>         
					</el-select>
				</el-form-item>
				<el-form-item prop="status" label="订单状态">
					<el-select v-model="order.status" placeholder="订单状态" filterable>
						<el-option v-for="(stat, s) in status" :key="s" :label="stat" :value="s"></el-option>         
					</el-select>
				</el-form-item>
				<el-form-item prop="goods_no" label="商品编码">
					<el-input v-model="order.goods_no" placeholder="请输入商品编码"></el-input>
				</el-form-item>
				<el-form-item label="商品数量" prop="quantity" :rules="[{ required: true, message: '必须填写优惠金额'}]">
					<el-input-number v-model.number="order.quantity" placeholder="请输入修改后的金额" :step="1" :min="1"></el-input-number>
				</el-form-item>
				<el-form-item>
					<el-button slot="label" type="text" @click="downloadTemp">下载模板</el-button>
					<el-button type="primary" @click="submitForm('order_form')">提交添加</el-button>
					<el-upload class="dib ml10 mr10" accept=".xls,.xlsx" :auto-upload="false" :show-file-list="false" :on-change="importOrders" :action="$api.URI_UPLOADS" :disabled="!$utils.create($api.URI_ORDERS)">
						<el-button type="primary" :disabled="!$utils.create($api.URI_ORDERS)" plain>导入订单</el-button>
					</el-upload>
				</el-form-item>
			</template>
		</el-form>
	</el-dialog>
</template>

<script>
	import XLSX from "xlsx";
	import { mapState } from 'vuex';
	import { ad_export_json_to_excel_multi_sheet } from '@/utils/exportExcel';

	export default {
		computed: {
			...mapState(['theme', 'regions', 'admin_user']),
			tree_regions () {
				return this.$utils.buildTree(this.regions);
			},
			table_temp () {
				const thead = ['来源编码', '来源单号', '收货人', '手机号码', '地区ID', '地址详情', '快递编码', '状态编码', '商品编码', '商品数量', '快递单号', '下单时间', '商品金额'];
				const filter = ['source', 'out_no', 'linkman', 'mobile', 'region_id', 'address', 'express', 'status', 'goods_no', 'quantity', 'express_no', 'entered_at', 'goods_price'];
				if (!this.admin_user.shop_id) {
					thead.unshift('站点ID');
					filter.unshift('shop_id');
				}
				return {
					data: [],
					sheet: '订单列表',
					thead,
					filter
				}
			}
		},
		props: {
			expresses: {
				type: Object
			},
			status: {
				type: Object
			},
			sources: {
				type: Object
			},
			shops: {
				type: Array
			}
		},
		methods: {
			async open (r) {
				this.order = { ...r };
				this.order_dialog = true;
			},
			closeImporting (done) {
				if (!this.importing) return done();
				this.$confirm('是否中断导入操作？').then(() => {
					this.importing = false;
					done();
				});
			},
			closeImported (data) {
				const { thead, filter } = this.table_temp;
				this.importing = false;
				this.order_dialog = false;
				this.import_index = 0;
				this.import_count = 0;
				this.$emit('refresh');
				this.$utils.handleDownload([[...thead, '导入结果']], [...filter, 'result'], data, "客户导入结果" + this.$moment().format('YYYYMMDDHHmmss'), "导入明细");
			},
			importOrders ({raw}) {
				this.importing = true;
				const fileReader = new FileReader();
				fileReader.readAsBinaryString(raw);
				fileReader.onload = async ({target}) => {
					try {
						const { filter } = this.table_temp;
						const { Sheets, SheetNames } = XLSX.read(target.result, {type: "binary"});
						const data = XLSX.utils.sheet_to_json(Sheets[SheetNames[0]], {header: filter});
						data.shift();
						if (data.length <= 0) {
							this.importing = false;
							return this.$message.error("无需要导入记录！");
						}
						this.import_count = data.length;
						for (var i in data) {
							if (!this.importing) {
								this.closeImported(data);
								break;
							}
							const { out_no, source, linkman, express, status, shop_id, address, goods_no, quantity } = data[i];
							this.import_index++;
							if (!out_no) {
								data[i].result = "提交来源单号不能为空";
								continue;
							}
							if (!linkman) {
								data[i].result = "提交联系人不能为空";
								continue;
							}
							if (!goods_no) {
								data[i].result = "提交商品编码不能为空";
								continue;
							}
							if (!address) {
								data[i].result = "提交地址详情不能为空";
								continue;
							}
							if (!(quantity > 0)) {
								data[i].result = "提交商品必须大于0";
								continue;
							}
							if (!this.sources[source]) {
								data[i].result = "提交来源编码不存在，请参考来源编码Sheets";
								continue;
							}
							if (express && !this.expresses[express]) {
								data[i].result = "提交快递编码不存在，请参考快递编码Sheets";
								continue;
							}
							if (!this.status[status]) {
								data[i].result = "提交状态编码不存在，请参考状态编码Sheets";
								continue;
							}
							if (shop_id && !(shop_id > 0)) {
								data[i].result = "提交店铺ID必须大于0，请参考店铺Sheets";
								continue;
							}
							// if (!(region_id > 0)) {
							// 	data[i].result = "提交地区ID必须大于0，请参考地区Sheets";
							// 	continue;
							// }
							// 有转接格式，暂时去掉验证
							// const m_reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
							// if (mobile && !m_reg.test(mobile)) {
							// 	data[i].result = "手机格式不正确";
							// 	continue;
							// }
							const res = await this.$http.post(this.$api.URI_ORDERS, {...data[i]});
							const { code, msg } = res.data;
							data[i].result = msg;
							if (code != 0) continue;
						}
						if (this.import_index === this.import_count) {
							setTimeout(() => {
								this.closeImported(data);
							}, 1000);
						}
					} catch (e) {
						console.log(e);
					}
				}
			},
			async downloadTemp () {
				const res = await this.$http.get(this.$api.URI_GOODS, {params: {action: 'all'}, headers: {loading: true}});
				const { result } = res.data;
				const products = result.products ? result.products.map((p) => { return {...p, shop_name: p.shop ? p.shop.name : ''}; }) : [];
				const regions = [];
				this.regions.forEach((r) => {
					regions[r.id] = r.name;
					r.parent_name = regions[r.parent_id]; 
				});
				const expresses = [];
				for (let id in this.expresses) {
					expresses.push({id, name: this.expresses[id]});
				}
				const status = [];
				for (let id in this.status) {
					status.push({id, name: this.status[id]});
				}
				const sources = [];
				for (let id in this.sources) {
					sources.push({id, name: this.sources[id]});
				}
				const template = [
					{
						filter: ['id', 'name', 'parent_name'],
						thead: ['ID', '名称', '上级'],
						sheet: '地区ID',
						data: this.regions
					}, {
						filter: ['id', 'name'],
						thead: ['编码', '名称'],
						sheet: '快递编码',
						data: expresses
					}, {
						filter: ['id', 'name'],
						thead: ['编码', '名称'],
						sheet: '状态编码',
						data: status
					}, {
						filter: ['id', 'name'],
						thead: ['编码', '名称'],
						sheet: '来源编码',
						data: sources
					}, {
						filter: ['shop_name', 'number', 'title'],
						thead: ['站点', '编码', '名称'],
						sheet: '商品编码',
						data: products
					}
				];
				var title = '通用订单导入模板_生成于';
				if (!this.admin_user.shop_id) {
					template.push({
						filter: ['id', 'name'],
						thead: ['ID', '名称'],
						sheet: '站点ID',
						data: this.shops
					});
					title = '集团订单导入模板_生成于';
				}
				template.unshift(this.table_temp);
				ad_export_json_to_excel_multi_sheet(template, title + this.$moment().format('YYYYMMDDHHmmss'));
			},
			async submitOrder (data) {
				const res = await this.$http.post(this.$api.URI_ORDERS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.$emit('refresh', this.order);
						this.order_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'order_form':
							var { id, user_id, shop_id } = this.order;
							// if (!express) return this.$message.error("必须选择物流平台！");
							// 添加订单
							if (!id) {
								if (this.is_order_form_user && !user_id) return this.$message.error("必须选择关联用户！");
								if (this.is_order_form_shop && !shop_id) return this.$message.error("必须选择关联店铺！");
							}
							// console.log(this.order);
							this.submitOrder(this.order);
						break;
					}
				});
			},
		},
		data() {
			return {
				order: {},
				order_dialog: false,
				order_rules: {},
				importing: false,
				import_index: 0,
				import_count: 0,
			}
		}
	};
</script>
