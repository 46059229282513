<template>
	<div class="df fdc bcf1 oh" style="height: 100%;">
		<general-header title="创建企业"></general-header>
		<div class="f1 pt30 oa">
			<div class="df m0a bsbb aifs jcc" style="width: 1200px; height: 100%;">
				<div class="p20 br5 bcff" style="width: 380px;">
					<h3 class="c3 tac mb10">欢迎创建使用企业管理系统</h3>
					<el-form ref="loginForm" label-width="60px" :model="login_form" :rules="rules" @submit.prevent status-icon>
						<el-form-item label="简称" prop="short">
							<el-input type="text" v-model="login_form.short" autocomplete="off" placeholder="请输入简称" clearable></el-input>
						</el-form-item>
						<el-form-item label="行业" prop="industry">
							<el-input type="text" v-model="login_form.industry" autocomplete="off" placeholder="请输入行业" clearable></el-input>
						</el-form-item>
						<el-form-item label="名称" prop="name">
							<el-input type="text" v-model="login_form.name" autocomplete="off" placeholder="请输入名称" clearable></el-input>
						</el-form-item>
						<el-form-item label="类型" prop="type">
							<el-input type="text" v-model="login_form.type" autocomplete="off" placeholder="请输入类型" clearable></el-input>
						</el-form-item>
						<el-form-item label="法人" prop="legal">
							<el-input type="text" v-model="login_form.legal" autocomplete="off" placeholder="请输入法定代表人" clearable></el-input>
						</el-form-item>
						<el-form-item label="代码" prop="credit_code">
							<el-input type="text" v-model="login_form.credit_code" autocomplete="off" placeholder="请输入统一社会信用代码" clearable></el-input>
						</el-form-item>
						<el-form-item label="成立" prop="type">
							<el-input type="text" v-model="login_form.type" autocomplete="off" placeholder="请输入注册成立时间" clearable></el-input>
						</el-form-item>
						<el-form-item label="住所" prop="address">
							<el-input type="text" v-model="login_form.address" autocomplete="off" placeholder="请输入注册所在地址" clearable></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('loginForm')">确认创建</el-button>
							<el-button @click="$refs['loginForm'].resetFields();">重置</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<copy-right></copy-right>
	</div>
</template>

<style>

</style>

<script>
	import GeneralHeader from '@/components/general-header';
	import CopyRight from '@/components/general-copyright';

	export default {
		components: {
			GeneralHeader,
			CopyRight
		},
		methods: {
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error('填写表单有误！');
				});
			}
		},
		data() {
			return {
				login_form: {

				},
				rules: {
					short: [{required: true, message: '请输入简称！', trigger: 'blur'}],
					name: [{required: true, message: '请输入名称！', trigger: 'blur'}],
					legal: [{required: true, message: '请输入法定代表人！', trigger: 'blur'}],
					credit_code: [{required: true, message: '请输入统一社会信用代码！', trigger: 'blur'}],
					address: [{required: true, message: '请输入注册所在地址！', trigger: 'blur'}],
				}
			};
		},
		async created () {

		}
	}
</script>
