<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="客户/订单/合同" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="所属门店" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.services" placeholder="请选择服务" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="(type, t) in services" :key="t" :label="type" :value="t"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employees" placeholder="请选择老师" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getSuppliers(params)" plain>查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table class="scroll-wrapper absolute" height="100%" :data="suppliers" :size="theme.size" @sort-change="sortChange">
					<el-table-column label="服务老师" min-width="100" prop="employee.name"></el-table-column>
					<el-table-column label="销售订单" min-width="190">
						<template slot-scope="scope">
							<el-link type="primary" @click="$refs['show_dialog'].open(scope.row.order)">{{scope.row.order.no}}</el-link>
							<div class="lh1_3" v-if="scope.row.order.order_goods">
								<p v-for="goods in scope.row.order.order_goods" :key="goods.id">{{goods.title}}</p>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="签约合同" min-width="220">
						<template slot-scope="scope">
							<div class="lh1_3" v-if="scope.row.order.contract">
								<p>{{scope.row.order.contract.no}} {{scope.row.order.contract.price/100}} 元</p>
								<p>{{scope.row.order.contract.person}}，{{scope.row.order.contract.mobile}}</p>
								<p>{{scope.row.order.contract.address}}</p>
								<p>{{scope.row.order.contract.start_at | format_at}} - {{scope.row.order.contract.end_at | format_at}}</p>
							</div>
							<p v-else>暂无签约</p>
						</template>
					</el-table-column>
					<el-table-column label="交付方式" min-width="80" :formatter="({offline}) => { return offline ? '线下' : '线上'; }"></el-table-column>
					<el-table-column label="服务内容" min-width="140" :formatter="({service}) => { return services[service] || '未知'; }"></el-table-column>
					<el-table-column label="备注说明" min-width="140" prop="remarks" show-overflow-tooltip></el-table-column>
					<el-table-column label="交付时间" min-width="150" prop="delivery_at"></el-table-column>
					<template v-if="theme.width >= 1450">
						<el-table-column label="更新时间" min-width="150" prop="updated_at"></el-table-column>
						<el-table-column label="创建时间" min-width="150" prop="created_at"></el-table-column>
					</template>
					<el-table-column label="操作" width="60">
						<template slot-scope="scope">
							<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete($api.URI_DELIVERIES_SERVICES)" v-if="!scope.row.deleted_at">删除</el-button>
							<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore($api.URI_DELIVERIES_SERVICES)" v-else>恢复</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getSuppliers({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getSuppliers({ ...params, page: v }) }"
			background>
		</el-pagination>
		<template slot="other">
			<show-order ref="show_dialog" :employees="employees"></show-order>
		</template>
	</main-table-a>
</template>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import mainTableA from '@/components/main-table-a';
	import showOrder from '@/pages/oms/orders/show';

	export default {
		components: {
			mainTableA,
			showOrder
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		filters: {
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y/MM/DD');
			}
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getSuppliers(this.params);
			},
			onDelete (r) {
				this.$confirm('确定要删除这条记录吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_DELIVERIES_SERVICES, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getSuppliers(this.params);
					this.$message.success(msg);
				})
			},
			onRestore (r) {
				this.$confirm('确定要恢复这条记录吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_DELIVERIES_SERVICES, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getSuppliers(this.params);
					this.$message.success(msg);
				})
			},
			async getSuppliers (p, l = false) {
				const res = await this.$http.get(this.$api.URI_DELIVERIES_SERVICES, {params: p, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.suppliers = result.data;
				this.services = result.services;
				this.employees = result.employees;
				this.params = {
					...p,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				services: [],
				suppliers: [],
				employees: [],
				supplier: {},
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getSuppliers(this.params, true)
		}
	};
</script>