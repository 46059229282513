<template>
	<div class="oa" style="height: 100%;">
		<div v-for="(meta, m) in goods.attachment_meta" :key="m">
			<el-divider content-position="left"><b>{{meta}}</b></el-divider>
			<draggable v-model="goods.attachments" @end="onDragEnd">
				<template v-for="attachment in goods.attachments">
					<div class="g-attachment" :key="attachment.id" v-if="attachment.meta == m">
						<el-image class="attach-img" fit="cover" :src="attachment.url" :preview-src-list="goods.attachments.map((a) => { return a.url; })">
							<div slot="error" class="attach-err">{{attachment.type}}</div>
						</el-image>
						<div class="attach-mark">
							<i class="el-icon-edit cb7a06a"></i>
							<el-popconfirm title="确定要删除这个附件吗？" @confirm="handleAttachments('delete', attachment)" v-if="!attachment.deleted_at">
								<i slot="reference" class="el-icon-delete cb7a06a" :disabled="!$utils.delete($api.URI_GOODS_ATTACHMENTS)"></i>
							</el-popconfirm>
							<el-popconfirm title="确定要恢复这个附件吗？" @confirm="handleAttachments('restore', attachment)" v-else>
								<i slot="reference" class="el-icon-refresh-left cb7a06a" :disabled="!$utils.restore($api.URI_GOODS_ATTACHMENTS)"></i>
							</el-popconfirm>
						</div>
					</div>
				</template>
			</draggable>
			<div>
				<el-upload :accept="$utils.uploadConfig('goods_attachment'+m).ext.map((e) => { return '.'+e; }).join(',')" :action="$api.URI_UPLOADS" :show-file-list="false" :on-success="(r) => { onSuccess(r, m) }" :data="{type: $utils.uploadConfig('goods_attachment'+m).type}" :disabled="!$utils.create($api.URI_GOODS_ATTACHMENTS)" multiple>
					<el-button slot="trigger" size="mini" type="primary">选取文件</el-button>
					<div slot="tip" class="el-upload__tip">只能上传 {{$utils.uploadConfig('goods_attachment'+m).ext.join('/').toUpperCase()}} 格式的文件，且大小不超过 {{$utils.uploadConfig('goods_attachment'+m).format_size_unit}}</div>
				</el-upload>
			</div>
		</div>
	</div>
</template>

<style type="text/css">
	.g-attachment {
		width: 80px;
		margin: 0 5px 5px;
		position: relative;
		display: inline-block;
	}
	.el-upload-dragger,
	.g-attachment .attach-img {
		width: 80px;
		height: 80px;
	}
	.g-attachment .attach-err {
		width: 100%;
		height: 100%;
		display: flex;
		font-size: 20px;
		font-weight: 600;
		align-items: center;
		justify-content: center;
		background-color: #fafafa;
	}
	.g-attachment .attach-mark {
		display: flex;
		padding: 5px 0;
		cursor: pointer;
		position: absolute;
		align-items: center;
		justify-content: space-around;
		background-color: rgba(0,0,0, 0.5);
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
	}
	.g-attachment:hover .attach-mark {
		opacity: 1;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import draggable from 'vuedraggable';

	export default {
		props: {
			goods: {
				type: Object
			}
		},
		components: {
			draggable
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			onDragEnd () {
				this.submitAttachments({attachments: this.goods.attachments.map((a) => { return a.id; })});
			},
			async handleAttachments (a, r) {
				const res = await this.$http.get(this.$api.URI_GOODS_ATTACHMENTS, {params: {action: a, id: r.id}, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.$emit('refresh');
					}
				});
			},
			async submitAttachments (data) {
				const res = await this.$http.post(this.$api.URI_GOODS_ATTACHMENTS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.$emit('refresh');
					}
				});
			},
			async onSuccess (r, meta = 0) {
				const { code, msg, result } = r;
				const { number } = this.goods;
				if (code) return this.$message.error(name+msg);
				const res = await this.$http.post(this.$api.URI_GOODS_ATTACHMENTS, {id: result.id, number, meta});
				if (res.data.code != 0) return this.$message.error(res.data.msg);
				this.$emit('refresh');
			}
		},
		data() {
			return {
				attachments: []
			}
		}
	};
</script>