<template>
	<el-dialog width="460px" :title="recruitment.id ? (read_only ? '招聘详情' :'编辑招聘') : '创建招聘'" :visible.sync="show_dialog" :fullscreen="theme.width < 680" :close-on-click-modal="false" append-to-body destroy-on-close>
		<div class="pr" style=" margin-top: -20px;" v-if="read_only">
			<el-descriptions :labelStyle="{width: '70px'}" :title="'No.'+recruitment.no" :column="2" :size="theme.size" border>
				<el-descriptions-item label="申请人">{{recruitment.employee.name}}</el-descriptions-item>
				<el-descriptions-item label="名称">{{recruitment.title}}</el-descriptions-item>
				<el-descriptions-item label="薪资">{{recruitment | format_pay}} / {{fields.pay[recruitment.pay_type] || '/'}}</el-descriptions-item>
				<el-descriptions-item label="性质">{{fields.nature[recruitment.nature] || '/'}}</el-descriptions-item>
				<el-descriptions-item label="紧急">{{fields.urgency[recruitment.urgency] || '/'}}</el-descriptions-item>
				<el-descriptions-item label="经验">{{fields.experience[recruitment.experience] || '/'}}</el-descriptions-item>
				<el-descriptions-item label="学历">{{fields.edu[recruitment.education] || '/'}}</el-descriptions-item>
				<el-descriptions-item label="人数">{{recruitment.amount}}</el-descriptions-item>
				<el-descriptions-item label="最迟到岗">{{recruitment | latest_arrival}}</el-descriptions-item>
				<el-descriptions-item label="创建时间">{{recruitment.created_at}}</el-descriptions-item>
				<el-descriptions-item label="描述说明">{{recruitment.description}}</el-descriptions-item>
			</el-descriptions>
			<div class="pa fs38 fwb" style="color: rgba(255, 0, 0, 0.2); top: 100px; left: 50%; transform: translate(-50%, -50%) rotateZ(20deg);" v-if="fields.status[recruitment.status]">{{fields.status[recruitment.status]}}</div>
		</div>
		<el-form label-width="90px" ref="recruitment_create" :model="recruitment" :rules="recruitment_rules" :size="theme.size" v-else status-icon>
			<el-form-item label="名称" prop="title">
				<el-input v-model="recruitment.title" placeholder="请输入名称" clearable></el-input>
			</el-form-item>
			<el-form-item label="薪资" style="margin-bottom: 0;" required>
				<el-col :span="7">
					<el-form-item prop="pay_min">
						<el-input v-model.number="recruitment.pay_min" placeholder="最小薪资" clearable></el-input>
					</el-form-item>
				</el-col>
				<el-col class="tac" :span="1">-</el-col>
				<el-col :span="7">
					<el-form-item prop="pay_max">
						<el-input v-model.number="recruitment.pay_max" placeholder="最大薪资" clearable></el-input>
					</el-form-item>
				</el-col>
				<el-col class="tac" :span="1">/</el-col>
				<el-col :span="6">
					<el-form-item prop="pay_type">
						<el-select v-model="recruitment.pay_type" placeholder="周期" filterable>
							<el-option v-for="(pay, p) in fields.pay" :key="p" :label="pay+'薪'" :value="+p"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col class="tac" :span="2">
					<el-tooltip placement="top">
						<div slot="content">只填最大薪资时为：固定金额<br>只填最小薪资时为：大于等于金额<br>最小最大都填时为：范围金额</div>
						<i class="el-icon-question"></i>
					</el-tooltip>
				</el-col>
			</el-form-item>
			<el-form-item label="性质/紧急" style="margin-bottom: 0;" required>
				<el-col :span="11">
					<el-form-item prop="nature">
						<el-select v-model="recruitment.nature" placeholder="请选择性质" filterable clearable>
							<el-option v-for="(nature, n) in fields.nature" :key="n" :label="nature" :value="+n"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col class="tac" :span="2">-</el-col>
				<el-col :span="11">
					<el-form-item prop="urgency">
						<el-select v-model="recruitment.urgency" placeholder="请选择紧急" filterable clearable>
							<el-option v-for="(urgency, u) in fields.urgency" :key="u" :label="urgency" :value="+u"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-form-item>
			<el-form-item label="经验/学历" style="margin-bottom: 0;" required>
				<el-col :span="11">
					<el-form-item prop="experience">
						<el-select v-model="recruitment.experience" placeholder="请选择经验" filterable clearable>
							<el-option v-for="(experience, e) in fields.experience" :key="e" :label="experience" :value="+e"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col class="tac" :span="2">-</el-col>
				<el-col :span="11">
					<el-form-item prop="education">
						<el-select v-model="recruitment.education" placeholder="请选择学历" filterable clearable>
							<el-option v-for="(education, e) in fields.edu" :key="e" :label="education" :value="+e"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-form-item>
			<el-form-item label="计划人数" prop="amount">
				<el-input-number v-model="recruitment.amount" :min="1" :max="999" placeholder="计划人数"></el-input-number>
			</el-form-item>
			<el-form-item label="最迟到岗" prop="latest_arrival">
				<el-date-picker v-model="recruitment.latest_arrival" type="date" placeholder="选择最迟到岗时间" :picker-options="{ disabledDate (time) { return time.getTime() < Date.now(); } }"></el-date-picker>
			</el-form-item>
			<el-form-item label="描述说明" prop="description">
				<el-input v-model="recruitment.description" type="textarea" placeholder="请输入描述说明" rows="3" maxlength="255" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item v-if="!read_only">
				<el-button type="primary" @click="submitForm('recruitment_create')">{{recruitment.id ? '提交保存' : '提交创建'}}</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';

	export default {
		computed: {
			...mapState(['theme']),
			read_only () {
				if (this.recruitment.status == 2) return true;
				if (this.recruitment.status == 4) return true;
				return false;
			}
		},
		props: {
			fields: {
				type: Object
			}
		},
		filters: {
			format_pay ({ pay_min, pay_max }) {
				var title = '';
				if (pay_min && pay_max) {
					title = pay_min + '-' + pay_max + '元';	
				}
				if (!pay_min) {
					title = pay_max + '元';	
				}
				if (!pay_max) {
					title = pay_min + '元以上';	
				}
				return title;
			},
			latest_arrival ({latest_arrival}) {
				return moment(latest_arrival).format('Y-MM-DD');
			}
		},
		methods: {
			async open (r = {}) {
				this.recruitment = { pay_type: 1, ...r, pay_min: (r.pay_min || 0)/100, pay_max: (r.pay_max || 0)/100};
				this.show_dialog = true;
			},
			async submitrecruitments (data) {
				const res = await this.$http.post(this.$api.URI_POSITIONS_RECRUITMENTS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.$emit('refresh');
						this.show_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'recruitment_create':
							this.submitrecruitments(this.recruitment);
						break;
					}
				});
			},
			onStatus (r) {
				const { id, title, status } = r;
				switch (status) {
					case 1:
					case 3:
						if (!this.$utils.create(this.$api.URI_APPROVALS)) return this.$message.error('暂无提交审核权限！');
						this.$confirm('申请招聘 ' + title, '确定要提交审核吗？', {
							dangerouslyUseHTMLString: true,
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							const res = await this.$http.post(this.$api.URI_APPROVALS, {common_model_id: id, common_model_type: 'App\\Models\\Recruitment'});
							const { code, msg: message } = res.data;
							if (code != 0) return this.$message.error(message);
							this.$message({
								type: 'success',
								duration: 1000,
								message,
								onClose: () => {
									this.$emit('refresh');
								}
							});
						});
					break;

					default:
						this.open(r);
					break;
				}
			}
		},
		data() {
			const checkPay = (rule, value, callback) => {
				const { pay_min, pay_max } = this.recruitment;
				if (!pay_min && !pay_max) return callback(new Error('薪资最小和最大必填一个'));
				return callback();
			}

			return {
				recruitment: {},
				show_dialog: false,
				recruitment_rules: {
					title: [{ required: true, message: '必须填写名称', trigger: 'blur'}],
					nature: [{ required: true, message: '必须选择性质', trigger: 'blur'}],
					urgency: [{ required: true, message: '必须选择紧急程度', trigger: 'blur'}],
					experience: [{ required: true, message: '必须选择工作经验', trigger: 'blur'}],
					education: [{ required: true, message: '必须选择学历程度', trigger: 'blur'}],
					amount: [{ required: true, message: '必须填写招聘人数', trigger: 'blur'}],
					pay: [{ required: true, validator: checkPay, trigger: 'blur'}],
					latest_arrival: [{ required: true, message: '必须选择最迟到岗时间', trigger: 'blur'}]
				}
			}
		}
	};
</script>