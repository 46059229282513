<template>
	<div class="df fdc" style="height: 100%;">
		<div class="scroll-wrapper pr10 oa" style="height: 100%;">
			<el-button type="primary" size="mini" icon="el-icon-edit-outline" @click="showPayment({common_type: 'App\\Models\\Order', common_id: order.id, common_no: order.no })" plain>创建申请</el-button>
			<template v-if="payments.length">
				<el-descriptions class="payment mt10" v-for="payment in payments" :key="payment.no" :size="theme.size" border>
					<template slot="title">
						<el-link type="primary" class="fs16 fwb" :disabled="!$utils.update($api.URI_EMPLOYEES_PAYMENTS)" @click="showPayment({...payment, common_no: payment.common ? payment.common.no : '', amount: payment.amount/100})">No.{{payment.no}}</el-link>
						<p class="fs12 dib pl10">{{payment.employee.name}} 创建于 {{payment.created_at}}</p>
					</template>
					<template slot="extra">
						<el-link type="primary" :disabled="!$utils.delete($api.URI_EMPLOYEES_PAYMENTS)" @click="$refs['payment_show'].onRestore(payment)">删除</el-link>
					</template>
					<el-descriptions-item label="付款公司">{{payment.company ? payment.company.name : '不限'}}</el-descriptions-item>
					<el-descriptions-item label="金额">{{payment.amount/100 + ' 元'}}</el-descriptions-item>
					<el-descriptions-item label="状态">
						<el-tooltip effect="dark" placement="top" :content="'OA审核编号'+payment.approval.sp_no" v-if="payment.approval">
							<el-link type="primary" @click="$refs['payment_show'].onStatus(payment)">{{statuses[payment.status] || '未知'}}</el-link>
						</el-tooltip>
						<el-link type="primary" @click="$refs['payment_show'].onStatus(payment)" v-else>{{statuses[payment.status] || '未知'}}</el-link>
					</el-descriptions-item>
					<el-descriptions-item label="付款方式">{{ways[payment.way] || '未知'}}</el-descriptions-item>
					<el-descriptions-item label="备注说明">{{payment.remarks || '/'}}</el-descriptions-item>
				</el-descriptions>
			</template>
			<el-divider v-else>暂无记录</el-divider>
		</div>
		<payment-show ref="payment_show" :ways="ways" :companies="companies" :types="common_types" @refresh="getPayments(params)"></payment-show>
	</div>
</template>

<style type="text/css">
	.payment .el-descriptions__header {
		margin-bottom: 5px;
	}
	.payment .el-descriptions__title {
		color: #666;
		font-size: 14px;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import paymentShow from '@/pages/fms/payments/show';

	export default {
		components: {
			paymentShow
		},
		props: {
			order: {
				type: Object
			}
		},
		computed: {
			...mapState(['theme'])
		},
		filters: {
			format_price (value) {
				return (value/100).toFixed(2);
			}
		},
		methods: {
			showPayment (r) {
				if (r.status == 4 || r.status == 5 || r.status == 6) {
					r.credentials = r.attachments.filter((a) => a.meta === 1);
					r.documents = r.attachments.filter((a) => a.meta === 2);
					r.attachments = r.attachments.filter((a) => a.meta === 0);
				}
				this.$refs['payment_show'].open(r);
			},
			async getPayments (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_EMPLOYEES_PAYMENTS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.payments = result.data;
				this.ways = result.ways;
				this.statuses = result.statuses;
				this.companies = result.companies;
				this.common_types = result.common_types;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				ways: {},
				payments: [],
				companies: [],
				common_types: {},
				params: {
					perPage: 10
				}
			}
		},
		mounted () {
			const { id: order_id } = this.order;
			this.getPayments({...this.params, order_id});

		}
	};
</script>