<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="标题 or 关键词 or 描述" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable>
						<el-option label="创建时间" value="created"></el-option>
						<el-option label="更新时间" value="updated"></el-option>
					</el-select>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getVideos({ ...params, page: 1})" plain>查询</el-button>
					<el-button type="primary" @click="video_dialog = true;" :disabled="!$utils.create('videos')">添加视频</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table :data="videos" height="100%" class="scroll-wrapper absolute" :size="theme.size" @sort-change="sortChange">
					<el-table-column fixed="left" type="selection" width="42"></el-table-column>
					<el-table-column label="封面图" width="100">
						<el-image slot-scope="scope" class="video_img" :src="scope.row.poster" :preview-src-list="[scope.row.poster]">
							<i slot="error" class="el-icon-picture-outline"></i>
						</el-image>
					</el-table-column>
					<el-table-column prop="id" label="名称/ID" sortable="custom">
						<template slot-scope="scope">
							<h3>{{scope.row.title}}</h3>
							<p>
								{{scope.row.id}}
								<span> · {{scope.row.duration}} 秒</span>
							</p>
							<div>
								<el-checkbox :size="theme.size" @change="changeVideos({ action: 'publishing', id: scope.row.id})" :value="scope.row.published_at!=null" :disabled="!$utils.checkAuth('videos', 'publishing') || scope.row.deleted_at">发布</el-checkbox>
								<el-checkbox :size="theme.size" @change="changeVideos({ action: 'topping', id: scope.row.id})" :value="scope.row.topped_at!=null" :disabled="!$utils.checkAuth('videos', 'topping') || scope.row.deleted_at">置顶</el-checkbox>
							</div>
							<p style="margin-top: 2px;">
								<el-tag size="mini" style="margin-right: 10px;" v-for="tag in scope.row.tags" :key="tag.id">{{tag.name}}</el-tag>
							</p>					
						</template>
					</el-table-column>
					<el-table-column prop="views" label="SEO优化/浏览" sortable="custom">
						<template slot-scope="scope">
							<el-input v-model="scope.row.keywords" placeholder="请输入视频关键词" @change="submitVideos(scope.row)" :size="theme.size" style="margin-bottom: 5px" :readonly="!$utils.update('videos')" :disabled="scope.row.deleted_at" maxlength="48" show-word-limit>
								<template slot="prepend">{{scope.row.views}}</template>
							</el-input>
							<el-input type="textarea" rows="2" v-model="scope.row.description" placeholder="请输入视频描述" @change="submitVideos(scope.row)" :size="theme.size" :readonly="!$utils.update('videos')" :disabled="scope.row.deleted_at" maxlength="168" show-word-limit></el-input>
						</template>
					</el-table-column>
					<el-table-column prop="at" label="操作时间" width="210" v-if="theme.width >= 1080">
						<template slot-scope="scope">
							<template v-if="scope.row.deleted_at">删除：{{scope.row.deleted_at}}</template>
							<template v-else>更新：{{scope.row.updated_at}}<br>创建：{{scope.row.created_at}}</template>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="100">
						<template slot-scope="scope">
							<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore('videos')" v-if="scope.row.deleted_at">恢复</el-button>
							<template v-else>
								<el-button type="text" @click="onEditor(scope.row)" :disabled="!$utils.update('videos')">编辑</el-button>
								<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete('videos')">删除</el-button>
							</template>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getVideos({...params, page: 1, perPage: v}) }"
			@current-change="(v) => { getVideos({ ...params, page: v}) }"
			background>
		</el-pagination>
		<template slot="other">
			<!-- 添加编辑视频-->
			<el-dialog width="420px" :title="video.id ? '更新视频' : '添加视频'" :visible.sync="video_dialog" :fullscreen="theme.width < 420" @closed="video = {}; $refs['video_form'].resetFields();">
				<el-form ref="video_form" label-width="0px" :model="video" :rules="video_rules" :size="theme.size" status-icon>
					<el-form-item prop="title">
						<el-input v-model="video.title" autocomplete="off" placeholder="请输入标题" maxlength="38" show-word-limit clearable></el-input>
					</el-form-item>
					<el-form-item prop="description">
						<el-input v-model="video.description" type="textarea" rows="3" placeholder="请输入描述" maxlength="255" show-word-limit></el-input>
					</el-form-item>
					<el-form-item prop="url">
						<el-upload
							:action="$api.URI_UPLOADS"
							:accept="video_cover_cfg.ext.map((e) => { return '.'+e; }).join(',')"
							:on-success="(r, f, l) => { !r.code ? (video = {...video, url: r.result.url}) : $message.error(r.msg)}"
							:data="{type: video_cover_cfg.type}"
							:show-file-list="false"
							:before-upload="beforecoverAttach">
							<el-input placeholder="点击上传视频" v-model="video.url" readonly></el-input>
							<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
						</el-upload>
					</el-form-item>
					<el-form-item prop="poster" v-if="video.url">
						<el-upload
							:action="$api.URI_UPLOADS"
							:accept="video_cover_cfg.ext.map((e) => { return '.'+e; }).join(',')"
							:on-success="(r, f, l) => { !r.code ? (video = {...video, poster: r.result.url}) : $message.error(r.msg)}"
							:data="{type: video_cover_cfg.type}"
							:show-file-list="false"
							:before-upload="beforecoverAttach">
							<el-input placeholder="点击上传封面" v-model="video.poster" readonly></el-input>
							<div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
						</el-upload>
					</el-form-item>
					<el-form-item v-if="video.url">
						<video-player ref="videoPlayer" :options="player_options"></video-player>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('video_form')">{{video.id ? '保存更新' : '提交添加'}}</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</template>
	</main-table-a>
</template>
<!-- http://ip-api.com/json/183.239.161.21?lang=zh-CN -->

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style type="text/css">
	.el-upload {
		width: 100%;
	}
	.video_img {
		width: 80px;
		height: 80px;
		font-size: 40px;
		line-height: 80px;
		text-align: center;
		background-color: #f1f1f1;
	}
	.view-tag {
		color: #fff;
		font-size: 12px;
		padding: 0 10px;
		background-color: rgba(0, 0, 0, .6);
		position: absolute;
		right: 0;
		top: 0;
	}
	.vjs-poster {
		background-size: cover;
	}
	/*.vjs-icon-upload {
		cursor: pointer;
	}
	.vjs-icon-upload .vjs-icon-placeholder:before {
		content: "1";
	}*/
</style>

<script>
	import { mapState } from 'vuex';
	import { videoPlayer } from 'vue-video-player';
	import 'video.js/dist/video-js.css';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			videoPlayer,
			mainTableA
		},
		computed: {
			...mapState(['theme', 'admin_user']),
			video_cover_cfg () {
				return this.$utils.uploadConfig('video_cover');
			},
			video_detail_cfg () {
				return this.$utils.uploadConfig('video_detail');
			},
			player_options () {
				const { url: src, poster } = this.video;
				return {
					poster,
					width: 380,
					controls: true,
					playbackRates: [0.7, 1.0, 1.5, 2.0],
					sources: [{
						type: "video/mp4",
						src
					}]
				}
			}
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getVideos(p);
			},
			beforecoverAttach (file) {
				const { size, format_size_unit } = this.video_cover_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传封面图不能超过 '+format_size_unit);
				return is;
			},
			async changeVideos (r) {
				const res = await this.$http.get(this.$api.URI_VIDEOS, {params: r, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
				this.getVideos(this.params);
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'video_form':
							this.submitVideos(this.video);
						break;
					}
				});
			},
			async submitVideos (data) {
				const res = await this.$http.post(this.$api.URI_VIDEOS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.getVideos(this.params);
						this.video_dialog = false;
					}
				});
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.title+'】视频吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_VIDEOS, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getVideos(this.params);
						}
					});
				})
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.title+'】视频吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_VIDEOS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getVideos(this.params);
						}
					});
				})
			},
			async onEditor (r) {
				this.video = { ...r};
				this.video_dialog = true;
				// 控制栏添加按钮
				// this.$nextTick(() => {
				// 	var button = this.$refs.videoPlayer.player.controlBar.addChild('button');
				// 	console.log(button);
				// 	button.addClass('vjs-icon-upload');
				// 	button.setAttribute('title', '上传视频');
				// 	button.el().innerHTML = "<i class='el-icon-upload2' style='font-size: 1.2em;'></i>";
				// 	button.on('click', function (e) {
				// 		console.log(e);
				// 	});
				// });
			},
			async getVideos (p, l = false) {
				const res = await this.$http.get(this.$api.URI_VIDEOS, {params: p, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.videos = result.data;
				this.shops = result.shops;

				this.categories = this.$utils.buildTree(result.categories);
				this.params = {
					...p,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				tags: [],
				shops: [],
				videos: [],
				categories: [],
				video_dialog: false,
				video: {},
				params: {
					perPage: 10,
				},
				video_rules: {
					title: [{ required: true, message: '请输入课程标题', trigger: 'blur' }]
				}
			}
		},
		created () {
			this.getVideos(this.params, true);
		}
	};
</script>