<template>
	<el-dialog width="720px" :title="employee.name + ' 薪酬配置'" :visible.sync="configs_dialog" append-to-body destroy-on-close>
		<template v-if="show_table">
			<el-table style="margin-top: -30px;" class="scroll-wrapper" height="47vh" :data="configs" :size="theme.size">
				<el-table-column label="名称" prop="name" min-width="70"></el-table-column>
				<el-table-column label="类型" prop="salary_field.name" min-width="70"></el-table-column>
				<el-table-column label="配值" min-width="80" :formatter="({amount}) => { return +amount; }"></el-table-column>
				<el-table-column label="备注说明" prop="commit" min-width="100" show-overflow-tooltip></el-table-column>
				<el-table-column label="更新时间" prop="updated_at" min-width="150"></el-table-column>
				<el-table-column label="创建时间" prop="created_at" min-width="150"></el-table-column>
				<el-table-column label="操作" min-width="50">
					<el-link slot="header" type="primary" size="mini" @click="config = {}; config_dialog = true;">添加</el-link>
					<el-link slot-scope="scope" type="primary" @click="delRow(scope.row)">删除</el-link>
				</el-table-column>
			</el-table>
			<el-pagination
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getConfigs({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getConfigs({ ...params, page: v }) }"
				background>
			</el-pagination>
		</template>
		<template v-else>
			<el-result icon="warning" title="警告提示" subTitle="暂不支持为员工设置薪酬配置，请前往薪酬列表初始化薪酬配置字段"></el-result>
		</template>
		<el-dialog :title="config.id ? '编辑配置' : '添加配置'" width="380px" :visible.sync="config_dialog" append-to-body destroy-on-close>
			<el-form label-width="80px" ref="configs_form" :model="config" :rules="rules" :size="theme.size" status-icon>
				<el-form-item label="名称" prop="name">
					<el-input v-model="config.name" placeholder="请输入名称" clearable>
						<el-select slot="prepend" style="width: 90px;" v-model="config.salary_field_id" placeholder="类型" filterable clearable>
							<el-option v-for="field in fields" :key="field.id" :label="field.name" :value="field.id"></el-option>
						</el-select>
					</el-input>
				</el-form-item>
				<el-form-item label="配值" prop="amount">
					<el-input-number placeholder="配值" v-model="config.amount" :precision="2" :step="0.01" :min="0.01"></el-input-number>
				</el-form-item>
				<el-form-item label="备注说明" prop="commit">
					<el-input v-model="config.commit" type="textarea" placeholder="请输入备注说明。非必填" rows="3" maxlength="255" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('configs_form')">{{config.id ? '提交保存'  : '提交创建'}}</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			open (employee) {
				this.getConfigs({ employee_id: employee.id }, true);
				this.employee = employee;
				this.configs_dialog = true;
			},
			delRow ({id, name}) {
				this.$confirm(name, '确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleConfig({action: 'delete', id});
				});
			},
			async handleConfig (params) {
				const res = await this.$http.get(this.$api.URI_SALARIES_CONFIGS, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getConfigs(this.params);
				this.$message.success(msg);
				this.$emit('refresh');
			},
			async submitConfig (data) {
				const res = await this.$http.post(this.$api.URI_SALARIES_CONFIGS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.getConfigs(this.params);
						this.config_dialog = false;
						this.$emit('refresh');
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					const { id: employee_id } = this.employee;
					if (!employee_id) return this.$message.error("未知员工！");
					switch (ref) {
						case 'configs_form':
							this.submitConfig({employee_id, ...this.config});
						break;
					}
				});
			},
			async getConfigs (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_SALARIES_CONFIGS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.configs = result.data;
				this.fields = result.fields;
				this.show_table = (result.fields && result.fields.length);
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				employee: {},
				config: {},
				fields: [],
				configs: [],
				configs_dialog: false,
				config_dialog: false,
				show_table: true,
				rules: {
					name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
					amount: [{ required: true, message: '请输入配值', trigger: 'blur' }]
				},
				params: {
					perPage: 10
				}
			}
		}
	};
</script>