<template>
	<div>
		<el-form :inline="true" :model="params" :size="theme.size" v-if="!theme.is_mobile">
			<el-form-item label="关键词">
				<el-input v-model="params.keywords" placeholder="请输入应用名称关键词" clearable></el-input>
			</el-form-item>
			<el-form-item label="应用类型">
				<el-select v-model="params.type" placeholder="应用类型" filterable clearable>
					<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getWochat({ ...params, page: 1})">查询</el-button>
				<el-button type="primary" :disabled="!$utils.create('wochat/apps')" @click="drawer = true">添加应用</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="apps" :max-height="theme.height - 310" :size="theme.size">
			<el-table-column prop="name" label="应用名称" width="180">
				<template slot-scope="scope">
					<p>{{scope.row.name}} <template v-if="scope.row.wechat_app"> @{{scope.row.wechat_app.name}}</template></p>
					<p>{{types[scope.row.type]}} <template v-if="scope.row.wochat_app"> #{{scope.row.wochat_app.name}}</template></p>
				</template>
			</el-table-column>
			<el-table-column prop="agentid" label="应用密钥">
				<template slot-scope="scope">
					<p v-if="scope.row.agentid">{{scope.row.agentid}}</p>
					<p v-if="scope.row.secret">{{scope.row.secret}}</p>
				</template>
			</el-table-column>
			<el-table-column prop="token" label="应用回调">
				<template slot-scope="scope">
					<p v-if="scope.row.token">{{scope.row.token}}</p>
					<p v-if="scope.row.encodingAESKey">{{scope.row.encodingAESKey}}</p>
				</template>
			</el-table-column>
			<el-table-column prop="created_at" label="维护时间" width="240">
				<template slot-scope="scope">
					<p v-if="scope.row.created_at">创建于 {{scope.row.created_at}}</p>
					<p v-if="scope.row.updated_at">更新于 {{scope.row.updated_at}}</p>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="更多操作" width="120">
				<template slot-scope="scope">
					<template v-if="scope.row.deleted_at">已删除</template>
					<template v-else>
						<el-button type="text" :disabled="!$utils.update('wochat/apps')" @click="create_form = {...scope.row, shop_id: scope.row.shop ? scope.row.shop.id : ''}; drawer = true;">编辑</el-button>
						<el-button type="text" :disabled="!$utils.delete('wochat/apps')" @click="onDelete(scope.row)">删除</el-button>
					</template>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => {getWochat({ ...params, page: 1, perPage: v})}"
			@current-change="(v) => {getWochat({ ...params, page: v})}"
			background></el-pagination>
		<el-dialog :title="create_form.id ? '修改应用' : '添加应用'" :visible.sync="drawer" @closed="$refs['createForm'].resetFields(); create_form = {}">
			<el-form status-icon :model="create_form" :size="theme.size" ref="createForm" label-width="100px">
				<el-form-item label="名称" prop="name" required>
					<el-input v-model="create_form.name" :disabled="create_form.type == 2" autocomplete="off" placeholder="请输入应用名称">
						<el-select slot="prepend" style="width: 180px;" v-model="create_form.shop_id" placeholder="绑定店铺" filterable>
							<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
						</el-select>
					</el-input>
				</el-form-item>
				<el-form-item label="应用/模板" prop="agentid" required>
					<el-input v-model="create_form.agentid" :disabled="create_form.type == 2" autocomplete="off" placeholder="请输入应用ID/模板ID">
						<el-select slot="prepend" style="width: 200px;" v-model="create_form.type" :disabled="create_form.type == 2" placeholder="应用类型" filterable>
							<el-option v-for="(type, t) in types" :key="t" :label="type + (t == 2 ? '[仅服务商后台创建]' : '')" :value="t" :disabled="t == 2"></el-option>
						</el-select>
						<template slot="append" v-if="create_form.type == 2">仅服务商后台重置</template>
					</el-input>
				</el-form-item>
				<el-form-item label="密钥" prop="secret" required>
					<el-input v-model="create_form.secret" :disabled="create_form.type == 2" autocomplete="off" placeholder="请输入应用密钥">
						<template slot="append" v-if="create_form.type == 2">仅服务商后台重置</template>
					</el-input>
				</el-form-item>
				<el-form-item label="token" prop="token" required>
					<el-input v-model="create_form.token" autocomplete="off" placeholder="请输入token"></el-input>
				</el-form-item>
				<el-form-item label="AESKey" prop="encodingAESKey" required>
					<el-input v-model="create_form.encodingAESKey" autocomplete="off" placeholder="请输入AESKey"></el-input>
				</el-form-item>
				<el-form-item label="回调URL" v-if="create_form.wechat_app">https://api.ycfawu.com/adminv1/wochat/agenta/{{create_form.wechat_app.corpid}}/{{create_form.agentid}}</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('createForm')">确 定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		computed: {
			...mapState(['theme']),
			is_mobile () {
				return this.$utils.isMobile();
			}
		},
		methods: {
			onDelete (r) {
				this.$confirm('确定要删除【'+r.name+'】应用吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_WOCHAT_APPS, {params: {action: 'destroy', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getWochat(this.params)
						}
					});
				})
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					this.submitWochatApp(this.create_form);
				})
			},
			async submitWochatApp (data) {
				const res = await this.$http.post(this.$api.URI_WOCHAT_APPS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 2000,
					message: msg,
					onClose: () => {
						this.drawer = false;
						this.getWochat(this.params);
					}
				});
			},
			async getWochat (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_WOCHAT_APPS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.apps = result.data;
				this.types = result.types;
				this.shops = result.shops;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				apps: [],
				types: [],
				shops: [],
				drawer: false,
				create_form: {},
				params: {
					perPage: 10,
				}
			}
		},
		created () {
			this.getWochat(this.params, true);
		}
	};
</script>