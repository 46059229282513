<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<div class="df">
				<div class="p10 mr20 mb20 br5 miw100 cp" :style="'box-shadow: 0 0 10px 5px #f1f1f1; background-color: ' + (status.checked ? '#b7a06a' : '#e2d6ba')" v-for="(status, s) in status_tabs" :key="s" @click="toggleStatus(s)">
					<div class="df jcsb mb5">
						<div class="fs16 fw6 c3">{{counts['status_'+s] || 0}}</div>
					</div>
					<div class="fs14 c6">{{status.name}}</div>
				</div>
				<div class="mb10">
					<el-button type="primary" size="mini" @click="show_fillter = !show_fillter" :plain="!show_fillter">{{show_fillter ? '收起' : '筛选'}}</el-button>
				</div>
			</div>
			<el-form :inline="true" :model="params" :size="theme.size" v-if="show_fillter">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="合同编号/客户名称/手机/邮箱" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="admin_user.shop_id">
					<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item v-else>
					<el-select v-model="params.shop_id" placeholder="城市站点" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employee_id" placeholder="业务" filterable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id">
							<span>{{employee.name}}</span>
							<span class="c9 fs12">{{counts["employee_"+employee.id] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.service" placeholder="套餐" filterable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="(service, s) in services" :key="s" :label="service" :value="s">
							<span>{{service}}</span>
							<span class="c9 fs12">{{counts["service_"+s] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.way" placeholder="服务" filterable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="(way, w) in waies" :key="w" :label="way" :value="w">
							<span>{{way}}</span>
							<span class="c9 fs12">{{counts["way_"+w] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.payment" placeholder="支付" filterable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="(payment, p) in payments" :key="p" :label="payment" :value="p">
							<span>{{payment}}</span>
							<span class="c9 fs12">{{counts["payment_"+p] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable>
						<el-option label="开始时间" value="start"></el-option>
						<el-option label="结束时间" value="end"></el-option>
						<el-option label="签名时间" value="signatured"></el-option>
						<el-option label="创建时间" value="created"></el-option>
						<el-option label="更新时间" value="updated"></el-option>
					</el-select>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getContracts({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table ref="contracts_table" class="scroll-wrapper pa" height="100%" :size="theme.size" :data="contracts" :row-class-name="tableRowClassName">
					<el-table-column type="selection" width="42"></el-table-column>
					<el-table-column label="流水" min-width="240">
						<template slot-scope="scope">
							<el-link type="primary" class="twn" @click="$refs['show_client'].open({id: scope.row.client_id})"><i class="el-icon-user"></i> {{scope.row.client.name}}</el-link>
							<p v-if="scope.row.company"><i class="el-icon-office-building"></i> {{scope.row.company.name}}</p>
							<p><el-link type="primary" @click="editContract(scope.row)"><i class="el-icon-document"></i> {{scope.row.no}}</el-link></p>
							<p><i class="el-icon-s-order"></i> {{services[scope.row.service]}} {{waies[scope.row.way]}} {{payments[scope.row.payment]}} </p>
							<template v-if="theme.width <= 1440">
								<p v-if="scope.row.person"><i class="el-icon-mobile-phone"></i> {{scope.row.person}} <template v-if="scope.row.mobile">, {{scope.row.mobile}}</template></p>
								<p v-if="scope.row.regions.length"><i class="el-icon-map-location"></i> {{scope.row.regions.map((r) => r.name).join('')}}</p>
								<p v-if="scope.row.address"><i class="el-icon-place"></i> {{scope.row.address}}</p>
							</template>
						</template>
					</el-table-column>
					<el-table-column label="联系" min-width="210" v-if="theme.width > 1440">
						<template slot-scope="scope">
							<p v-if="scope.row.person"><i class="el-icon-mobile-phone"></i> {{scope.row.person}} <template v-if="scope.row.mobile">, {{scope.row.mobile}}</template></p>
							<p v-if="scope.row.regions.length"><i class="el-icon-map-location"></i> {{scope.row.regions.map((r) => r.name).join('')}}</p>
							<p v-if="scope.row.address"><i class="el-icon-place"></i> {{scope.row.address}}</p>
						</template>
					</el-table-column>
					<el-table-column label="业务" min-width="140">
						<template slot-scope="scope">
							<p><i class="el-icon-user"></i> {{scope.row.employee ? scope.row.employee.name : '未知业务'}}</p>
							<p v-if="admin_user.shop_id"><i class="el-icon-office-building"></i> {{scope.row | format_department(oa_users, oa_departments)}}</p>
							<p v-else><i class="el-icon-office-building"></i> {{scope.row.shop ? scope.row.shop.name : theme.group}}</p>
							<el-tooltip effect="dark" content="已审核支付 / 签约金额">
								<span><i class="el-icon-money"></i> {{scope.row.order | fees_total}} / {{(scope.row.price/100).toFixed(2)+' 元'}}</span>
							</el-tooltip>
						</template>
					</el-table-column>
					<el-table-column label="订单" min-width="220">
						<template slot-scope="scope">
							<template v-if="scope.row.order">
								<el-link type="primary" @click="$refs['show_dialog'].open(scope.row.order)">No.{{scope.row.order.no}}</el-link>
								<p class="c9 mb5 twn" style="line-height: 1.2;" v-for="goods in scope.row.order.order_goods" :key="goods.id">{{goods.title}}</p>
							</template>
							<p v-else>暂无～</p>
							<p v-if="scope.row.comment">备注：{{scope.row.comment}}</p>
						</template>
					</el-table-column>
					<el-table-column label="有效时间" min-width="120" v-if="theme.width >= 1080">
						<template slot-scope="scope">
							<p v-if="scope.row.start_at">开始：{{scope.row.start_at | format_at}}</p>
							<p v-if="scope.row.end_at">结束：{{scope.row.end_at | format_at}}</p>
							<p v-if="scope.row.signatured_at">签名：{{scope.row.signatured_at | format_at}}</p>
							<!-- <img style="width: 160px; position: absolute; top: 0;" :src="scope.row.signatured_card" alt="" v-if="scope.row.signatured_card"> -->
						</template>
					</el-table-column>
					<el-table-column label="操作时间" min-width="180" v-if="theme.width >= 1240">
						<template slot-scope="scope">
							<p>创建：{{scope.row.created_at}}</p>
							<p>更新：{{scope.row.updated_at}}</p>
							<p v-if="scope.row.deleted_at">删除：{{scope.row.deleted_at}}</p>
						</template>
					</el-table-column>
					<el-table-column label="状态" width="80">
						<template slot-scope="scope">
							<el-tooltip effect="dark" placement="top" :content="'OA审核编号'+scope.row.approval.sp_no" v-if="scope.row.approval">
								<el-link type="primary" @click="$refs['contract_dialog'].onStatus(scope.row)">{{statuses[scope.row.status] || '未知'}}</el-link>
							</el-tooltip>
							<el-link type="primary" @click="$refs['contract_dialog'].onStatus(scope.row)" v-else>{{statuses[scope.row.status] || '未知'}}</el-link>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="60">
						<el-dropdown slot="header" @command="batchAction">
							<el-button type="text">操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="export">导出选中</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<template slot-scope="scope">
							<el-button type="text" @click="$refs['contract_dialog'].delContract(scope.row)" :disabled="!$utils.delete($api.URI_CONTRACTS) || scope.row.deleted_at">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div slot="footer" class="df aic">
			<el-pagination
				:layout="theme.is_mobile ? 'total, prev, pager, next' : $config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getContracts({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getContracts({ ...params, page: v }) }"
				background>
			</el-pagination>
			<div class="fs12 pl20 c6">当前页累计 <b class="cff0000">{{ contracts | money_total}}</b> 元</div>
		</div>
		<template slot="other">
			<show-order ref="show_dialog"></show-order>
			<show-client ref="show_client"></show-client>
			<show-fee ref="fee_dialog" @refresh="getContracts(params)"></show-fee>
			<show-contract ref="contract_dialog" @refresh="getContracts(params)" @create-fee="(fee) => { $refs['fee_dialog'].open(fee); }"></show-contract>
		</template>
	</main-table-a>
</template>

<style>
	.el-table .warning-row {
		background: oldlace;
	}
	.el-table .success-row {
		background: #f0f9eb;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';
	import mainTableA from '@/components/main-table-a';
	import showFee from '../fees/show';
	import showClient from '../clients/show';
	import showContract from '../contracts/show';
	import showOrder from '@/pages/oms/orders/show';

	export default {
		components: {
			mainTableA,
			showFee,
			showOrder,
			showClient,
			showContract
		},
		computed: {
			...mapState(['theme', 'admin_user', 'config_uploads', 'oa_users', 'oa_departments', 'models']),
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			},
			waies () {
				const { contracts } = this.models;
				return contracts ? contracts.waies : [];
			},
			services () {
				const { contracts } = this.models;
				return contracts ? contracts.services : [];
			},
			payments () {
				const { contracts } = this.models;
				return contracts ? contracts.payments : [];
			},
			statuses () {
				const { contracts } = this.models;
				return contracts ? contracts.statuses : [];
			}
		},
		filters: {
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM-DD');
			},
			format_department ({ employee }, users, departments) {
				if (!employee) return '公海';
				if (!users[employee.id]) return '未关联OA';
				const { main_department } = users[employee.id];
				if (!departments[main_department]) return '未知部门';
				return departments[main_department].name;
			},
			fees_total (order) {
				var total = 0;
				if (!order) return total;
				order.client_fees.map((f) => {
					total += f.price;
				})
				return total/100;
			},
			money_total (data) {
				let money = 0;
				data.forEach((v) => {
					money += v.price;
				});
				return (money/100).toFixed(2);
			}
		},
		methods: {
			tableRowClassName({row}) {
				var class_name;
				if (row.deleted_at == null) {
					switch (row.status) {
						case 'pending':
							class_name = 'warning-row';
							break;
						case 'signatured':
							class_name = 'success-row';
							break;
						default:
							break;
					}
				}
				return class_name;
			},
			openFile (u) {
				window.open(u);
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.no+'】合同吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_CONTRACTS, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg: message } = res.data;
					if (code != 0) return this.$message.error(message);
					this.$message.success({
						message,
						onClose: () => {
							this.getContracts(this.params);
						}
					});
				})
			},
			async batchAction (m) {
				const { selection } = this.$refs.contracts_table;
				if (!selection.length) return this.$message.error('请选择操作内容');
				switch (m) {
					case 'export':
							selection.forEach((c) => {
								c.employee_name = c.employee ? c.employee.name : '未知';
								c.client_name = c.client ? c.client.name : '未知';
								c.order_no = c.order ? c.order.no : '未知';
								c.price_unit = (c.price/100).toFixed(2);
								c.status_name = this.statuses[c.status];
								c.department_name = '未关联OA';
								if (this.oa_users[c.employee_id]) {
									const { main_department } = this.oa_users[c.employee_id];
									c.department_name = '未知部门';
									if (this.oa_departments[main_department]) {
										c.department_name = this.oa_departments[main_department].name;
									}
								}
							});
							this.$confirm(`此操作将导出${selection.length}条内容, 是否继续？`, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								var file = "签约合同导出" + this.$moment().format('YYYYMMDDHHmmss');
								var header = [['流水号', '客户', '订单号', '部门', '业务员', '金额', '状态', '开始时间', '结束时间', '更新时间', '创建时间']];
								var filter = ['no', 'client_name', 'order_no', 'department_name', 'employee_name', 'price_unit', 'status_name', 'start_at', 'end_at', 'updated_at', 'created_at'];
								this.$utils.handleDownload(header, filter, selection, file, "合同明细");
							})
						break;
				}
			},
			toggleStatus (s) {
				const { status } = this.params
				const index = status.indexOf(s);
				if (index > -1) {
					status.splice(index, 1);
					this.status_tabs[s].checked = false;
				} else {
					status.push(s);
					this.status_tabs[s].checked = true;
				}
				this.getContracts(this.params);
			},
			editContract (r) {
				// if (r.status === 2 || r.status === 4 || r.status === 5) return this.$message.error('当前状态不可操作！');
				this.$refs['contract_dialog'].open({
					...r,
					order_no: r.order ? r.order.no : '',
					price: r.price/100,
					regions: r.regions.map((r) => r.id),
					max_price: r.price,
					effective_at: [new Date(r.start_at), new Date(r.end_at)]
				});
			},
			async getContracts (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_CONTRACTS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.contracts = result.data;
				this.shops = result.shops;
				this.counts = result.counts;
				this.employees = result.employees;
				var status_tabs = {};
				const status = params.status || [];
				for (var s in this.statuses) {
					status_tabs[s] = {
						name: this.statuses[s],
						checked: status.indexOf(s) >= 0 ? true : false
					};
				}
				this.status_tabs = status_tabs;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				shops: [],
				contracts: [],
				employees: [],
				status_tabs: [],
				show_fillter: false,
				counts: {},
				params: {
					status: [],
					perPage: 10,
					keywords: '',
				}
			}
		},
		async created () {
			this.getContracts(this.params, true);
		}
	};
</script>