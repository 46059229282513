<template>
	<div>
		<h3>绑定百度小程序</h3>
		<el-form ref="create_form" label-width="100px" class="mt20" :model="create_form" :rules="create_rules" :size="theme.size" status-icon>
			<el-form-item label="应用名称" prop="name">
				<el-input placeholder="请输入应用名称" autocomplete="off" v-model="create_form.name" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="应用ID" prop="app_id">
				<el-input placeholder="请输入应用ID" autocomplete="off" v-model="create_form.app_id" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="应用Key" prop="app_key">
				<el-input placeholder="请输入应用Key" autocomplete="off" v-model="create_form.app_key" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="应用秘钥" prop="app_secret">
				<el-input placeholder="请输入应用秘钥" autocomplete="off" v-model="create_form.app_secret" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="结算凭证" prop="dealId">
				<el-input placeholder="请输入结算凭证" autocomplete="off" v-model="create_form.dealId" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="收银台" prop="appKey">
				<el-input placeholder="请输入收银台" autocomplete="off" v-model="create_form.appKey" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="应用私钥" prop="rsa_private_key">
				<el-input placeholder="请输入应用私钥" autocomplete="off" v-model="create_form.rsa_private_key" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="百度公钥" prop="baidu_rsa_public_key">
				<el-input placeholder="请输入百度公钥" autocomplete="off" v-model="create_form.baidu_rsa_public_key" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('create_form')">确 定</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<style>

</style>
<script>
	import { mapState } from 'vuex';
	
	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
		},
		data() {
			return {
				create_form: {},
				create_rules: {
					name: [{ required: true, message: '必须填写应用名称', trigger: 'blur' }],
					app_id: [{ required: true, message: '必须填写应用ID', trigger: 'blur' }],
					app_key: [{ required: true, message: '必须填写应用Key', trigger: 'blur' }],
					app_secret: [{ required: true, message: '必须填写应用秘钥', trigger: 'blur' }],
					dealId: [{ required: true, message: '必须填写结算凭证', trigger: 'blur' }],
					appKey: [{ required: true, message: '必须填写收银台', trigger: 'blur' }],
					rsa_private_key: [{ required: true, message: '必须填写应用私钥', trigger: 'blur' }],
					baidu_rsa_public_key: [{ required: true, message: '必须填写百度公钥', trigger: 'blur' }]
				}
			}
		},
		created () {
		}
	};
</script>