<template>
	<el-dialog width="320px" :title="title" :visible.sync="show_dialog" :fullscreen="theme.width < 680" :close-on-click-modal="false" append-to-body destroy-on-close>
		<el-form label-width="60px" ref="dictionary_form" :model="dictionary" :rules="rules" :size="theme.size">
			<el-form-item label="键名" prop="name">
				<el-input v-model="dictionary.name" placeholder="请输入键名" :disabled="dictionary.id" clearable>
					<el-tooltip v-if="!dictionary.name" slot="suffix" content="请使用英文拼音小写字母，为空时自动创建">
						<i class="el-icon-question"></i>
					</el-tooltip>
				</el-input>
			</el-form-item>
			<el-form-item label="键值" prop="value">
				<el-input v-model="dictionary.value" placeholder="请输入键值" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('dictionary_form')">{{dictionary.id ? '提交保存' : '提交创建'}}</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme', 'models']),
			model_dict () {
				if (!this.models) return {};
				return this.models.model_dictionaries || {};
			},
			dict_models () {
				return this.model_dict.models || {};
			},
			dict_fields () {
				return this.model_dict.fields || {};
			},
			title () {
				const { model, field } = this.dictionary;
				if (!this.dict_models[model]) return '未知模型';
				if (!this.dict_fields[model][field]) return '未知字段';
				return this.dict_models[model]+this.dict_fields[model][field];
			}
		},
		filters: {

		},
		methods: {
			async open (r) {
				this.dictionary = { ...r };
				this.show_dialog = true;
			},
			async submitDictionaries (data) {
				const res = await this.$http.post(this.$api.URI_MODELS_DICTIONARIES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.$emit('refresh');
						this.show_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'dictionary_form':
							// console.log(this.dictionary)
							this.submitDictionaries(this.dictionary);
						break;
					}
				});
			}
		},
		data() {
			const checkName = (rule, value, callback) => {
				if (value) {
					// if (!/^[a-z0-9_]+$/.test(value)) return callback(new Error('小写字母数字或下划线'));
					if (!/^[a-z]/.test(value)) return callback(new Error('键名必须使用小写字母开头'));
					if (!/^[a-z]*([a-z]|[0-9]*)$/.test(value)) return callback(new Error('键名必须使用小写字母数字结尾'));
					// if (!/^[a-z]*[_]?([a-z]|[0-9]*)$/.test(value)) return callback(new Error('请使用小写字母数字结尾'));
				}
				return callback();
			}

			return {
				dictionary: {},
				show_dialog: false,
				rules: {
					name: [{ validator: checkName, trigger: ['blur', 'change'] }],
					value: [{ required: true, message: '必须填写键值', trigger: 'blur'}]
				}
			}
		}
	};
</script>