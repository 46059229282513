<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size" @submit.native.prevent v-if="!theme.is_mobile">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="商品/附件/编码/货架" @keyup.enter.native.prevent="getGoods(params)" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getGoods(params)">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<!-- 产品表格 -->
				<el-table ref="goods_table" class="scroll-wrapper absolute" height="100%" :data="goods" @sort-change="sortChange" :size="theme.size">
					<el-table-column label="商品" min-width="140">
						<template slot-scope="scope">
							<h5>{{scope.row.goods.title}}</h5>
							<p>{{scope.row.name}}</p>
						</template>
					</el-table-column>
					<el-table-column prop="number" label="编码">
						<el-input slot-scope="scope" v-model="scope.row.number" placeholder="请输入编码" size="mini" @change="submitGoodsAttr(scope.row)" :disabled="!$utils.update('goods/attributes')">
							<el-button slot="append" icon="el-icon-refresh" :disabled="!$utils.update('goods/attributes')" @click="refreshStock(scope.row.number)"></el-button>
						</el-input>
					</el-table-column>
					<el-table-column prop="shop_price" label="金额" sortable="custom">
						<el-input slot-scope="scope" v-model="scope.row.shop_price" placeholder="请输入金额" size="mini" @change="submitGoodsAttr(scope.row)" :disabled="!$utils.update('goods/attributes')">
							<template slot="append">分</template>
						</el-input>
					</el-table-column>
					<el-table-column prop="weight" label="重量" sortable="custom">
						<el-input slot-scope="scope" v-model="scope.row.weight" placeholder="请输入重量" size="mini" @change="submitGoodsAttr(scope.row)" :disabled="!$utils.update('goods/attributes')">
							<template slot="append">克</template>
						</el-input>
					</el-table-column>
					<el-table-column prop="stock" label="库存" sortable="custom" width="100">
						<el-input slot-scope="scope" v-model="scope.row.stock" placeholder="请输入库存" size="mini" @change="submitGoodsAttr(scope.row)" :disabled="!$utils.update('goods/attributes')"></el-input>
					</el-table-column>
					<el-table-column prop="rack" label="货架" sortable="custom" width="120">
						<el-input slot-scope="scope" v-model="scope.row.rack" placeholder="请输入货架" size="mini" @change="submitGoodsAttr(scope.row)" :disabled="!$utils.update('goods/attributes')"></el-input>
					</el-table-column>
					<el-table-column prop="at" label="操作时间" width="210">
						<template slot-scope="scope">更新：{{scope.row.updated_at}}<br>创建：{{scope.row.created_at}}</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<!-- 列表分页 -->
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getGoods({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getGoods({ ...params, page: v }) }"
			background>
		</el-pagination>
	</main-table-a>
</template>

<style>
	.dis_thumb {
		width: 60px;
		height: 60px;
		font-size: 30px;
		line-height: 60px;
		text-align: center;
		background: #f1f1f1;
	}
	.el-drawer__body {
		overflow: auto;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				var p = this.params;
					p.order = o;
				this.getGoods(p);
			},
			refreshStock (n) {
				if (!n) return this.$message.error("同步库存失败！编码不能为空");
				this.$message.error(n+"同步库存失败！系统尚未接入ERP");
			},
			async submitGoodsAttr (d) {
				const res = await this.$http.post(this.$api.URI_GOODS_ATTRIBUTES, d);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
				this.getGoods(this.params);
			},
			async getGoods (p, l = false) {
				const res = await this.$http.get(this.$api.URI_GOODS_ATTRIBUTES, {params: p, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				const { data, total, per_page, current_page } = result;
				this.goods = data;
				this.params = {
					...p,
					total: total,
					perPage: parseInt(per_page),
					page: parseInt(current_page)
				}
			}
		},
		data() {
			return {
				goods: [],
				params: {
					page: 1,
					perPage: 10,
				}
			}
		},
		async created () {
			this.getGoods(this.params, true);
		}
	};
</script>