<template>
	<div class="df fdc" style="height: 100%;">
		<el-alert title="属性说明" type="warning">
			<template solt="description">
				<template v-if="!goods.sold_at">
					<p>笛卡尔积一般指笛卡尔乘积。笛卡尔乘积是指在数学中，两个集合X和Y的笛卡尔积，又称直积。表示为X × Y，第一个对象是X的成员而第二个对象是Y的所有可能有序对的其中一个成员</p>
					<p>本页面不支持创建笛卡尔积。若需要请先前往【<b class="c409eff">商品类型</b>】创建。商品类型属性名只能在【<b class="c409eff">商品类型</b>】删除</p>
					<p class="c9">当属性名下关联属性值无数据时，则商品详细不展示当前属性名</p>
				</template>
				<p v-else>当产品上架时不能修改属性，请下架后再操作</p>
			</template>
		</el-alert>
		<div class="oa" style="height: 100%;">
			<el-table row-key="id" :header-cell-style="{backgroundColor: '#e1d6ba', color: '#666'}" :data="goods.type_attributes" :size="theme.size" :expand-row-keys="expand_row_keys" @expand-change="expandChange">
				<el-table-column type="expand">
					<el-table slot-scope="props" :size="theme.size" :data="props.row.goods_attributes" :header-cell-style="{backgroundColor: '#e1d6ba', color: '#666'}">
						<el-table-column min-width="1"></el-table-column>
						<el-table-column label="属性值" min-width="120">
							<el-link slot-scope="scope" type="primary" @click="addGoodsAttr({...scope.row, cross: props.row.cross})" :disabled="!$utils.update($api.URI_GOODS_ATTRIBUTES) || goods.sold_at">{{scope.row.name}}</el-link>
						</el-table-column>
						<template v-if="!props.row.cross">
							<el-table-column label="编码" prop="number" min-width="100"></el-table-column>
							<el-table-column label="价格" prop="shop_price" min-width="80" :formatter="(r) => { return (r.shop_price/100).toFixed(2); }"></el-table-column>
							<el-table-column label="重量(克)" prop="weight" min-width="80"></el-table-column>
							<el-table-column label="库存" prop="stock" min-width="80"></el-table-column>
							<el-table-column label="货架" prop="rack" min-width="80"></el-table-column>
						</template>
						<el-table-column label="其它属性" min-width="180" v-else>已创建笛卡尔积，请在最低部填写产品信息</el-table-column>
						<!-- 上架时不能修改属性 -->
						<el-table-column min-width="50" v-if="!goods.sold_at">
							<el-link slot="header" type="primary" @click="addGoodsAttr({goods_id: goods.id, type_attribute_id: props.row.id, cross: props.row.cross})" :disabled="!$utils.create($api.URI_GOODS_ATTRIBUTES)">新增</el-link>
							<template slot-scope="scope">
								<el-popconfirm title="确定要恢复这条记录吗？" @confirm="handleGoodsAttr('restore', scope.row)" v-if="scope.row.deleted_at">
									<el-link slot="reference" type="primary" :disabled="!$utils.restore($api.URI_GOODS_ATTRIBUTES)">恢复</el-link>
								</el-popconfirm>
								<el-popconfirm title="确定要删除这条记录吗？" @confirm="handleGoodsAttr('delete', scope.row)" v-else>
									<el-link slot="reference" type="primary" :disabled="!$utils.delete($api.URI_GOODS_ATTRIBUTES)">删除</el-link>
								</el-popconfirm>
							</template>
						</el-table-column>
					</el-table>
				</el-table-column>
				<el-table-column label="属性名" min-width="100">
					<template slot-scope="scope">
						<span v-if="scope.row.deleted_at">{{scope.row.name}}</span>
						<el-link type="primary" @click="addTypeAttr(scope.row)" :disabled="!$utils.update($api.URI_TYPE_ATTRIBUTES) || goods.sold_at" v-else>{{scope.row.name}}</el-link>
					</template>
				</el-table-column>
				<el-table-column prop="cross" label="笛卡尔积" min-width="70" :formatter="(r) => { return r.cross ? '是' : '否'; }"></el-table-column>
				<el-table-column prop="required" label="是否必选" min-width="70" :formatter="(r) => { return r.required ? '是' : '否'; }"></el-table-column>
				<el-table-column prop="multiple" label="是否多选" min-width="70" :formatter="(r) => { return r.multiple ? '是' : '否'; }"></el-table-column>
				<el-table-column prop="minimum" label="最小选择" min-width="70"></el-table-column>
				<el-table-column prop="maximum" label="最大选择" min-width="70"></el-table-column>
				<el-table-column prop="sort" label="排序" min-width="50"></el-table-column>
				<el-table-column label="关联值" min-width="60" :formatter="(r) => { return r.goods_attributes.length; }"></el-table-column>
				<!-- 上架时不能修改属性 -->
				<el-table-column min-width="50" v-if="!goods.sold_at">
					<el-link slot="header" type="primary" @click="addTypeAttr({common_model_type: 'App\\Models\\Goods', common_model_id: goods.id})" :disabled="!$utils.create($api.URI_TYPE_ATTRIBUTES)">新增</el-link>
					<template slot-scope="scope">
						<el-popconfirm title="确定要恢复这条记录吗？" @confirm="handleTypeAttr('restore', scope.row)" v-if="scope.row.deleted_at">
							<el-link slot="reference" type="primary" :disabled="!$utils.restore($api.URI_TYPE_ATTRIBUTES)">恢复</el-link>
						</el-popconfirm>
						<el-popconfirm title="确定要删除这条记录吗？" @confirm="handleTypeAttr('delete', scope.row)" v-else>
							<el-link slot="reference" type="primary" :disabled="!$utils.delete($api.URI_TYPE_ATTRIBUTES)">删除</el-link>
						</el-popconfirm>
					</template>
				</el-table-column>
			</el-table>
			<el-table :header-cell-style="{backgroundColor: '#e1d6ba', color: '#666'}" :data="goods.goods_attributes" :size="theme.size" v-if="goods.goods_attributes && goods.goods_attributes.length">
				<el-table-column min-width="1"></el-table-column>
				<el-table-column min-width="180" :label="goods.type_attributes.filter((t) => t.cross ).map((attr) => { return attr.name; }).join('_')">
					<el-link slot-scope="scope" type="primary" @click="addGoodsAttr(scope.row)" :disabled="!$utils.update($api.URI_GOODS_ATTRIBUTES) || goods.sold_at">{{scope.row.name}}</el-link>
				</el-table-column>
				<el-table-column prop="number" label="编码" min-width="90"></el-table-column>
				<el-table-column prop="shop_price" label="价格" min-width="90" :formatter="(r) => { return (r.shop_price/100).toFixed(2); }"></el-table-column>
				<el-table-column prop="weight" label="重量(克)" min-width="90"></el-table-column>
				<el-table-column prop="stock" label="库存" min-width="90"></el-table-column>
				<el-table-column prop="rack" label="货架" min-width="90"></el-table-column>
			</el-table>
		</div>
		<el-dialog title="属性名" width="380px" :visible.sync="attribute_name_dialog" :fullscreen="theme.width <= 380" append-to-body destroy-on-close>
			<el-form label-width="100px" ref="attribute_name" :model="attribute_name" :size="theme.size" status-icon>
				<el-form-item label="属性名" prop="name" :rules="[{ required: true, message: '必须填写属性名', trigger: 'blur'}]">
					<el-input v-model="attribute_name.name" placeholder="请输入属性名"></el-input>
				</el-form-item>
				<!-- 本页面必须禁用笛卡尔积，只有在类型属性才能使用 -->
				<el-form-item label="笛卡尔积" prop="cross">
					<el-switch :active-value="1" :inactive-value="0" active-text="是" inactive-text="否" v-model="attribute_name.cross" :disabled="true"></el-switch>
				</el-form-item>
				<template v-if="!attribute_name.cross">
					<el-form-item label="是否必选" prop="required">
						<el-switch :active-value="1" :inactive-value="0" active-text="是" inactive-text="否" v-model="attribute_name.required"></el-switch>
					</el-form-item>
					<el-form-item label="是否多选" prop="multiple">
						<el-switch :active-value="1" :inactive-value="0" active-text="是" inactive-text="否" v-model="attribute_name.multiple"></el-switch>
					</el-form-item>
					<template v-if="attribute_name.multiple">
						<el-form-item label="最小选择" prop="minimum">
							<el-input-number v-model.number="attribute_name.minimum" placeholder="最小选择" :min="attribute_name.required ? 2 : 0" :max="attribute_name.maximum"></el-input-number>
						</el-form-item>
						<el-form-item label="最大选择" prop="maximum">
							<el-input-number v-model.number="attribute_name.maximum" placeholder="最大选择" :min="attribute_name.minimum"></el-input-number>
						</el-form-item>
					</template>
					<el-form-item label="排序" prop="sort">
						<el-input-number v-model.number="attribute_name.sort" placeholder="排序" :min="0"></el-input-number>
					</el-form-item>
				</template>
				<el-form-item>
					<el-button type="primary" @click="submitForm('attribute_name')">确 定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="属性值" width="400px" :visible.sync="attribute_value_dialog" :fullscreen="theme.width <= 380" append-to-body destroy-on-close>
			<el-form label-width="80px" ref="attribute_value" :model="attribute_value" :size="theme.size" status-icon>
				<el-form-item label="属性值" prop="name" :rules="[{ required: true, message: '必须填写属性值', trigger: 'blur'}]">
					<el-input v-model="attribute_value.name" placeholder="请输入属性值" :disabled="!attribute_value.type_attribute_id"></el-input>
				</el-form-item>
				<template v-if="!attribute_value.cross">
					<el-form-item label="编码" prop="number" :rules="[{ required: true, message: '必须填写编码', trigger: 'blur'}]">
						<el-input v-model="attribute_value.number" placeholder="请输入编码"></el-input>
					</el-form-item>
					<el-form-item label="价格" prop="shop_price" :rules="[{ required: true, type: 'number', message: '必须填写价格', trigger: 'blur'}]">
						<el-input-number v-model.number="attribute_value.shop_price" placeholder="请输入价格" :min="0" :max="99999999" :precision="2" :step="0.01"></el-input-number>
					</el-form-item>
					<el-form-item label="重量" prop="weight" :rules="[{ required: true, type: 'number', message: '必须填写重量', trigger: 'blur'}]">
						<el-input-number v-model.number="attribute_value.weight" placeholder="请输入重量" :min="0" :max="99999999"></el-input-number>
						<span class="fs12"> 实物必填，0为虚拟产品</span>
					</el-form-item>
					<el-form-item label="库存" prop="stock" :rules="[{ required: true, type: 'number', message: '必须填写库存', trigger: 'blur'}]">
						<el-input-number v-model.number="attribute_value.stock" placeholder="请输入库存" :min="0" :max="99999999"></el-input-number>
					</el-form-item>
					<el-form-item label="货架" prop="rack">
						<el-input v-model="attribute_value.rack" placeholder="请输入货架。为空建议使用编码配合仓储管理" clearable></el-input>
					</el-form-item>
				</template>
				<el-form-item>
					<el-button type="primary" @click="submitForm('attribute_value')">确 定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		props: {
			goods: {
				type: Object
			}
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			expandChange (e) {
				const keys = this.expand_row_keys;
				const index = keys.indexOf(e.id);
				index >= 0 ? keys.splice(index, 1) : keys.push(e.id);
			},
			addTypeAttr (r) {
				this.attribute_name = { ...r };
				this.attribute_name_dialog = true;
			},
			addGoodsAttr (r) {
				this.attribute_value = { ...r, shop_price: r.shop_price/100 };
				this.attribute_value_dialog = true;
			},
			async handleTypeAttr (a, r) {
				if (r.common_model_type == 'App\\Models\\GoodsType') return this.$message.error('请前往商品类型操作');
				const res = await this.$http.get(this.$api.URI_TYPE_ATTRIBUTES, {params: {action: a, id: r.id}, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$emit('refresh');
			},
			async handleGoodsAttr (a, r) {
				const res = await this.$http.get(this.$api.URI_GOODS_ATTRIBUTES, {params: {action: a, id: r.id}, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
				this.$emit('refresh');
			},
			async submitTypeAttributes (d) {
				if (!d.name) return this.$message.error('必须填写属性名');
				const res = await this.$http.post(this.$api.URI_TYPE_ATTRIBUTES, d);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message({
					type: 'success',
					duration: 1000,
					message,
					onClose: () => {
						this.$emit('refresh');
						this.attribute_name_dialog = false;
					}
				});
			},
			async submitGoodsAttributes (d) {
				if (!d.name) return this.$message.error('必须填写属性值');
				const res = await this.$http.post(this.$api.URI_GOODS_ATTRIBUTES, d);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message({
					type: 'success',
					duration: 1000,
					message,
					onClose: () => {
						this.$emit('refresh');
						this.attribute_value_dialog = false;
					}
				});
			},
			submitForm (r) {
				this.$refs[r].validate(async (valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (r) {
						case 'attribute_name':
							this.submitTypeAttributes(this.attribute_name);
						break;
						case 'attribute_value':
							this.submitGoodsAttributes(this.attribute_value);
						break;
					}
				});
			}
		},
		data() {
			return {
				attribute_name: {},
				attribute_value: {},
				expand_row_keys: [],
				attribute_name_dialog: false,
				attribute_value_dialog: false
			}
		}
	};
</script>