<template>
	<div>
		<h3>绑定抖音小程序</h3>
		<el-form ref="create_form" label-width="100px" class="mt20" :model="create_form" :rules="create_rules" :size="theme.size" status-icon>
			<el-form-item label="应用名称" prop="name">
				<el-input placeholder="请输入应用名称" autocomplete="off" v-model="create_form.name" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="应用ID" prop="app_id">
				<el-input placeholder="请输入应用ID" autocomplete="off" v-model="create_form.app_id" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="应用秘钥" prop="app_secret">
				<el-input placeholder="请输入应用秘钥" autocomplete="off" v-model="create_form.app_secret" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="应用Token" prop="token">
				<el-input placeholder="请输入应用Token" autocomplete="off" v-model="create_form.token" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="应用Salt" prop="salt">
				<el-input placeholder="请输入应用Salt" autocomplete="off" v-model="create_form.salt" maxlength="18" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('create_form')">确 定</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<style>

</style>
<script>
	import { mapState } from 'vuex';
	
	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
		},
		data() {
			return {
				create_form: {},
				create_rules: {
					name: [{ required: true, message: '必须填写应用名称', trigger: 'blur' }],
					app_id: [{ required: true, message: '必须填写应用ID', trigger: 'blur' }],
					app_secret: [{ required: true, message: '必须填写应用秘钥', trigger: 'blur' }],
					token: [{ required: true, message: '必须填写应用Token', trigger: 'blur' }],
					salt: [{ required: true, message: '必须填写应用Salt', trigger: 'blur' }]
				}
			}
		},
		created () {
		}
	};
</script>