<template>
	<main-table-a>
		<template v-if="show_table">
			<div slot="table" class="f1 df fdc">
				<el-form :inline="true" :model="params" :size="theme.size">
					<el-form-item >
						<el-input style="width: 140px;" v-model="params.keyword" placeholder="备注说明" clearable></el-input>
					</el-form-item>
					<el-form-item style="width: 180px;" v-if="!admin_user.shop_id">
						<el-select v-model="params.shop_id" placeholder="城市站点" filterable clearable>
							<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select style="width: 180px;" v-model="params.employees" placeholder="请选择员工" filterable  multiple collapse-tags>
							<el-option class="df aic jcsb" v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id">
								<span>{{employee.name}}</span>
								<span class="c9 fs12">{{counts["employee_"+employee.id] || 0}}</span>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item >
						<el-select style="width: 220px;" v-model="params.insurance_temp_id" placeholder="投保产品" filterable  multiple collapse-tags>
							<el-option class="df aic jcsb" v-for="temp in temps" :key="temp.id" :label="temp.name" :value="temp.id">
								<span>{{temp.name}}</span>
								<span class="c9 fs12">{{counts["temp_"+temp.id] || 0}}</span>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select style="width: 120px;" slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable clearable>
							<el-option label="失效时间" value="expired"></el-option>
							<el-option label="创建时间" value="created"></el-option>
							<el-option label="更新时间" value="updated"></el-option>
						</el-select>
						<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="getInsurances({ ...params, page: 1})" plain>查询</el-button>
						<el-button type="primary" @click="$refs['products'].open()">投保产品</el-button>
					</el-form-item>
				</el-form>
				<div class="f1 pr">
					<el-table ref="insurance_table" class="bill scroll-wrapper absolute" max-height="100%" :data="insurances" :size="theme.size" @sort-change="sortChange" show-summary>
						<el-table-column prop="employee.name" label="被保人" min-width="90"></el-table-column>
						<el-table-column prop="name" label="名称" min-width="160">
							<el-link slot-scope="scope" type="primary" :disabled="!$utils.update($api.URI_INSURANCES)" @click="$refs['insurances'].open(scope.row)">{{scope.row.name}}</el-link>
						</el-table-column>
						<el-table-column label="缴费" min-width="50" :formatter="({once}) => { return once ? '当月' : '每月'; }"></el-table-column>
						<el-table-column label="基数" prop="base" min-width="100" sortable="custom" :formatter="({base}) => { return (base*1) + ' 元'}"></el-table-column>
						<el-table-column label="公司缴纳" align="center">
							<el-table-column label="比例" min-width="80" :formatter="({ent}) => { return (ent*1) + ' %'}"></el-table-column>
							<el-table-column label="金额" prop="ent_amount" min-width="90" :formatter="({ent_amount}) => { return (ent_amount*1) + ' 元'}"></el-table-column>
						</el-table-column>
						<el-table-column label="个人缴纳" align="center">
							<el-table-column label="比例" min-width="80" :formatter="({ind}) => { return (ind*1) + ' %'}"></el-table-column>
							<el-table-column label="金额" prop="ind_amount" min-width="90" :formatter="({ind_amount}) => { return (ind_amount*1) + ' 元'}"></el-table-column>
						</el-table-column>
						<el-table-column label="小计" prop="sub_amount" min-width="80" :formatter="({sub_amount}) => { return (sub_amount*1) + ' 元'}"></el-table-column>
						<el-table-column label="备注说明" prop="remarks" min-width="100" show-overflow-tooltip></el-table-column>
						<el-table-column label="操作时间" align="center" min-width="200">
							<template v-if="theme.width > 1440">
								<el-table-column label="失效" prop="expired_at" min-width="150" :formatter="({expired_at}) => { return expired_at || '长期'; }"></el-table-column>
								<el-table-column label="更新" prop="updated_at" min-width="150"></el-table-column>
								<el-table-column label="创建" prop="created_at" min-width="150"></el-table-column>
							</template>
							<template slot-scope="scope">
								<p v-if="scope.row.expired_at">失效于 {{scope.row.expired_at}}</p>
								<p v-if="scope.row.updated_at">更新于 {{scope.row.updated_at}}</p>
								<p v-if="scope.row.created_at">创建于 {{scope.row.created_at}}</p>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="60">
							<template slot-scope="scope">
								<el-button type="text" :disabled="!$utils.delete($api.URI_INSURANCES)" @click="delInsurance(scope.row)" v-if="!scope.row.deleted_at">删除</el-button>
								<el-button type="text" :disabled="!$utils.restore($api.URI_INSURANCES)" @click="resInsurance(scope.row)" v-else>恢复</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<el-pagination
				slot="footer"
				:layout="$config.PAGE_LAYOUT"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getInsurances({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getInsurances({ ...params, page: v }) }"
				background>	
			</el-pagination>
		</template>
		<template v-else>
			<el-result slot="table" icon="warning" title="警告提示" subTitle="暂不支持为员工投保，请前往薪酬列表初始化薪酬配置字段"></el-result>
		</template>
		<template slot="other">
			<create-insurances ref="insurances" :temps="temps" @refresh="getInsurances(params)"></create-insurances>
			<products ref="products"></products>
		</template>
	</main-table-a>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';
	import createInsurances from '@/pages/hrm/insurances/create';
	import products from './products';

	export default {
		components: {
			createInsurances,
			mainTableA,
			products
		},
		computed: {
			...mapState(['theme', 'admin_user']),
			insurances_cfg () {
				return this.$utils.uploadConfig('insurances');
			}
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getInsurances(this.params);
			},
			delInsurance ({ id, employee, name }) {
				this.$confirm(employee.name + ' 的 ' + name, '确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleInsurances({action: 'delete', id});
				});
			},
			resInsurance ({ id, employee, name }) {
				this.$confirm(employee.name + ' 的 ' + name, '确定要恢复吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleInsurances({action: 'restore', id});
				});
			},
			async getInsurances (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_INSURANCES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.insurances = result.data;
				this.temps = result.temps;
				this.employees = result.employees;
				this.show_table = result.show_table;
				this.counts = result.counts;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async handleInsurances (params) {
				const res = await this.$http.get(this.$api.URI_INSURANCES, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getInsurances(this.params);
				this.$message.success(msg);
			}
		},
		data() {
			return {
				counts: {},
				insurance: {},
				insurances: [],
				employees: [],
				temps: [],
				show_table: true,
				params: {
					perPage: 10
				}
			}
		},
		created () {
			this.getInsurances(this.params, true);
		}
	};
</script>