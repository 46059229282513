<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input placeholder="名称 OR IP OR 地址" v-model="params.keyword" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="门店" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.method" placeholder="方式" filterable clearable>
						<el-option label="GET" value="get"></el-option>
						<el-option label="PUT" value="put"></el-option>
						<el-option label="POST" value="post"></el-option>
						<el-option label="DELETE" value="delete"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getAdminLogs({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table class="scroll-wrapper absolute" height="100%" :data="logs" :size="theme.size">
					<el-table-column label="操作人" min-width="120">
						<template slot-scope="scope">{{scope.row.user.name}}<template v-if="!admin_user.shop_id">@{{scope.row.shop ? scope.row.shop.name : theme.group}}</template></template>
					</el-table-column>
					<el-table-column prop="permission.name" label="权限" min-width="120" :formatter="(r) => { return r.permission ? r.permission.name : '未知' }"></el-table-column>
					<el-table-column prop="ip" label="IP" min-width="140"></el-table-column>
					<el-table-column prop="url" label="地址" min-width="280"></el-table-column>
					<el-table-column prop="method" label="方式" width="80"></el-table-column>
					<el-table-column prop="request_time" label="时长" width="80" :formatter="(r) => { return r.request_time+'秒' }">
						<template slot="header">
							时长
							<el-tooltip effect="dark" content="仅仅是后端数据处理时长，实际前端响应大于此时长(包含网络连接响应时长)">
								<i class="el-icon-question"></i>
							</el-tooltip>
						</template>
					</el-table-column>
					<el-table-column prop="updated_at" label="更新时间" width="220" v-if="theme.width >= 1080"></el-table-column>
					<el-table-column prop="created_at" label="创建时间" width="220" v-if="theme.width >= 1080"></el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getAdminLogs({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getAdminLogs({ ...params, page: v }) }"
			background>
		</el-pagination>
	</main-table-a>
</template>


<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			async getAdminLogs (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_ADMIN_LOGS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.logs = result.data;
				this.shops = result.shops;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				logs: [],
				shops: [],
				params: {
					perPage: 10,
					keywords: '',
				},
			}
		},
		async created () {
			this.getAdminLogs(this.params, true)
		}
	};
</script>