<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input placeholder="流水号/物流单号/收件信息" v-model="params.keyword" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="所属门店" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="所有交付" v-model="params.type" filterable clearable>
						<el-option label="卖家交付" value="seller"></el-option>
						<el-option label="买家交付" value="buyer"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-cascader style="width: 100%;" placeholder="请选择所属地区" v-model="params.regions" :options="regions" :props="{expandTrigger: 'hover', value: 'id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-select placeholder="请选择物流平台" v-model="params.company" filterable clearable>
						<el-option v-for="(company, c) in companies" :key="c" :label="company" :value="c"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getDeliveries({ ...params, page: 1})">查询</el-button>
					<el-button type="primary" @click="getDeliveries({ perPage: 10 })">重置</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table class="scroll-wrapper absolute" height="100%" :data="deliveries" :size="theme.size">
					<el-table-column label="流水号" width="260">
						<template slot-scope="scope">
							<p>{{scope.row.type_name}}No.{{scope.row.no}}</p>
							<p v-if="scope.row.order">{{scope.row.order.shop ? scope.row.order.shop.name : '订单编号'}}No.{{scope.row.order.no}}</p>
						</template>
					</el-table-column>
					<el-table-column label="收件信息">
						<template slot-scope="scope">
							<p>{{scope.row.mobile}}，{{scope.row.name}} @{{scope.row.user.name}}</p>
							<p><sapn v-for="region in scope.row.regions" :key="region.id">{{region.name}}</sapn>{{scope.row.address}}</p>
						</template>
					</el-table-column>
					<el-table-column label="单号件数" width="160">
						<template slot-scope="scope">
							<p>{{scope.row.kuaidi_num}}</p>
							<p>{{scope.row.quantity}} PCS</p>
						</template>
					</el-table-column>
					<el-table-column label="物流跟中">
						<template slot-scope="scope">
							<p>{{scope.row.company_name}}#{{scope.row.status_name}}</p>
							<template v-if="scope.row.last_express">
								<p>{{scope.row.last_express['context']}}</p>
								<p>{{scope.row.last_express['time']}}</p>
							</template>
							<p v-else>暂无物流信息</p>
						</template>
					</el-table-column>
					<el-table-column label="操作时间" width="220">
						<template slot-scope="scope">
							<p>更新：{{scope.row.updated_at}}</p>
							<p>创建：{{scope.row.created_at}}</p>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="更多操作" width="120">
						<template slot-scope="scope">
							<div>
								<el-dropdown class="mr15" @command="onKuaidi">
									<span class="el-dropdown-link">快递管家<i class="el-icon-arrow-down el-icon--right"></i></span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item :command="({t: 'send', r: scope.row})" :disabled="!$utils.update('deliveries')">订单导入</el-dropdown-item>
										<el-dropdown-item :command="({t: 'auto_print', r: scope.row})" :disabled="!$utils.update('deliveries')">订单打印</el-dropdown-item>
										<el-dropdown-item :command="({t: 'send_print', r: scope.row})" :disabled="!$utils.update('deliveries')">导入打印</el-dropdown-item>
										<el-dropdown-item :command="({t: 'update_send', r: scope.row})" :disabled="!$utils.update('deliveries')">订单修改</el-dropdown-item>
										<el-dropdown-item :command="({t: 'cancel', r: scope.row})" :disabled="!$utils.update('deliveries')">订单取消</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>  
							<div>
								<el-dropdown @command="onKuaidi">
									<span class="el-dropdown-link">操作<i class="el-icon-arrow-down el-icon--right"></i></span>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item :command="({t: 'on_edit', r: scope.row})" :disabled="!$utils.update('deliveries')">修改</el-dropdown-item>
										<el-dropdown-item :command="({t: 'goods_drawer', r: scope.row})">清单</el-dropdown-item>
										<el-dropdown-item :command="({t: 'express_drawer', r: scope.row})">物流</el-dropdown-item>
										<el-dropdown-item :command="({t: 'on_delete', r: scope.row})" :disabled="!$utils.delete('deliveries')">删除</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getDeliveries({ ...params, page: 1, perPage: v}) }"
			@current-change="(v) => { getDeliveries({ ...params, page: v}) }"
			background>
		</el-pagination>
		<template slot="other">
			<!-- 收货信息 -->
			<el-dialog title="收件信息" width="480px" :visible.sync="delivery.id > 0" @close="delivery = {}" :fullscreen="theme.width < 520">
				<el-form ref="edit_form" label-width="80px" :model="delivery" :size="theme.size">
					<el-form-item prop="name" label="收货人" :rules="[{ required: true, message: '必须填写收货人'}]">
						<el-input v-model="delivery.name" placeholder="请输入修改后的收货人"></el-input>
					</el-form-item>
					<el-form-item prop="mobile" label="手机号码" :rules="[{ required: true, message: '必须填写手机号码'}, { pattern: /^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8}$/, message: '请填写正确手机号码'}]">
						<el-input v-model="delivery.mobile" placeholder="请输入修改后的手机号码"></el-input>
					</el-form-item>
					<el-form-item prop="regions" label="收货地区" :rules="[{ required: true, message: '必须选择收货地区'}]">
						<el-cascader style="width: 100%;" placeholder="请选择订单收货地区。支持输入搜索" v-model="delivery.regions" :options="tree_regions" :props="{expandTrigger: 'hover', value: 'id', label: 'name'}" filterable></el-cascader>
					</el-form-item>
					<el-form-item prop="address" label="详细地址" :rules="[{ required: true, message: '必须填写详细地址'}]">
						<el-input v-model="delivery.address" placeholder="请输入修改后的收货人"></el-input>
					</el-form-item>
					<el-form-item prop="company_code" label="物流平台" :rules="[{ required: true, message: '必须选择物流平台'}]">
						<el-input placeholder="请输入物流单号" v-model="delivery.kuaidi_num">
							<el-select slot="prepend" style="width: 100px;" v-model="delivery.company_code" placeholder="请选择物流平台" filterable>
								<el-option v-for="(company, c) in companies" :key="c" :label="company" :value="c"></el-option>         
							</el-select>
						</el-input>
					</el-form-item>
					<el-form-item prop="quantity" label="包裹数量" :rules="[{ required: true, message: '必须填写详细地址'}]">
						<el-input-number v-model="delivery.quantity" :min="1" :max="99"></el-input-number>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('edit_form')" :disabled="!$utils.update('deliveries')">提交保存</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<!-- 物料清单 -->
			<el-drawer :size="theme.width < 520 ? '100%' : '450px'" :title="goods_drawer.no+'交付订单物料清单'" :visible.sync="goods_drawer.id>0" @close="goods_drawer = {}">
				<div class="pl20 pr20">
					<el-table :size="theme.size" :data="goods_drawer.delivery_materials">
						<el-table-column label="名称" prop="order_material.name"></el-table-column>
						<el-table-column label="编码" prop="order_material.in_code"></el-table-column>
						<el-table-column label="数量" prop="quantity"></el-table-column>
					</el-table>
				</div>
			</el-drawer>
			<!-- 物流信息 -->
			<el-drawer :size="theme.width < 520 ? '100%' : '450px'" :title="express_drawer.no+'交付订单物流信息'" :visible.sync="express_drawer.id>0" @close="express_drawer = {}">
				<div class="p15">
					<el-timeline v-if="express_drawer.express_info">
						<el-timeline-item v-for="(info, index) in express_drawer.express_info" :key="index" :timestamp="info.time">
							{{info.context}}
						</el-timeline-item>
					</el-timeline>
					<div v-else>暂无物流信息，请稍后查看！</div>
				</div>
			</el-drawer>
		</template>
	</main-table-a>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		computed: {
			...mapState(['theme', 'admin_user', 'regions']),
			tree_regions () {
				return this.$utils.buildTree(this.regions);
			}
		},
		methods: {
			onKuaidi (e) {
				switch (e.t) {
					case 'on_edit':
						this.delivery = {
							id: e.r.id,
							name: e.r.name,
							mobile: e.r.mobile,
							regions: e.r.regions.map(i => { return i.id }),
							address: e.r.address,
							company_code: e.r.company_code,
							quantity: e.r.quantity
						};
					break;
					case 'goods_drawer':
						this.goods_drawer = e.r;
					break;
					case 'express_drawer':
						this.express_drawer = e.r;
					break;
					case 'on_delete':
						this.$confirm('确定要删除【'+e.r.no+'】交付订单吗？', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							const res = await this.$http.get(this.$api.URI_DELIVERIES, {params: {action: 'delete', id: e.r.id}, headers: {loading: true}});
							const { code, msg } = res.data;
							if (code != 0) return this.$message.error(msg);
							this.$message({
								type: 'success',
								duration: 1000,
								message: msg,
								onClose: () => {
									this.getDeliveries(this.params);
								}
							});
						})
					break;
				}
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					this.submitDeliveries(this.delivery);
				})
			},
			async submitDeliveries (data) {
				const res = await this.$http.post(this.$api.URI_DELIVERIES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						if (this.delivery.id) {
							this.delivery = {};
						}
						this.getDeliveries(this.params);
					}
				});
			},
			async getDeliveries (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_DELIVERIES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.deliveries = result.data;
				this.shops = result.shops;
				this.levels = result.levels;
				this.companies = result.companies;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				shops: [],
				levels: [],
				companies: [],
				deliveries: [],
				delivery: {},
				goods_drawer: {},
				express_drawer: {},
				params: {
					perPage: 10,
				}
			}
		},
		async created () {
			this.getDeliveries(this.params, true);
		}
	};
</script>