<template>
	<el-dialog width="860px" title="薪酬字段" :visible.sync="config_dialog" :fullscreen="theme.width < 860" append-to-body destroy-on-close>
		<el-table class="scroll-wrapper" style="margin-top: -30px;" height="48vh" :data="columns" :size="theme.size">
			<el-table-column label="名称" min-width="80">
				<el-link slot-scope="scope" type="primary" @click="column = {...scope.row}; columns_dialog = true;">{{scope.row.name}}</el-link>
			</el-table-column>
			<el-table-column label="类型" min-width="44" :formatter="({type}) => {  return types[type] || '/'; }"></el-table-column>
			<el-table-column label="缴税" min-width="44" :formatter="({tax}) => {  return tax ? '税前' : '税后'; }"></el-table-column>
			<el-table-column label="考勤" min-width="44" :formatter="({checking}) => {  return checking ? '是' : '否'; }"></el-table-column>
			<el-table-column label="初始" min-width="44" :formatter="({init}) => {  return init ? '是' : '否'; }"></el-table-column>
			<el-table-column label="工资条" min-width="55" :formatter="({display}) => {  return display ? '展示' : '隐藏'; }"></el-table-column>
			<el-table-column label="描述说明" min-width="120" prop="description" show-overflow-tooltip></el-table-column>
			<el-table-column label="更新时间" prop="updated_at" min-width="150"></el-table-column>
			<el-table-column label="创建时间" prop="created_at" min-width="150"></el-table-column>
			<el-table-column min-width="50">
				<el-link slot="header" type="primary" size="mini" @click="column = {}; columns_dialog = true;">添加</el-link>
				<el-link slot-scope="scope" type="primary" @click="delRow(scope.row)" :disabled="scope.row.init">删除</el-link>
			</el-table-column>
		</el-table>
		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getColumns({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getColumns({ ...params, page: v }) }"
			background>
		</el-pagination>
		<el-dialog :title="column.id ? '编辑字段' : '添加字段'" width="380px" :visible.sync="columns_dialog" append-to-body destroy-on-close>
			<el-form label-width="80px" ref="column_form" :model="column" :rules="column_rules" :size="theme.size" status-icon>
				<el-form-item label="名称" prop="name">
					<el-input v-model="column.name" placeholder="请输入名称" clearable>
						<el-select slot="prepend" style="width: 90px;" v-model="column.type" placeholder="类型" filterable clearable>
							<el-option v-for="(type, t) in types" :key="t" :label="type" :value="+t"></el-option>
						</el-select>
					</el-input>
				</el-form-item>
				<el-form-item label="备注说明" prop="description">
					<el-input v-model="column.description" type="textarea" placeholder="请输入备注说明。非必填" rows="3" maxlength="255" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item><p class="fs12 c9 lh1_5">注意：只有初始化字段会获取系统部分计算数值，手动创建添加字段只能手动创建导入数值</p></el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('column_form')">{{column.id ? '提交保存'  : '提交创建'}}</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			open () {
				this.getColumns(this.params, true);
				this.config_dialog = true;
			},
			delRow ({id, name}) {
				this.$confirm(name, '确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleColumns({action: 'delete', id});
				});
			},
			async handleColumns (params) {
				const res = await this.$http.get(this.$api.URI_SALARIES_FIELDS, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getColumns(this.params);
				this.$message.success(msg);
			},
			async submitColumn (data) {
				const res = await this.$http.post(this.$api.URI_SALARIES_FIELDS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.getColumns(this.params);
						this.columns_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'column_form':
							this.submitColumn(this.column);
						break;
					}
				});
			},
			async getColumns (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_SALARIES_FIELDS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.columns = result.data;
				this.types = result.types;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				types: [],
				column: {},
				columns: [],
				columns_dialog: false,
				config_dialog: false,
				column_rules: {
					name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
				},
				params: {
					perPage: 10
				}
			}
		}
	};
</script>