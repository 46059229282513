<template>
	<div>
		<el-form :inline="true" :model="params" :size="theme.size">
			<el-form-item label="关键词">
				<el-input v-model="params.keywords" placeholder="请输入商圈名称关键词" clearable></el-input>
			</el-form-item>
			<el-form-item label="所属地区">
				<el-cascader v-model="market.region" :props="{ ...regions, checkStrictly: true }"></el-cascader>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getMarkets({ ...params, page: 1})">查询</el-button>
				<el-button type="primary" @click="create_drawer = true" :disabled="!$utils.create('markets')">新增商圈</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="markets" :max-height="theme.height - 310" :size="theme.size">
			<el-table-column prop="id" label="ID" width="100"></el-table-column>
			<el-table-column prop="name" label="名称">
				<el-input slot-scope="scope" class="noborder" v-model="scope.row.name" placeholder="请输入商圈名称" @change="submitMarkets(scope.row)" :size="theme.size" :disabled="scope.row.deleted_at" :readonly="!$utils.update('markets')"></el-input>
			</el-table-column>
			<el-table-column prop="opening_at" label="营业时间">
				<template slot-scope="scope">{{scope.row.open_begin_at}} - {{scope.row.open_end_at}}</template>
			</el-table-column>
			<el-table-column prop="shops_count" label="店铺数量"></el-table-column>
			<el-table-column prop="region.name" label="所在地区"></el-table-column>
			<el-table-column prop="detail" label="详情地址"></el-table-column>
			<el-table-column prop="principal" label="负责人">
				<el-input slot-scope="scope" class="noborder" v-model="scope.row.principal" placeholder="请输入商圈负责人" @change="submitMarkets(scope.row)" :size="theme.size" :disabled="scope.row.deleted_at" :readonly="!$utils.update('markets')"></el-input>
			</el-table-column>
			<el-table-column prop="mobile" label="联系电话">
				<el-input slot-scope="scope" class="noborder" v-model="scope.row.mobile" placeholder="请输入商圈联系电话" @change="submitMarkets(scope.row)" :size="theme.size" :disabled="scope.row.deleted_at" :readonly="!$utils.update('markets')"></el-input>
			</el-table-column>
			<el-table-column prop="updated_at" label="更新时间" width="180"></el-table-column>
			<el-table-column prop="created_at" label="创建时间" width="180"></el-table-column>
			<el-table-column prop="name" label="更多操作" width="100">
				<template slot-scope="scope">
					<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore('markets')" v-if="scope.row.deleted_at">恢复</el-button>
					<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete('markets')" v-else>删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getMarkets({ ...params, page: 1, perPage: v}) }"
			@current-change="(v) => { getMarkets({ ...params, page: v}) }"
			background>	
		</el-pagination>
		<el-drawer title="新增商圈" :visible.sync="create_drawer" @closed="resetForm('market_form')">
			<div class="pl15 pr15">
				<el-form ref="market_form" label-width="80px" :model="market" :rules="market_rules" :size="theme.size">
					<el-form-item prop="name" label="名称">
						<el-input placeholder="请输入商圈名称" v-model="market.name" clearable></el-input>
					</el-form-item>
					<el-form-item prop="opening_at" label="营业时间">
						<el-time-picker value-format="H:mm:s" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" v-model="market.opening_at" is-range></el-time-picker>
					</el-form-item>
					<el-form-item prop="regions" label="所属地区">
						<el-cascader placeholder="请选择所属地区" v-model="market.regions" :props="regions"></el-cascader>
					</el-form-item>
					<el-form-item prop="detail" label="详细地址">
						<el-input placeholder="请输入详细地址" v-model="market.detail" clearable></el-input>
					</el-form-item>
					<el-form-item prop="principal" label="负责人">
						<el-input placeholder="请输入商圈负责人" v-model="market.principal" clearable></el-input>
					</el-form-item>
					<el-form-item prop="mobile" label="联系电话">
						<el-input placeholder="请输入商圈联系电话" v-model="market.mobile" clearable></el-input>
					</el-form-item>
					<el-form-item prop="parking_fee" label="停车收费">
						<el-input placeholder="用于说明商圈停车场的收费标准。非必填" type="textarea" rows="3" v-model="market.parking_fee" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('market_form')">立即创建</el-button>
						<el-button @click="resetForm('market_form')">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-drawer>
	</div>
</template>
<style>
	.el-cascader,
	.el-date-editor--timerange.el-input__inner {
		width: 100%;
	}
</style>
<script>
	import { mapState } from 'vuex';
	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			onDelete (r) {
				this.$confirm('确定要删除【'+r.name+'】商圈吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MARKETS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getMarkets(this.params);
						}
					});
				})
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.name+'】商圈吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MARKETS, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getMarkets(this.params);
						}
					});
				})
			},
			resetForm (ref) {
				this.create_drawer = false;
				this.$refs[ref].resetFields();
				this.market = {};
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误");
					let market = this.market;
					market.region_id = market.regions[market.regions.length-1];
					this.submitMarkets(market);
				});
			},
			async submitMarkets (data) {
				const res = await this.$http.post(this.$api.URI_MARKETS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						if (this.create_drawer) {
							this.create_drawer = false;
							this.getMarkets(this.params);
						}
					}
				});
			},
			async getMarkets (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_MARKETS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.markets = result.data;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				markets: [],
				create_drawer: false,
				market: {},
				market_rules: {
					name: [
						{ required: true, message: '请输入商圈名称', trigger: 'blur' },
						{ min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
					],
					opening_at: [
						{ required: true, type: 'array', message: '请选择营业时间', trigger: 'change' }
					],
					regions: [
						{ required: true, type: 'array', message: '请选择所属地区', trigger: 'change' }
					]
				},
				regions: {
					lazy: true,
					value: 'id',
					label: 'name',
					lazyLoad: async (node, resolve) => {
						const { value } = node;
						const res = await this.$http.get(this.$api.URI_REGIONS, {params: {action: 'region', region: value?value:0}, headers: {loading: true}});
						resolve(res.data.result);
					}
				},
				params: {
					perPage: 10,
				}
			}
		},
		created () {
			this.getMarkets(this.params, true);
		}
	};
</script>