<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-select v-model="params.employees" placeholder="请选择业务" filterable  multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-if="admin_user.shop_id">
					<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item v-else>
					<el-select v-model="params.shop_id" placeholder="城市站点" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getPerformancesObjectives({ ...params, page: 1})" plain>查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table class="scroll-wrapper absolute" height="100%" :data="objectives" :size="theme.size">
					<el-table-column label="归属部门" min-width="100" v-if="admin_user.shop_id">
						<template slot-scope="scope">{{scope.row | format_department(oa_users, oa_departments)}}</template>
					</el-table-column>
					<el-table-column label="城市站点" min-width="100" v-else :formatter="(r) => { return r.shop ? r.shop.name : theme.group; }"></el-table-column>
					<el-table-column label="业务员" prop="employee.name" min-width="120"></el-table-column>
					<el-table-column label="金额" :formatter="({money}) => { return money + '元'; }"></el-table-column>
					<el-table-column label="累计" :formatter="({total}) => { return total + '元'; }"></el-table-column>
					<el-table-column label="完成率" :formatter="({finished}) => { return finished + '%'; }"></el-table-column>
					<el-table-column label="完成周期" min-width="180">
						<template slot-scope="scope">{{scope.row.start_at}} - {{scope.row.end_at}}</template>
					</el-table-column>
					<template v-if="theme.width >= 1420">
						<el-table-column label="更新时间" prop="updated_at" min-width="150"></el-table-column>
						<el-table-column label="创建时间" prop="created_at" min-width="150"></el-table-column>
					</template>
					<el-table-column label="操作" width="80">
						<template slot-scope="scope">
							<el-link type="primary" @click="onDelete(scope.row)" :disabled="!$utils.delete($api.URI_PERFORMANCES_OBJECTIVES) || scope.row.deleted_at">删除</el-link>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="theme.is_mobile ? 'total, prev, pager, next' : $config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:pager-count="theme.is_mobile ? 3 : 18"
			:total="params.total"
			@size-change="(v) => { getPerformancesObjectives({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getPerformancesObjectives({ ...params, page: v }) }"
			background>
		</el-pagination>
	</main-table-a>
</template>


<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		computed: {
			...mapState(['theme', 'admin_user', 'oa_users', 'oa_departments']),
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			}
		},
		filters: {
			format_department ({ employee }, users, departments) {
				if (!employee) return '公海';
				if (!users[employee.id]) return '未关联OA';
				const { main_department } = users[employee.id];
				if (!departments[main_department]) return '未知部门';
				return departments[main_department].name;
			}
		},
		methods: {
			onDelete ({id, employee}) {
				this.$confirm('删除后不能恢复', '确定要删除【'+employee.name+'】绩效目标吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_PERFORMANCES_OBJECTIVES, {params: {action: 'delete', id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getPerformancesObjectives(this.params);
					this.$message.success(msg);
				});
			},
			async getPerformancesObjectives (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_PERFORMANCES_OBJECTIVES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.objectives = result.data;
				this.shops = result.shops;
				this.employees = result.employees;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				shops: [],
				employees: [],
				objectives: [],
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getPerformancesObjectives(this.params, true);
		}
	};
</script>