<template>
	<el-dialog width="420px" title="调岗转正" :visible.sync="show_dialog" :fullscreen="theme.width < 680" :close-on-click-modal="false" append-to-body destroy-on-close>
		<el-form label-width="70px" ref="transfer_form" :model="transfer" :rules="rules" :size="theme.size" status-icon>
			<el-form-item label="名称" prop="name">
				<el-input v-model="transfer.name" placeholder="请输入名称" clearable>
					<el-switch slot="append" v-model="transfer.official" :active-value="1" :inactive-value="0" active-text="正式" inactive-text="试用"></el-switch>
				</el-input>
			</el-form-item>
			<el-form-item label="备注说明" prop="comment">
				<el-input type="textarea" v-model="transfer.comment" :rows="3" placeholder="请输入备注说明"></el-input>
			</el-form-item>
			<el-form-item prop="attachments">
				<el-upload
					:action="$api.URI_UPLOADS"
					:data="{type: transfers_cfg.type}"
					:accept="transfers_cfg.ext.map(e => { return '.'+e; }).join(',')"
					:on-success="(r, f, l) => { onSuccess(l, 'attachments'); }"
					:on-remove="(f, l) => { transfer = {...transfer, attachments: l}; }"
					:file-list="transfer.attachments"
					:limit="1">
					<el-button type="text" icon="el-icon-upload">点击上传</el-button>
					<p slot="tip" class="fs12 c9 lh1_5">只能上传 {{transfers_cfg.ext.join('/').toUpperCase()}} 文件，且大小不超过 {{transfers_cfg.format_size_unit}}<br>总经办领导签字文件</p>
				</el-upload>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('transfer_form')">{{transfer.id ? '提交保存' : '提交创建'}}</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme']),
			transfers_cfg () {
				return this.$utils.uploadConfig('positions_transfers');
			}
		},
		filters: {

		},
		methods: {
			async open (r) {
				this.transfer = {...r};
				this.show_dialog = true;
			},
			async submitTransfers (data) {
				const res = await this.$http.post(this.$api.URI_POSITIONS_TRANSFERS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.$emit('refresh');
						this.show_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'transfer_form':
							this.submitTransfers(this.transfer);
						break;
					}
				});
			}
		},
		data() {
			return {
				transfer: {},
				show_dialog: false,
				rules: {
					name: [{ required: true, message: '必须填写名称', trigger: 'blur'}]
				}
			}
		}
	};
</script>