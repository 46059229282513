import Vue from 'vue';
import Store from '../store';
import VueRouter from 'vue-router';
import Util from '../utils';
import Api from '../utils/api';
import http from '../utils/http';
import Config from '../utils/config';
import { Message } from 'element-ui';

Vue.use(VueRouter);
const router = new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '/login',
			name: 'Login',
			meta: {title: '后台登陆'},
			component: () => import('@/pages/Login')
		}, {
			path: '/enrollment',
			name: 'Enrollment',
			meta: {title: '入职登记'},
			component: () => import('@/pages/Enrollment')
		}, {
			path: '/register',
			name: 'Register',
			meta: {title: '注册账户'},
			component: () => import('@/pages/register')
		}, {
			path: '/forgot',
			name: 'Forgot',
			meta: {title: '找回密码'},
			component: () => import('@/pages/forgot')
		}, {
			path: '/create',
			name: 'Create',
			meta: {title: '创建企业'},
			component: () => import('@/pages/create')
		}, {
			path: '/manages',
			name: 'Manages',
			meta: {title: '选择管理'},
			component: () => import('@/pages/manages')
		}, {
			path: '/wochat/oauth2',
			name: 'WochatOauth2',
			meta: {title: '注册绑定账户', alive: true},
			component: () => import('@/pages/wochat_oauth2')
		}, {
			path: '/authorizer',
			name: 'Authorizer',
			meta: {title: '获取授权信息', alive: true},
			component: () => import('@/pages/authorizer')
		}, {
			path: '/noadmin',
			name: 'NoAdmin',
			meta: {title: '无后台权限'},
			component: () => import('@/pages/NoAdmin')
		}, {
			path: '/',
			name: 'Workbench',
			component: () => import('@/pages/Workbench'),
			children: [
				{
					path: '/',
					name: 'Home',
					meta: {title: '工作台', alive: false},
					component: () => import('@/pages/home')
				}, {
					path: '/users',
					name: 'Users',
					meta: {title: '用户列表', alive: true},
					component: () => import('@/pages/urm/index')
				}, {
					path: '/roles',
					name: 'Roles',
					meta: {title: '角色管理', alive: true},
					component: () => import('@/pages/urm/roles')
				}, {
					path: '/bills',
					name: 'BILLS',
					meta: {title: '账单明细', alive: true},
					component: () => import('@/pages/urm/bill')
				}, {
					path: '/scores',
					name: 'Scores',
					meta: {title: '积分明细', alive: true},
					component: () => import('@/pages/urm/score')
				}, {
					path: '/wallets',
					name: 'Wallets',
					meta: {title: '余额明细', alive: true},
					component: () => import('@/pages/urm/wallet')
				}, {
					path: '/members',
					name: 'Members',
					meta: {title: '用戶会员', alive: true},
					component: () => import('@/pages/urm/member')
				}, {
					path: '/oacontacts',
					name: 'OaContacts',
					meta: {title: 'OA通讯录', alive: true},
					component: () => import('@/pages/urm/oacontacts')
				}, {
					path: '/employees',
					name: 'Employees',
					meta: {title: '员工列表', alive: true},
					component: () => import('@/pages/hrm/employees/index')
				}, {
					path: '/positions/candidates',
					name: 'PositionsCandidates',
					meta: {title: '应聘职位', alive: true},
					component: () => import('@/pages/hrm/candidates')
				}, {
					path: '/positions/recruitments',
					name: 'PositionsRecruitments',
					meta: {title: '招聘职位', alive: true},
					component: () => import('@/pages/hrm/recruitments')
				}, {
					path: '/positions/transfers',
					name: 'PositionsTransfers',
					meta: {title: '调岗转正', alive: true},
					component: () => import('@/pages/hrm/positions/transfers')
				}, {
					path: '/approvals',
					name: 'Approvals',
					meta: {title: '审核列表', alive: true},
					component: () => import('@/pages/hrm/approvals')
				}, {
					path: '/departures',
					name: 'Departures',
					meta: {title: '离职申请', alive: true},
					component: () => import('@/pages/hrm/departures/index')
				}, {
					path: '/enrollments',
					name: 'Dnrollments',
					meta: {title: '入职登记', alive: true},
					component: () => import('@/pages/hrm/enrollments/index')
				}, {
					path: '/checkin',
					name: 'Checkin',
					meta: {title: '考勤统计', alive: true},
					component: () => import('@/pages/hrm/checkin/index')
				}, {
					path: '/insurances',
					name: 'Insurances',
					meta: {title: '员工投保', alive: true},
					component: () => import('@/pages/hrm/insurances')
				}, {
					path: '/salaries/configs',
					name: 'SalariesConfigs',
					meta: {title: '薪酬配置', alive: true},
					component: () => import('@/pages/hrm/salaries/config')
				}, {
					path: '/masterless',
					name: 'Masterless',
					meta: {title: '客户公海', alive: true},
					component: () => import('@/pages/crm/clients/masterless')
				}, {
					path: '/clues',
					name: 'Clues',
					meta: {title: '线索列表', alive: true},
					component: () => import('@/pages/crm/clues')
				}, {
					path: '/clues/follows',
					name: 'CluesFollows',
					meta: {title: '线索跟进', alive: true},
					component: () => import('@/pages/crm/clues_follows')
				}, {
					path: '/clients',
					name: 'Clients',
					meta: {title: '客户列表', alive: true},
					component: () => import('@/pages/crm/clients/index')
				}, {
					path: '/clients/follows',
					name: 'ClientsFollows',
					meta: {title: '客户跟进', alive: true},
					component: () => import('@/pages/crm/follows')
				}, {
					path: '/clients/statistics',
					name: 'ClientsStatistics',
					meta: {title: '数据统计', alive: true},
					component: () => import('@/pages/crm/clients/statistics')
				}, {
					path: '/contracts',
					name: 'Contracts',
					meta: {title: '签约合同', alive: true},
					component: () => import('@/pages/crm/contracts/index')
				}, {
					path: '/clients/fees',
					name: 'ClientsFees',
					meta: {title: '客户回款', alive: true},
					component: () => import('@/pages/crm/fees/index')
				}, {
					path: '/performances',
					name: 'Performances',
					meta: {title: '业务绩效', alive: true},
					component: () => import('@/pages/fms/performances')
				}, {
					path: '/performances/rates',
					name: 'PerformancesRates',
					meta: {title: '绩效提成', alive: true},
					component: () => import('@/pages/fms/performances/rates')
				}, {
					path: '/performances/objectives',
					name: 'PerformancesObjectives',
					meta: {title: '绩效目标', alive: true},
					component: () => import('@/pages/fms/performances/objectives')
				}, {
					path: '/performances/statistics',
					name: 'PerformancesStatistics',
					meta: {title: '绩效统计', alive: true},
					component: () => import('@/pages/fms/performances/statistics')
				}, {
					path: '/salaries',
					name: 'Salaries',
					meta: {title: '薪酬列表', alive: true},
					component: () => import('@/pages/fms/salaries/index')
				}, {
					path: '/salaries/details',
					name: 'SalariesDetails',
					meta: {title: '薪酬明细', alive: true},
					component: () => import('@/pages/fms/salaries/details')
				}, {
					path: '/employees/expenses',
					name: 'Expenses',
					meta: {title: '费用报销', alive: true},
					component: () => import('@/pages/fms/expenses/index')
				}, {
					path: '/employees/payments',
					name: 'Payments',
					meta: {title: '付款申请', alive: true},
					component: () => import('@/pages/fms/payments/index')
				}, {
					path: '/invoices',
					name: 'Invoices',
					meta: {title: '发票申请', alive: true},
					component: () => import('@/pages/fms/invoices/index')
				}, {
					path: '/articles',
					name: 'Articles',
					meta: {title: '文章列表', alive: true},
					component: () => import('@/pages/cms/articles')
				}, {
					path: '/videos',
					name: 'Videos',
					meta: {title: '视频列表', alive: true},
					component: () => import('@/pages/cms/videos')
				}, {
					path: '/courses',
					name: 'Courses',
					meta: {title: '课程列表', alive: true},
					component: () => import('@/pages/cms/courses')
				}, {
					path: '/specials',
					name: 'Specials',
					meta: {title: '特殊专题', alive: true},
					component: () => import('@/pages/cms/specials')
				}, {
					path: '/goods',
					name: 'Goods',
					meta: {title: '产品列表', alive: true},
					component: () => import('@/pages/oms/goods/index')
				}, {
					path: '/goods/discounts',
					name: 'GoodsDiscounts',
					meta: {title: '产品优惠', alive: true},
					component: () => import('@/pages/oms/goods/discounts')
				}, {
					path: '/goods/sku',
					name: 'GoodsSku',
					meta: {title: '库存管理', alive: true},
					component: () => import('@/pages/oms/goods/sku')
				}, {
					path: '/goods/type',
					name: 'GoodsType',
					meta: {title: '产品类型', alive: true},
					component: () => import('@/pages/oms/goods/type')
				}, {
					path: '/orders',
					name: 'Orders',
					meta: {title: '销售订单', alive: true},
					component: () => import('@/pages/oms/orders/index')
				}, {
					path: '/deliveries/services',
					name: 'DeliveriesServices',
					meta: {title: '交付服务', alive: true},
					component: () => import('@/pages/oms/deliveries/services')
				}, {
					path: '/orders/statistics',
					name: 'OrdersStatistics',
					meta: {title: '销售统计', alive: true},
					component: () => import('@/pages/oms/orders/statistics')
				}, {
					path: '/topics',
					name: 'Topics',
					meta: {title: '话题列表', alive: true},
					component: () => import('@/pages/cms/topics')
				}, {
					path: '/brands',
					name: 'Brands',
					meta: {title: '品牌列表', alive: true},
					component: () => import('@/pages/cms/brands')
				}, {
					path: '/warehouses',
					name: 'Warehouses',
					meta: {title: '仓库列表', alive: true},
					component: () => import('@/pages/wms/warehouses/index')
				}, {
					path: '/warehouses/materials',
					name: 'WarehousesMaterials',
					meta: {title: '物料库存', alive: true},
					component: () => import('@/pages/wms/warehouses/materials')
				}, {
					path: '/materials',
					name: 'Materials',
					meta: {title: '物料列表', alive: true},
					component: () => import('@/pages/wms/materials/index')
				}, {
					path: '/materials/suppliers',
					name: 'MaterialsSuppliers',
					meta: {title: '物料供应', alive: true},
					component: () => import('@/pages/wms/provisions/index')
				}, {
					path: '/materials/transfers',
					name: 'MaterialsTransfers',
					meta: {title: '物料调拨', alive: true},
					component: () => import('@/pages/wms/transfers')
				}, {
					path: '/materials/inventories',
					name: 'MaterialsInventories',
					meta: {title: '物料盘点', alive: true},
					component: () => import('@/pages/wms/inventories')
				}, {
					path: '/materials/deliveries',
					name: 'MaterialsDeliveries',
					meta: {title: '物料配送', alive: true},
					component: () => import('@/pages/wms/deliveries/index')
				}, {
					path: '/materials/purchases',
					name: 'MaterialsPurchases',
					meta: {title: '物料采购', alive: true},
					component: () => import('@/pages/wms/purchases')
				}, {
					path: '/materials/sheets',
					name: 'MaterialsSheets',
					meta: {title: '出入明细', alive: true},
					component: () => import('@/pages/wms/materials/sheets')
				}, {
					path: '/suppliers',
					name: 'Suppliers',
					meta: {title: '供应商家', alive: true},
					component: () => import('@/pages/wms/suppliers')
				}, {
					path: '/categories',
					name: 'Categories',
					meta: {title: '内容类目', alive: true},
					component: () => import('@/pages/cms/categories')
				}, {
					path: '/companies',
					name: 'Companies',
					meta: {title: '企业列表', alive: true},
					component: () => import('@/pages/cms/principals')
				}, {
					path: '/apps',
					name: ' BaiduApps',
					meta: {title: '第三方应用', alive: true},
					component: () => import('@/pages/system/apps')
				}, {
					path: '/wechat',
					name: 'WechatApp',
					meta: {title: '微信公众号', alive: true},
					component: () => import('@/pages/system/wechat/app')
				}, {
					path: '/regions',
					name: 'Regions',
					meta: {title: '地区列表', alive: true},
					component: () => import('@/pages/system/regions')
				}, {
					path: '/coupons',
					name: 'Coupons',
					meta: {title: '优惠券列表', alive: true},
					component: () => import('@/pages/mms/coupons/index')
				}, {
					path: '/coupons/records',
					name: 'CouponsRecords',
					meta: {title: '优惠券记录', alive: true},
					component: () => import('@/pages/mms/coupons/records')
				}, {
					path: '/luckydraws',
					name: 'Luckydraws',
					meta: {title: '抽奖列表', alive: true},
					component: () => import('@/pages/mms/luckydraws/index')
				}, {
					path: '/luckydraws/create',
					name: 'LuckydrawsCreate',
					meta: {title: '创建抽奖', alive: true},
					component: () => import('@/pages/mms/luckydraws/create')
				}, {
					path: '/luckydraws/records',
					name: 'LuckydrawsRecords',
					meta: {title: '抽奖记录', alive: true},
					component: () => import('@/pages/mms/luckydraws/records')
				}, {
					path: '/luckydraws/:id',
					name: 'LuckydrawsEdit',
					meta: {title: '编辑抽奖', alive: true},
					component: () => import('@/pages/mms/luckydraws/create')
				}, {
					path: '/deliveries',
					name: 'Deliveries',
					meta: {title: '交付列表', alive: true},
					component: () => import('@/pages/mall/deliveries')
				}, {
					path: '/expresses',
					name: 'Expresses',
					meta: {title: '快递列表', alive: true},
					component: () => import('@/pages/mall/expresses')
				}, {
					path: '/shops',
					name: 'Shops',
					meta: {title: '店铺列表', alive: true},
					component: () => import('@/pages/mall/shops')
				}, {
					path: '/carts',
					name: 'Carts',
					meta: {title: '购物车', alive: true},
					component: () => import('@/pages/mall/carts')
				}, {
					path: '/addresses',
					name: 'Address',
					meta: {title: '地址列表', alive: true},
					component: () => import('@/pages/mall/addresses')
				}, {
					path: '/shop/basic',
					name: 'ShopBasic',
					meta: {title: '店铺信息', alive: true},
					component: () => import('@/pages/mall/shop/basic')
				}, {
					path: '/shop/weapp',
					name: 'ShopWeapp',
					meta: {title: '店铺小程序', alive: true},
					component: () => import('@/pages/mall/shop/weapp')
				}, {
					path: '/markets',
					name: 'Markets',
					meta: {title: '市场商圈', alive: true},
					component: () => import('@/pages/mall/markets')
				}, {
					path: '/h5',
					name: 'H5',
					meta: {title: 'H5作品', alive: true},
					component: () => import('@/pages/editor/index')
				}, {
					path: '/h5/create',
					name: 'H5Create',
					meta: {title: '创建H5', alive: true},
					component: () => import('@/pages/editor/create')
				}, {
					path: '/sms',
					name: 'Sms',
					meta: {title: '短信记录', alive: true},
					component: () => import('@/pages/system/sms')
				}, {
					path: '/models/dictionaries',
					name: 'ModelDictionary',
					meta: {title: '模型字典', alive: true},
					component: () => import('@/pages/system/dictionaries')
				}, {
					path: '/logs',
					name: 'Logs',
					meta: {title: '操作日志', alive: true},
					component: () => import('@/pages/system/logs')
				}, {
					path: '/noaccess',
					name: 'NoAccess',
					meta: {title: '无权限访问', alive: true},
					component: () => import('@/pages/noaccess')
				}, {
					path: '/imessage',
					name: 'IMessage',
					meta: {title: '在线对话', alive: true},
					component: () => import('@/pages/imessage')
				}, {
					path: 'statistics/yingxiao',
					name: 'StatisticsYingxiao',
					meta: {title: '营销统计', alive: true},
					component: () => import('@/pages/statistics/yingxiao')
				}, {
					path: 'statistics/qudao',
					name: 'StatisticsQudao',
					meta: {title: '渠道统计', alive: true},
					component: () => import('@/pages/statistics/qudao')
				}, {
					path: 'statistics/chanpin',
					name: 'StatisticsChanpin',
					meta: {title: '产品统计', alive: true},
					component: () => import('@/pages/statistics/chanpin')
				}, {
					path: '/*',
					name: 'NotFound',
					meta: {title: '页面未找到', alive: true},
					component: () => import('@/pages/notfound')
				}
			]
		}
	]
});
const { URI_LOGIN } = Api;

router.beforeEach(async (to, from, next) => {
	// 设置页面标题
	document.title = to.meta.title + ' | ' +Config.APP_NAME;
	const { protocol, host, pathname, href } = window.location;
	const redirect_uri = encodeURIComponent(href);
	const isAuth = Store.state.hasLogin;
	const notAuth = new Set(['Enrollment', 'Register', 'Forgot', 'Create']);
	if (notAuth.has(to.name)) return next();
	if (to.name === 'WochatOauth2') {
		if (!to.query.appid || !to.query.agentid || !to.query.code || to.query.state != 'loginWochat') return next({ name: 'Login', query: { redirect_uri } });
		return next();
	}
	if (to.name === 'Login') {
		if (isAuth) return next({ name: 'Home' });
		return next();
	}
	// 是否为企业微信浏览器环境
	if (Util.isWochat()) {
		if (!isAuth) {
			// 通过域名获取OA信息
			const getoa = await http.post(URI_LOGIN, {action: 'getoa', host});
			const { code: oa_c, result: oa } = getoa.data;
			// 获取OA信息失败，跳转账户密码登录
			if (oa_c) return next({ name: 'Login', query: { redirect_uri } });
			if (oa.wochat) {
				const { appid, agentid } = oa.wochat;
				const { code } = to.query;
				if (code) {
					const res = await http.post(URI_LOGIN, {action: 'wochat', code, appid, agentid});
					const { code: login_c, msg, result } = res.data;
					switch (login_c) {
						case 2:
							var redirect = encodeURIComponent(`${protocol}//${host}/wochat/oauth2?appid=${appid}&agentid=${agentid}&redirect_uri=${protocol}//${host}${pathname}`);
							window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=snsapi_privateinfo&state=loginWochat&agentid=${agentid}#wechat_redirect`;
						break;
						case 1:
							Message.error(msg);
							next({ name: 'Login', query: { redirect_uri } });
						break;
						case 0:
							Store.commit('login', result);
						break;
					}
				} else {
					window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=loginWochat&agentid=${agentid}#wechat_redirect`;
				}
			}
		}
	}
	// if (to.name === 'WochatOauth2') return next();
	// const { code, state } = to.query;
	// if (from.path == '/' && state == 'loginWochat' && code) {
	// 	const getoa = await Api.postLogin({action: 'getoa', host});
	// 	const { code: oa_c, result: oa } = getoa.data;
	// 	console.log(oa);
	// 	if (!oa_c) {
	// 		const { appid, agentid } = oa.wochat;
	// 		const res = await Api.postLogin({action: 'wochat', code, appid, agentid});
	// 		const { code: c, msg, result } = res.data;
	// 		if (c == 2 && Util.isWochat()) {
	// 			// 获取用户敏感信息。需要确认操作
	// 			return window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_privateinfo&state=loginWochat&agentid=${agentid}#wechat_redirect`;
	// 		}
	// 		if (c != 0) {
	// 			Message.error(msg);
	// 			return next({ name: 'Login', query: { redirect_uri } });
	// 		}
	// 		Store.commit('login', result);
	// 	}
	// }
	// if (to.name !== 'Login' && !isAuth) {
	// 	if (!Util.isWochat()) return next({ name: 'Login', query: { redirect_uri } });
	// 	const getoa = await Api.postLogin({action: 'getoa', host});
	// 	const { code: oa_c, result: oa } = getoa.data;
	// 	if (!oa_c) {
	// 		const { appid, agentid } = oa.wochat;
	// 		// 获取用户普通信息。无需确认操作
	// 		return window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=loginWochat&agentid=${agentid}#wechat_redirect`;
	// 	}
	// }
	let routes = Store.state.admin_routes;
	let admin_user = Store.state.admin_user;
	if (to.name !== 'Login' && !routes.size) {
		await Store.dispatch('getConfig');
		routes = Store.state.admin_routes;
		admin_user = Store.state.admin_user;
	}
	// 如果用户未能验证身份，则 `next` 会被调用两次
	if (to.name !== 'Login' && to.name !== 'NoAdmin') {
		if (!routes.has('/')) return next({ name: 'NoAdmin' });
		// if (!routes.has(to.path) && to.name !== 'NoAccess') return next({ name: 'NoAccess' });
		const filters = new Set(['NoAccess', 'NotFound', 'IMessage', 'Authorizer', 'ShopBasic', 'Manages']);
		if (!filters.has(to.name) && !isRoute(routes, to.path)) return next({ name: 'NoAccess' });
		// 未选择当前登录身份的，跳转选择页面
		if (!admin_user.employee && to.name != 'Manages') return next({ name: 'Manages' });
		setHistoryRoutes(to);
		// 过滤
		// const unsetTabRoutes = new Set(['Authorizer', 'ShopWeapp']);
		// if (!unsetTabRoutes.has(to.name)) {
			let tabs = Store.state.tab_routes;
			for (let tab of tabs) {
				if (tab.fullPath === to.fullPath) return next();
				// if (tab.path === to.path) return next();
			}
			tabs.push({name: to.name, fullPath: to.fullPath, path: to.path, meta: to.meta, close: true});
			Store.commit('setTabRoutes', tabs);
		// }
	}
	return next();
})

const isRoute = (r, p) => {
	let s = false;
	r.forEach((i) => {
		if (new RegExp(i+'$').test(p)) {
			s = true;
		}
	});
	return s;
}

const setHistoryRoutes = (t) => {
	// 过滤
	const filters = new Set(['NotFound', 'NoAccess']);
	if (filters.has(t.name)) return false;
	let n = Store.state.history_routes || [];
	// 添加
	n.unshift({title: t.meta.title, path: t.fullPath});
	// 限存
	if (n.length > 18) {
		n.pop();
	}
	// 去重
	let m = new Map();
	for (let h of n) {
		!m.has(h.fullPath) && m.set(h.fullPath, h);
	}
	Store.commit('setHistoryRoutes', [...m.values()]);
}

export default router;
