<template>
	<el-dialog width="1080px" title="投保产品" :fullscreen="theme.width < 1080" :visible.sync="config_dialog" append-to-body destroy-on-close>
		<el-form style="margin-top: -30px;" :inline="true" :model="params" :size="theme.size">
			<el-form-item >
				<el-input style="width: 180px;" v-model="params.keyword" placeholder="名称/备注说明" clearable></el-input>
			</el-form-item>
			<el-form-item v-if="!admin_user.shop_id">
				<el-select v-model="params.shop_id" placeholder="城市站点" filterable clearable>
					<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-select style="width: 180px;" v-model="params.employees" placeholder="请选择员工" filterable  multiple collapse-tags>
					<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item >
				<el-select style="width: 140px;" v-model="params.type" placeholder="类型" filterable  multiple collapse-tags>
					<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getInsurances({ ...params, page: 1})">查询</el-button>
			</el-form-item>
		</el-form>
		<el-table ref="insurance_table" height="46vh" :data="insurances" :size="theme.size" @sort-change="sortChange">
			<el-table-column prop="employee.name" label="创建人" min-width="90"></el-table-column>
			<el-table-column prop="name" label="名称" min-width="150">
				<el-link slot-scope="scope" type="primary" :disabled="!$utils.update($api.URI_INSURANCES_TEMPS)" @click="insurance = { ...scope.row, ent: scope.row.ent/100, ind: scope.row.ind/100 }; insurance_dialog = true;">{{scope.row.name}}</el-link>
			</el-table-column>
			<el-table-column label="类型" min-width="50" :formatter="({type}) => { return types[type] || '未知'}"></el-table-column>
			<el-table-column label="企业" prop="ent" min-width="70" sortable="custom" :formatter="({ent}) => { return ent/100 + ' %'}"></el-table-column>
			<el-table-column label="个人" prop="ind" min-width="70" sortable="custom" :formatter="({ind}) => { return ind/100 + ' %'}"></el-table-column>
			<el-table-column label="投保人数" prop="insurance_funds_count" min-width="70"></el-table-column>
			<el-table-column label="备注说明" prop="remarks" min-width="120" show-overflow-tooltip></el-table-column>
			<el-table-column label="更新时间" prop="updated_at" min-width="150"></el-table-column>
			<el-table-column label="创建时间" prop="created_at" min-width="150"></el-table-column>
			<el-table-column min-width="60">
				<el-link slot="header" type="primary" size="mini" :disabled="!$utils.create($api.URI_INSURANCES_TEMPS)" @click="insurance_dialog = true">添加</el-link>
				<template slot-scope="scope">
					<el-button type="text" :disabled="!$utils.delete($api.URI_INSURANCES_TEMPS)" @click="delInsurance(scope.row)" v-if="!scope.row.deleted_at">删除</el-button>
					<el-button type="text" :disabled="!$utils.restore($api.URI_INSURANCES_TEMPS)" @click="resInsurance(scope.row)" v-else>恢复</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getInsurances({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getInsurances({ ...params, page: v }) }"
			background>	
		</el-pagination>
		<el-dialog width="460px" :title="insurance.id ? '编辑产品' : '创建产品'" :visible.sync="insurance_dialog" @closed="insurance = {}" append-to-body destroy-on-close>
			<el-form label-width="90px" ref="insurance_form" :model="insurance" :rules="insurance_rules" :size="theme.size" status-icon>
				<el-form-item label="名称/类型" style="margin-bottom: 0;" required>
					<el-col :span="14">
						<el-form-item prop="name">
							<el-input v-model="insurance.name" placeholder="请输入产品名称" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col class="tac" :span="2">-</el-col>
					<el-col :span="8">
						<el-form-item prop="type">
							<el-select v-model="insurance.type" :disabled="insurance.id" placeholder="类型" filterable clearable>
								<el-option v-for="(type, t) in types" :key="t" :label="type" :value="+t"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="企业/个人" style="margin-bottom: 0;" required>
					<el-col :span="11">
						<el-form-item prop="ent">
							<el-input-number style="width: 100%;" placeholder="企业缴费比例" v-model="insurance.ent" :precision="2" :step="0.01" :min="0"></el-input-number>
						</el-form-item>
					</el-col>
					<el-col class="tac" :span="2">-</el-col>
					<el-col :span="11">
						<el-form-item prop="ind">
							<el-input-number style="width: 100%;" placeholder="个人缴费比例" v-model="insurance.ind" :precision="2" :step="0.01" :min="0"></el-input-number>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="备注说明" prop="remarks">
					<el-input v-model="insurance.remarks" type="textarea" placeholder="请输入备注说明。非必填" rows="3" maxlength="255" show-word-limit clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('insurance_form')">{{insurance.id ? '提交保存'  : '提交创建'}}</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme', 'admin_user']),
			insurances_cfg () {
				return this.$utils.uploadConfig('insurances');
			}
		},
		methods: {
			open () {
				this.getInsurances(this.params, true);
				this.config_dialog = true;
			},
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getInsurances(this.params);
			},
			openFile (u) {
				window.open(u);
			},
			onSuccess (list) {
				this.insurance = {
					...this.insurance,
					attachments: list.map((file) => {
						const { response } = file;
						if (response) {
							const { status, id } = response.result;
							if (status == "success") {
								file.id = id;
							}
						}
						return file;
					})
				};
			},
			delInsurance ({ id, name }) {
				this.$confirm(name, '确定要删除吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleInsurances({action: 'delete', id});
				});
			},
			resInsurance ({ id, name }) {
				this.$confirm(name, '确定要恢复吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.handleInsurances({action: 'restore', id});
				});
			},
			async getInsurances (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_INSURANCES_TEMPS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.insurances = result.data;
				this.types = result.types;
				this.employees = result.employees;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async handleInsurances (params) {
				const res = await this.$http.get(this.$api.URI_INSURANCES_TEMPS, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getInsurances(this.params);
				this.$message.success(msg);
			},
			async submitInsurance (data) {
				const res = await this.$http.post(this.$api.URI_INSURANCES_TEMPS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.getInsurances(this.params);
						this.insurance_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'insurance_form':
							this.submitInsurance(this.insurance);
						break;
					}
				});
			}
		},
		data() {
			return {
				insurance: {},
				insurances: [],
				employees: [],
				types: [],
				insurance_dialog: false,
				config_dialog: false,
				params: {
					perPage: 10
				},
				insurance_rules: {
					name: [{ required: true, message: '必须填写投保产品名称', trigger: 'blur'}],
					type: [{ required: true, message: '必须选择投保产品', trigger: 'blur'}],
					ent: [{ required: true, message: '必须填写企业缴费比例', trigger: 'blur'}],
					ind: [{ required: true, message: '必须填写个人缴费比例', trigger: 'blur'}],
				}
			}
		}
	};
</script>