<template>
	<div class="bcff">
		<div class="df aic jcsb m0a p10 bsbb" style="width: 1200px;">
			<div class="df aic">
				<img style="width: 140px;" src="@/assets/logo_slogan.png" alt="">
				<div class="fw9 c6 pl10">{{title}}</div>
			</div>
			<el-dropdown @command="handleCommand" v-if="admin_user">
				<div class="el-dropdown-link df aic cp">
					<el-avatar size="small" class="mr5" :src="admin_user.avatar || 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'"></el-avatar>
					<span class="fs16">{{admin_user.nick_name}}</span>
					<i class="el-icon-arrow-down el-icon--right"></i>
				</div>
				<template slot="dropdown">
					<el-dropdown-menu>
						<el-dropdown-item command="secure">修改密码</el-dropdown-item>
						<el-dropdown-item command="logout" divided>退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</template>
			</el-dropdown>
		</div>
	</div>
</template>


<script>
	import { mapState, mapActions } from 'vuex';

	export default {
		props: {
			title: {
				type: String
			}
		},
		computed: {
			...mapState(['admin_user'])
		},
		methods: {
			...mapActions(['logout']),
			handleCommand (cmd) {
				switch (cmd) {
					case 'secure':
						this.modify_drawer = true;
					break;
					case 'logout':
						this.$confirm('您正在操作退出登录系统, 是否继续？', '退出提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$message({
								type: 'success',
								duration: 2000,
								message: '正在退出系统...',
								onClose: () => {
									this.logout();
									this.$router.push({path: '/login', query: { redirect_uri: window.location.href }});
								}
							});
						});
					break;
				}
			}
		}
	}
</script>