<template>
	<el-dialog width="430px" :title="departure.id ? (departure.status == 4 ? '更新资料' : '申请详情') : '创建申请'" :visible.sync="departure_dialog" @closed="departure = {}" append-to-body destroy-on-close>
		<div class="pr" style="margin-top: -20px;" v-if="is_show">
			<el-descriptions :labelStyle="{width: '70px'}" :title="'No.'+departure.no" :column="2" :size="theme.size" border>
				<el-descriptions-item label="离职日期">{{departure.separated_at | format_at}}</el-descriptions-item>
				<el-descriptions-item label="申请日期">{{departure.created_at}}</el-descriptions-item>
				<el-descriptions-item label="离职理由" :span="2">{{departure.reason}}</el-descriptions-item>
				<el-descriptions-item label="说明附件" :span="2">
					<el-form ref="departure_form" :model="departure" :size="theme.size" v-if="departure.status == 4 && $utils.update($api.URI_EMPLOYEES_DEPARTURES)" status-icon>
						<el-form-item prop="attachments" :rules="[{ required: departure.id, message: '必须上传附件', trigger: 'blur'}]">
							<el-upload
								:action="$api.URI_UPLOADS"
								:data="{type: departures_cfg.type}"
								:accept="departures_cfg.ext.map(e => { return '.'+e; }).join(',')"
								:on-success="(r, f, l) => { onSuccess(l, 'attachments'); }"
								:on-remove="(f, l) => { departure = {...departure, attachments: l}; }"
								:file-list="departure.attachments"
								:limit="1">
								<el-button type="text" icon="el-icon-upload">点击上传</el-button>
								<p slot="tip" class="fs12 c9 lh1_5">只能上传 {{departures_cfg.ext.join('/').toUpperCase()}} 文件，且大小不超过 {{departures_cfg.format_size_unit}}<br>交接、证明等离职签字文件</p>
							</el-upload>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('departure_form')">保存附件</el-button>
						</el-form-item>
					</el-form>
				</el-descriptions-item>
			</el-descriptions>
			<div class="pa fs38 fwb" style="color: rgba(255, 0, 0, 0.2); top: 80px; left: 50%; transform: translate(-50%, -50%) rotateZ(20deg);" v-if="status[departure.status]">{{status[departure.status]}}</div>
		</div>
		<el-form label-width="80px" ref="departure_form" :model="departure" :rules="departure_rules" :size="theme.size" v-else status-icon>
			<el-form-item label="离职理由" prop="reason">
				<el-input type="textarea" v-model="departure.reason" :rows="3" placeholder="请输入离职理由" clearable></el-input>
			</el-form-item>
			<el-form-item label="离职日期" prop="separated_at">
				<!-- :picker-options="{disabledDate (time) { return time.getTime() < Date.now(); }}" -->
				<el-date-picker type="date" v-model="departure.separated_at" placeholder="请选择最后到岗打卡签到日期"></el-date-picker>
			</el-form-item>
			<el-form-item prop="attachments">
				<el-upload
					:action="$api.URI_UPLOADS"
					:data="{type: departures_cfg.type}"
					:accept="departures_cfg.ext.map(e => { return '.'+e; }).join(',')"
					:on-success="(r, f, l) => { onSuccess(l, 'attachments'); }"
					:on-remove="(f, l) => { departure = {...departure, attachments: l}; }"
					:file-list="departure.attachments"
					:limit="1">
					<el-button type="text" icon="el-icon-upload">点击上传</el-button>
					<p slot="tip" class="fs12 c9 lh1_5">只能上传 {{departures_cfg.ext.join('/').toUpperCase()}} 文件，且大小不超过 {{departures_cfg.format_size_unit}}<br>交接、证明等离职签字文件</p>
				</el-upload>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('departure_form')">{{departure.who == 'hr' ? '提交办理' : '提交申请'}}</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	import moment from 'moment';

	export default {
		computed: {
			...mapState(['theme']),
			departures_cfg () {
				return this.$utils.uploadConfig('departures');
			},
			is_show () {
				if (this.departure.status == 2) return true;
				if (this.departure.status == 3) return true;
				if (this.departure.status == 4) return true;
				if (!this.departure.id) return false;
				return false;
			}
		},
		props: {
			status: {
				type: Array
			}
		},
		filters: {
			format_at (t) {
				if (!t) return '无';
				return moment(t).format('Y-MM-DD');
			}
		},
		methods: {
			async open (d = {}) {
				this.departure_dialog = true;
				this.departure = {...d};
			},
			onSuccess (list) {
				this.departure = {
					...this.departure,
					attachments: list.map((file) => {
						const { response } = file;
						if (response) {
							const { status, id } = response.result;
							if (status == "success") {
								file.id = id;
							}
						}
						return file;
					})
				};
			},
			async submitDeparture (data) {
				const res = await this.$http.post(this.$api.URI_EMPLOYEES_DEPARTURES, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.$emit('refresh');
						this.departure_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'departure_form':
							this.submitDeparture(this.departure);
						break;
					}
				});
			}
		},
		data() {
			return {
				departure: {},
				departure_dialog: false,
				departure_rules: {
					reason: [{ required: true, message: '必须填写申请理由', trigger: 'blur'}],
					separated_at: [{ required: true, message: '必须选择离职时间', trigger: 'blur'}]
				}
			}
		}
	};
</script>