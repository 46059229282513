<template>
	<div style="height: 56vh;">
		<el-button type="primary" size="mini" icon="el-icon-edit-outline" class="mb10" @click="$refs['show_provision'].open({ material_id: material.id, material_no: material.in_code })" plain>添加</el-button>
		<div class="scroll-wrapper pr0 oa" style="height: 100%;" v-if="supplies.length">
			<div class="df jcsb aic pb5" v-for="item in supplies" :key="item.id">
				<div>
					<p class="fs12" v-if="item.employee">{{item.employee.name}} 创建于 {{item.created_at}} 最后更新于 {{item.updated_at}}</p>
					<h4>{{item.supplier.name}} 提供 {{item.price/100}} 元 {{qualities[item.quality]}} 品质 {{item.no}}</h4>
					<p class="fs12 ccc">{{item.remarks}}</p>
				</div>
				<div>
					<el-button type="text" @click="$refs['show_provision'].open({ ...item, material_id: material.id, material_no: material.in_code })">编辑</el-button>
					<el-popconfirm title="确定要删除这条记录吗？" class="mt5" @confirm="onDelete(item)">
						<el-button slot="reference" type="text">删除</el-button>
					</el-popconfirm>
				</div>
			</div>
		</div>
		<show-provision ref="show_provision" :qualities="qualities" :suppliers="suppliers" @refresh="getProvisions(params)"></show-provision>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import showProvision from '../provisions/show';

	export default {
		components: {
			showProvision
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		props: {
			material: {
				type: Object
			}
		},
		methods: {
			async onDelete (r) {
				const res = await this.$http.get(this.$api.URI_MATERIALS_SUPPLIERS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getProvisions(this.params);
				this.$message.success(msg);
			},
			async getProvisions (p, l = false) {
				const res = await this.$http.get(this.$api.URI_MATERIALS_SUPPLIERS, {params: p, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.supplies = result.data;
				this.qualities = result.qualities;
				this.suppliers = result.suppliers;
				this.params = {
					...p,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				qualities: [],
				suppliers: [],
				supplies: [],
				params: {
					perPage: 10
				}
			}
		},
		mounted () {
			if (!this.material) return false;
			const { id: material_id } = this.material;
			this.getProvisions({...this.params, material_id});
		}
	};
</script>