<template>
	<div class="df fdc bcf1 oh" style="height: 100%;">
		<general-header title="无权访问"></general-header>
		<div class="f1 oa navbar">
			<el-result icon="warning" title="非法闯入后台" subTitle="请不要多次尝试，否则系统自动锁定账户 OR 联系系统管理员Email：13243877782@163.com">
				<template slot="icon">
					<img style="width: 400px" src="@/assets/noaccess.svg" alt="">
				</template>
				<template slot="extra">
					<el-button type="primary" size="medium" @click="$utils.toRoute('/login')">{{time}}s 后跳转</el-button>
				</template>
			</el-result>
		</div>
		<copy-right></copy-right>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	import GeneralHeader from '@/components/general-header';
	import CopyRight from '@/components/general-copyright';

	export default {
		components: {
			GeneralHeader,
			CopyRight
		},
		data () {
			return {
				timer: null,
				time: 10
			}
		},
		methods: {
			...mapActions(['logout'])
		},
		created () {
			this.logout();
			this.timer = setInterval(() => {
				if (this.time === 1) {
					this.$utils.toRoute('/login');
				}
				this.time--
			}, 1000);
		},
		beforeDestroy() {
			clearInterval(this.timer);
			this.timer = null;
		}
	}
</script>
