<template>
	<div class="p20 f1 df fdc pb0" style="border-radius: 15px 15px 0 0; box-shadow: 0 0 10px 0px #ccc;">
		<slot name="table"></slot>
		<div class="df aic jcsb">
			<slot name="footer"></slot>
			<div class="c9 fs14" style="width: 100px;">管理系统 v1.0.1</div>
		</div>
		<slot name="other"></slot>
	</div>
</template>


<script>
	export default {
		props: {

		},
		data () {
			return {

			}
		},
		mounted () {

		},
		methods: {

		}
	}
</script>