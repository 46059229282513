<template>
	<div>
		<el-form :inline="true" :model="params" :size="theme.size">
			<el-form-item>
				<el-input v-model="params.keywords" placeholder="请输入姓名/手机/邮箱/关键词" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-select v-model="params.app" placeholder="所属公司" filterable clearable>
					<el-option v-for="app in apps" :key="app.id" :label="app.type_name+'@'+app.name" :value="app.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getUsers({ ...params, page: 1})">查询</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="users" :max-height="theme.height - 310" :size="theme.size">
			<el-table-column label="ID" prop="id" width="40"></el-table-column>
			<el-table-column label="头像" width="80">
				<el-image slot-scope="scope" class="tac fs30" style="width: 50px; height: 50px; line-height: 50px;" :src="scope.row.avatar">
					<i slot="error" class="el-icon-picture-outline"></i>
				</el-image>
			</el-table-column>
			<el-table-column label="基本信息" prop="name">
				<template slot-scope="scope">
					<p v-if="scope.row.name">{{scope.row.name}} <span v-if="scope.row.user"><i class="el-icon-postcard"></i> {{scope.row.user.name}}</span></p>
					<p v-if="scope.row.department">{{scope.row.department.name}} <span v-if="scope.row.title"><i class="el-icon-suitcase"></i> {{scope.row.title}}</span></p>
				</template>
			</el-table-column>
			<el-table-column label="联系方式">
				<template slot-scope="scope">
					<p v-if="scope.row.mobile"><i class="el-icon-mobile-phone"></i> {{scope.row.mobile}}</p>
					<p v-if="scope.row.email"><i class="el-icon-message"></i> {{scope.row.email}}</p>
				</template>
			</el-table-column>
			<el-table-column label="维护时间" width="240">
				<template slot-scope="scope">
					<p v-if="scope.row.updated_at">更新于 {{scope.row.updated_at}}</p>
					<p v-if="scope.row.created_at">注册于 {{scope.row.created_at}}</p>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			@size-change="(v) => { getUsers({ ...params, page: 1, perPage: v}) }"
			@current-change="(v) => { getUsers({ ...params, page: v}) }"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			background>
		</el-pagination>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			async getUsers (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_DINGTALK_USERS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.users = result.data;
				this.apps = result.apps;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				users: [],
				apps: [],
				params: {
					perPage: 10,
				}
			}
		},
		created () {
			this.getUsers(this.params, true);
		}
	};
</script>