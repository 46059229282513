<template>
	<el-dialog width="480px" :title="operate_goods.id ? '修改产品' : '添加产品'" :visible.sync="goods_dialog" :fullscreen="theme.width <= 480" :close-on-click-modal="false" @closed="$refs['goods_form'].resetFields(); operate_goods = {}" append-to-body destroy-on-close>
		<el-form ref="goods_form" label-width="100px" style="max-height: 60vh;" class="scroll-wrapper pr10 oa"  :model="operate_goods" :size="theme.size">
			<el-form-item label="类型">
				<el-select v-model="operate_goods.category_id" @change="(e) => { allGoods({action: 'all', shop_id: operate_goods.shop_id, category_id: e}); operate_goods = {...operate_goods, number: ''} }" placeholder="请选择产品编码" filterable clearable>
					<el-option v-for="g in categories" :key="g.id" :label="g.name" :value="g.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="分类" prop="number" :rules="[{ required: true, message: '请选择产品', trigger: ['blur', 'change'] }, { validator: checkGoods, trigger: ['blur', 'change'] }]">
				<!-- <el-input placeholder="请选择产品" v-model="operate_goods.number" clearable>
					<template slot="append" v-if="operate_goods.id || operate_goods.goods">{{(operate_goods.shop_price || operate_goods.goods.shop_price) | format_price}} 元</template>
				</el-input> -->
				<el-select v-model="operate_goods.number" placeholder="请选择产品编码" filterable clearable>
					<!-- <el-option
						v-for="g in products"
						:key="g.number"
						:label="(g.category ? g.category.name+'_' : '')+(g.brand ? g.brand.name+'_' : '')+(g.goods_type ? g.goods_type.name+'_' : '')+g.title+'#'+g.number+'￥'+(g.shop_price/100)"
						:value="g.number">
					</el-option> -->
					<el-option
						v-for="g in products"
						:key="g.number"
						:label="(g.brand ? g.brand.name+'/' : '')+(g.goods_type ? g.goods_type.name : '')+g.title+'￥'+(g.shop_price/100)"
						:value="g.number">
					</el-option>
				</el-select>
				<div class="dib ml10" v-if="operate_goods.id || operate_goods.goods">{{(operate_goods.shop_price || operate_goods.goods.shop_price) | format_price}} 元</div>
			</el-form-item>
			<template v-if="operate_goods.id || operate_goods.goods">
				<el-form-item
					v-for="t_attr in operate_goods.goods.type_attributes"
					:prop="'checkbox_attributed.'+t_attr.id"
					:label="t_attr.name"
					:key="t_attr.id"
					:rules="t_attr.multiple && t_attr.required ? [
						{ required: t_attr.required, message: '请选择必选'+t_attr.name, trigger: 'blur' },
						{type: 'array', min: t_attr.minimum, message: '最少选择'+t_attr.minimum+'项'}
					] : [{ required: t_attr.required, message: '请选择必选'+t_attr.name, trigger: 'blur' }]"
				>
					<el-checkbox-group v-model="operate_goods.checkbox_attributed[t_attr.id]" :max="t_attr.cross || !t_attr.multiple ? 1 : (t_attr.maximum || 999999)">
						<el-checkbox v-for="g_attr in t_attr.goods_attributes" :key="g_attr.id" :label="g_attr.id" :disabled="!t_attr.cross && !g_attr.stock">{{g_attr.name}} <template v-if="!t_attr.cross"> #{{g_attr.number}} +{{g_attr.shop_price/100}} 剩{{g_attr.stock}}</template></el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</template>
			<el-form-item v-for="(domain, index) in operate_goods.waiters" :label="'服务老师' + (index + 1)" :key="domain.key" required>
				<el-form-item label-width="0" :prop="'waiters.' + index + '.type'" :rules="{required: true, message: '服务' + (index + 1) + '类型不能为空', trigger: 'blur'}">
					<el-select v-model="domain.type" :placeholder="'请选择服务' + (index + 1) + '类型'" filterable clearable>
						<el-option v-for="(waiter, w) in waiters" :key="waiter" :label="waiter" :value="w"></el-option>
					</el-select>
					<el-button @click.prevent="removeDomain(domain)" class="ml10">删除</el-button>
				</el-form-item>
				<el-form-item label-width="0" :prop="'waiters.' + index + '.employee_id'" :rules="{required: true, message: '服务' + (index + 1) + '老师不能为空', trigger: 'blur'}" style="margin-bottom: 0;">
					<el-select v-model="domain.employee_id" :placeholder="'请选择服务' + (index + 1) + '老师'" filterable clearable>
						<el-option v-for="e in employees" :key="e.id" :label="e.name" :value="e.id"></el-option>
					</el-select>
				</el-form-item>
			</el-form-item>
			<el-form-item label="数量" required>
				<el-input-number v-model="operate_goods.quantity" :min="1" :max="operate_goods.maximum || 999999999" label="数量"></el-input-number>
				<p v-if="operate_goods.cross_attribute"> {{operate_goods.cross_attribute.name}} #{{operate_goods.cross_attribute.number}} 剩 {{operate_goods.cross_attribute.stock}} </p>
			</el-form-item>
			<!-- 当产品重量大于0时为实物需要提交收货地址 -->
			<template v-if="!operate_goods.id && operate_goods.weight">
				<el-form-item label="联系人" prop="linkman">
					<el-input v-model="operate_goods.linkman" placeholder="请输入联系人" autocomplete="off" clearable></el-input>
				</el-form-item>
				<el-form-item label="手机号码" prop="mobile">
					<el-input v-model="operate_goods.mobile" placeholder="请输入手机号码" autocomplete="off" clearable></el-input>
				</el-form-item>
				<el-form-item label="地址区域" prop="regions">
					<el-cascader style="width: 100%;" placeholder="请选择联系地址区域" v-model="operate_goods.regions" :options="tree_regions" :props="{expandTrigger: 'hover', value: 'id', label: 'name'}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item label="地址详情" prop="address">
					<el-input v-model="operate_goods.address" placeholder="请输入联系地址详情" autocomplete="off" clearable></el-input>
				</el-form-item>
			</template>
			<el-form-item>
				<el-button type="primary" @click="submitForm('goods_form')">提交{{operate_goods.id ? '保存' : '创建'}}</el-button>
				<el-button @click="addDomain">新增服务</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		props: {
			id: {
				type: Number
			}
		},
		computed: {
			...mapState(['theme', 'regions', 'goods']),
			tree_regions () {
				return this.$utils.buildTree(this.regions);
			},
			categories () {
				return this.goods.categories || [];
			}
		},
		filters: {
			format_price (value) {
				return (value/100).toFixed(2);
			}
		},
		watch: {
			'operate_goods.checkbox_attributed': {
				handler(n) {
					const { goods, quantity } = this.operate_goods;
					if (!goods) return false;
					const { type_attributes, goods_attributes } = goods;
					var maximum = 999999999;
					var cross_id = [];
					var attr_selected = [];
					var cross_attribute = null;
					if (!goods_attributes) return false;
					type_attributes.map((t) => {
						if (t.cross) {
							cross_id = [...cross_id, ...n[t.id]];
						} else {
							attr_selected = [...attr_selected, ...n[t.id]];
							t.goods_attributes.map((g) => {
								// 取最小库存属性
								if (attr_selected.indexOf(g.id) >= 0 && maximum > g.stock) {
									maximum = g.stock;
								}
							});
						}
					});
					goods_attributes.map((g) => {
						if (g.attributes === cross_id.join('_')) {
							cross_attribute = g;
							attr_selected.push(g.id);
							// 取最小库存属性
							if (maximum > g.stock) {
								maximum = g.stock;
							}
						}
					});
					this.operate_goods.maximum = maximum;
					this.operate_goods.quantity = quantity > maximum ? maximum : quantity;
					this.operate_goods.attr_selected = attr_selected;
					this.operate_goods.cross_attribute = cross_attribute;
					// 禁止使用 {...this.operate_goods} 否则出现无限循环
				},
				deep: true,  // 是否深度监听
				immediate: true  // 页面加载立即执行
			}
		},
		methods: {
			async open (r = {}) {
				if (r.number) {
					const res = await this.$http.get(this.$api.URI_ORDERS_GOODS, {params: {action: 'goods', goods_no: r.number, shop_id: r.shop_id}, headers: {loading: true}});
					const { code, result } = res.data;
					if (!code) {
						r = this.initGoods(r, result);
					}
				}
				// 加载所有产品
				this.allGoods({action: 'all', shop_id: r.shop_id});
				this.operate_goods = { ...r, quantity: r.quantity || 1, waiters: [] };
				this.goods_dialog = true;
			},
			async allGoods (params) {
				const res = await this.$http.get(this.$api.URI_GOODS, {params, headers: {loading: true}});
				const { result } = res.data;
				this.waiters = result.waiters;
				this.products = result.products;
				this.employees = result.employees;
			},
			initGoods (a, b) {
				const { goods_type, goods_attributes } = b;
				const default_selected = a.attributes ? a.attributes.map((a) => { return a.id; }) : [];
				goods_attributes.forEach((g) => {
					if (default_selected.indexOf(g.id) >= 0) {
						g.attributes.split('_').map((a) => {
							default_selected.push(+a);
						});
					}
				});
				var type_attributes = goods_type ? goods_type.type_attributes : [];
				type_attributes = [ ...type_attributes, ...b.type_attributes ];
				var checkbox_attributed = {};
				type_attributes.forEach((t) => {
					const attributed = [];
					t.goods_attributes.forEach((g) => {
						if (default_selected.indexOf(g.id) >= 0) {
							attributed.push(g.id);
						}
					});
					checkbox_attributed[t.id] = attributed;
				});
				a.goods = {...b, type_attributes};
				a.checkbox_attributed = checkbox_attributed;
				return { ...a };
			},
			async checkGoods (rule, value, callback) {
				const { number: goods_no, shop_id, goods_id } = this.operate_goods;
				const res = await this.$http.get(this.$api.URI_ORDERS_GOODS, {params: {action: 'goods', goods_no, shop_id}, headers: {loading: true}});
				const { code, msg, result } = res.data;
				if (code) return callback(new Error(msg));
				if (goods_id != result.id) {
					this.operate_goods = this.initGoods(this.operate_goods, result);
				}
				return callback();
			},
			submitForm (r) {
				this.$refs[r].validate(async (valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (r) {
						case 'goods_form':
							// console.log(this.operate_goods);
							this.submitOrderGoods(this.operate_goods, () =>{
								this.$emit('refresh');
								this.goods_dialog = false;
							});
						break;
					}
				});
			},
			async submitOrderGoods (data, onClose) {
				const res = await this.$http.post(this.$api.URI_ORDERS_GOODS, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({ message, onClose });
			},
			removeDomain(item) {
				var index = this.operate_goods.waiters.indexOf(item)
				if (index !== -1) {
					this.operate_goods.waiters.splice(index, 1)
				}
			},
			addDomain() {
				this.operate_goods.waiters.push({
					type: '',
					employee_id: '',
					key: Date.now()
				});
			}
		},
		data() {
			return {
				waiters: [],
				products: [],
				employees: [],
				goods_dialog: false,
				operate_goods: {}
			}
		}
	};
</script>