<template>
	<div style="height: 100%;">
		<main-table-a>
			<el-form slot="header" :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-select v-model="params.goods_type_id" placeholder="请选择类型" filterable clearable>
						<el-option v-for="type in goods_types" :key="type.id" :label="type.name" :value="type.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getPerformancesRates({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<el-table slot="table" class="scroll-wrapper absolute" height="100%" :data="rates" :size="theme.size">
				<el-table-column label="业务岗位" prop="shop_position.name">
					<el-link slot-scope="scope" type="primary" :disabled="!$utils.update('positions')" @click="$refs['show_dialog'].open(scope.row.shop_position)">{{scope.row.shop_position.name}}</el-link>
				</el-table-column>
				<el-table-column label="固定工资" :formatter="({shop_position}) => { return (shop_position.base_wage+shop_position.post_wage)/100+' 元'; }"></el-table-column>
				<el-table-column label="产品类型" prop="goods_type.name"></el-table-column>
				<el-table-column label="绩效提点" :formatter="({ratio}) => { return ratio/100+' %'; }"></el-table-column>
				<el-table-column label="更新时间" prop="updated_at" min-width="180"></el-table-column>
				<el-table-column label="创建时间" prop="created_at" min-width="180"></el-table-column>
			</el-table>
			<el-pagination
				slot="footer"
				:page-sizes="$config.PAHE_SIZES"
				:page-size="params.perPage"
				:current-page="params.page"
				:total="params.total"
				@size-change="(v) => { getPerformancesRates({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getPerformancesRates({ ...params, page: v }) }"
				background>
			</el-pagination>
		</main-table-a>
		<!-- <show-position ref="show_dialog" @refresh="getPerformancesRates(params)"></show-position> -->
	</div>
</template>


<script>
	import { mapState } from 'vuex';
	// import showPosition from '@/pages/hrm/positions/show';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA,
			// showPosition
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			async getPerformancesRates (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_PERFORMANCES_RATES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.rates = result.data;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				rates: [],
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getPerformancesRates(this.params, true);
		}
	};
</script>