<template>
	<el-dialog width="480px" :title="user.id ? '编辑账户' : '添加账户'" :visible.sync="user_dialog" @closed="$refs.user_form.resetFields(); user = {};">
		<el-form :model="user" :rules="user_rules" :size="theme.size" ref="user_form" label-width="60px" status-icon>
			<el-form-item label="名称" prop="name" required>
				<el-input v-model="user.name" autocomplete="off" placeholder="请输入账户名称"></el-input>
			</el-form-item>
			<el-form-item label="手机" prop="mobile">
				<el-input v-model="user.mobile" autocomplete="off" placeholder="请输入手机号码"></el-input>
			</el-form-item>
			<el-form-item label="邮箱" prop="email">
				<el-input v-model="user.email" autocomplete="off" placeholder="请输入电子邮箱"></el-input>
			</el-form-item>
			<el-form-item label="密码" prop="password">
				<el-input v-model="user.password" autocomplete="off" placeholder="请输入登录密码"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('user_form')">{{user.id ? '提交保存' : '提交创建'}}</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';
	
	export default {
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			open (r) {
				this.user = { ...r };
				this.user_dialog = true;
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error('提交表单有误！');
					this.submitUser(this.user);
				});
			},
			async submitUser (data) {
				const res = await this.$http.post(this.$api.URI_USERS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.user_dialog = false;
						this.$emit('refresh');
					}
				});
			}
		},
		data () {
			return {
				user: {},
				user_dialog: false,
				user_rules: {
					name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
				}
			}
		}
	};
</script>