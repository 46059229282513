<template>
	<div>
		<el-form :inline="true" :model="params" :size="theme.size">
			<el-form-item label="关键词">
				<el-input v-model="params.keywords" placeholder="请输入应用名称关键词" clearable></el-input>
			</el-form-item>
			<el-form-item label="应用类型">
				<el-select v-model="params.type" placeholder="应用类型" filterable clearable>
					<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="商户">
				<el-select v-model="params.merchant_id" placeholder="商户" filterable clearable>
					<el-option v-for="merchant in merchants" :key="merchant.id" :label="merchant.name+merchant.mchid" :value="merchant.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getWechat({ ...params, page: 1})">查询</el-button>
				<el-button type="primary" :disabled="!$utils.create('wechat/apps')" @click="drawer = true">添加应用</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="apps" :max-height="theme.height - 310" :size="theme.size">
			<el-table-column prop="id" label="ID"></el-table-column>
			<el-table-column prop="name" label="应用名称">
				<el-button slot-scope="scope" type="text" @click="$utils.toRoute('/wechat?appid='+scope.row.appid)" :disabled="scope.row.type == 2">{{scope.row.name}}</el-button>
			</el-table-column>
			<el-table-column prop="type_name" label="应用类型">
				<template slot-scope="scope">{{types[scope.row.type]}}</template>
			</el-table-column>
			<el-table-column prop="wechat_merchant_name" label="关联商户">
				<template slot-scope="scope">
					<template v-if="scope.row.type">{{scope.row.wechat_merchant?scope.row.wechat_merchant.name:'未关联'}}</template>
					<template v-else>不支持</template>
				</template>
			</el-table-column>
			<el-table-column prop="originid" label="原始ID"></el-table-column>
			<el-table-column prop="appid" label="应用ID"></el-table-column>
			<el-table-column prop="created_at" label="创建时间" width="180"></el-table-column>
			<el-table-column fixed="right" label="更多操作" width="120">
				<template slot-scope="scope">
					<!-- <el-button v-if="scope.row.type != 2" type="text">
						<router-link :to="'/system/wechat/'+scope.row.appid">配置</router-link>
					</el-button> -->
					<el-button type="text" :disabled="!$utils.update('wechat/apps')" @click="createForm = {...scope.row}; drawer = true;">更新</el-button>
					<el-button type="text" @click="onDelete(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getWechat({ ...params, page: 1, perPage: v}) }"
			@current-change="(v) => { getWechat({ ...params, page: v}) }"
			background></el-pagination>
		<el-dialog :title="createForm.id?'修改应用':'添加应用'" :visible.sync="drawer">
			<div class="pl15 pr15">
				<el-form status-icon :model="createForm" :size="theme.size" ref="createForm" label-width="80px">
					<el-form-item label="应用名称" prop="name" required>
						<el-input v-model="createForm.name" autocomplete="off" placeholder="请输入应用名称"></el-input>
					</el-form-item>
					<el-form-item label="应用类型" prop="type" required>
						<el-select v-model="createForm.type" placeholder="应用类型" filterable>
							<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="原始ID" prop="originid" required>
						<el-input v-model="createForm.originid" autocomplete="off" placeholder="请输入应用ID"></el-input>
					</el-form-item>
					<el-form-item label="应用ID" prop="appid" required>
						<el-input v-model="createForm.appid" autocomplete="off" placeholder="请输入应用ID"></el-input>
					</el-form-item>
					<el-form-item label="应用密钥" prop="secret" required>
						<el-input v-model="createForm.secret" autocomplete="off" placeholder="请输入应用密钥"></el-input>
					</el-form-item>
					<el-form-item label="微信商户" prop="wechat_merchant_id" required v-if="createForm.type">
						<el-select v-model="createForm.wechat_merchant_id" placeholder="微信商户" filterable clearable>
							<el-option v-for="merchant in merchants" :key="merchant.id" :label="merchant.name+merchant.mchid" :value="merchant.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button @click="resetForm('createForm')">取 消</el-button>
						<el-button type="primary" @click="submitForm('createForm')">确 定</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			onDelete (r) {
				this.$confirm('确定要删除【'+r.name+'】应用吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_WECHAT_APPS, {params: {action: 'destroy', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getWechat(this.params)
						}
					});
				})
			},
			resetForm (ref) {
				this.drawer = false;
				this.$refs[ref].resetFields();
				this.createForm = {
					type: 0
				};
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					this.submitWechatApp(this.createForm);
				})
			},
			async submitWechatApp (data) {
				const res = await this.$http.post(this.$api.URI_WECHAT_APPS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 2000,
					message: msg,
					onClose: () => {
						this.drawer = false;
						this.getWechat(this.params);
					}
				});
			},
			async getWechat (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_WECHAT_APPS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.apps = result.data;
				this.types = result.types;
				this.merchants = result.merchants;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				apps: [],
				types: [],
				merchants: [],
				drawer: false,
				createForm: {
					type: 0,
				},
				params: {
					perPage: 10,
				}
			}
		},
		created () {
			this.getWechat(this.params, true);
		}
	};
</script>