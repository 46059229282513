<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keyword" placeholder="会员名称" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="门店" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getMembers({ ...params, page: 1})" plain>查询</el-button>
					<el-button type="primary" @click="create_dialog = true" :disabled="!$utils.create('members')">添加会员</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table ref="memberTable" class="scroll-wrapper absolute" height="100%" :data="members" :size="theme.size">
					<el-table-column label="名称">
						<el-link slot-scope="scope" type="primary" @click="handleEdit(scope.row)" :disabled="!$utils.update('members')">{{scope.row.name}}</el-link>
					</el-table-column>
					<el-table-column prop="shop.name" label="门店" v-if="!admin_user.shop_id"></el-table-column>
					<el-table-column label="折扣" :formatter="({rate}) => { return rate + '%'; }"></el-table-column>
					<el-table-column prop="users_count" label="用户数量"></el-table-column>
					<el-table-column label="默认">
						<el-switch slot-scope="scope" :active-value="1" :inactive-value="0" active-text="是" inactive-text="否" v-model="scope.row.new_user" @change="submitMember(scope.row)" :readonly="!$utils.update('members')"></el-switch>
					</el-table-column>
					<el-table-column prop="updated_at" label="更新时间" min-width="160"></el-table-column>
					<el-table-column prop="created_at" label="创建时间" min-width="160"></el-table-column>
					<el-table-column fixed="right" label="操作" min-width="60">
						<template slot-scope="scope">
							<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore('members')" v-if="scope.row.deleted_at">恢复</el-button>
							<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete('members')" v-else>删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getMembers({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getMembers({ ...params, page: v }) }"
			background>
		</el-pagination>
		<template slot="other">
			<el-dialog title="添加会员" width="320px" :visible.sync="create_dialog" @closed="resetForm('member_form')">
				<el-form ref="member_form" label-width="100px" :model="member" :rules="rules" :size="theme.size" status-icon>
					<el-form-item label="会员名称" prop="name">
						<el-input v-model="member.name" autocomplete="off" placeholder="请输入会员名称"></el-input>
					</el-form-item>
					<el-form-item label="所属门店" prop="shop_id" v-if="!admin_user.shop_id">
						<el-select v-model="member.shop_id" placeholder="门店" filterable clearable>
							<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="折扣率" prop="rate">
						<el-input-number placeholder="请输入会员折扣率" v-model="member.rate" :precision="2" :step="0.01" :min="0"></el-input-number>
					</el-form-item>
					<el-form-item label="默认" prop="new_user">
						<el-switch v-model="member.new_user" :active-value="1" :inactive-value="0"></el-switch>
					</el-form-item>
					<el-form-item>
						<el-button @click="resetForm('member_form')">取 消</el-button>
						<el-button type="primary" @click="submitForm('member_form')">确 定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</template>
	</main-table-a>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			async submitMember (data) {
				const res = await this.$http.post(this.$api.URI_MEMBERS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 2000,
					message: msg,
					onClose: () => {
						if (this.create_dialog) {
							this.create_dialog = false;
							this.getMembers(this.params);
						}
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("提交表单有误！")
					this.submitMember(this.member);
				})
			},
			resetForm (ref) {
				this.create_dialog = false;
				this.$refs[ref].resetFields();
				this.member = {};
			},
			handleEdit (r) {
				this.create_dialog = true;
				this.member = {...r};
			},
			onDelete (r) {
				const { id, name, user_count } = r;
				if (r.user_count) return this.$message.error('不能删除！【'+name+'】会员下还有'+user_count+'个用户。');
				this.$confirm('确定要删除【'+name+'】会员吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_MEMBERS, {params: {action: 'delete', id: id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message.success(msg);
					this.getMembers(this.params);
				})
			},
			async getMembers (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_MEMBERS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.members = result.data;
				this.shops = result.shops;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				create_dialog: false,
				shops: [],
				members: [],
				member: {
					new_user: 0,
					rate: 100
				},
				rules: {
					name: [{ required: true, message: '请输入会员名称', trigger: 'blur' }],
				},
				params: {
					perPage: 10
				}
			}
		},
		created () {
			this.getMembers(this.params, true);
		}
	};
</script>