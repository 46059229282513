<template>
	<el-dialog width="420px" :title="payment.id ? ('No.' + payment.no + ' 付款详情') : '申请付款'" :fullscreen="theme.width < 420" :visible.sync="payment_dialog" @closed="payment = {}" append-to-body destroy-on-close>
		<div class="pr" v-if="is_show">
			<el-descriptions style="margin-top: -20px;" :labelStyle="{width: '70px'}" :column="2" :size="theme.size" border>
				<el-descriptions-item label="申请人">{{payment.employee ? payment.employee.name : '/'}}</el-descriptions-item>
				<el-descriptions-item label="金额"><b class="cff0000">{{payment.amount}}</b></el-descriptions-item>
				<el-descriptions-item label="申请时间">{{payment.created_at}}</el-descriptions-item>
				<el-descriptions-item label="付款方式">{{ways[payment.way]}}</el-descriptions-item>
				<el-descriptions-item :label="'关联'+types[payment.common_type]" :span="2">{{payment.common ? payment.common.no : '无'}}</el-descriptions-item>
				<el-descriptions-item label="付款企业" :span="2">
					<template v-if="payment.company">
						<h2>{{payment.company.name}}</h2>
						<div class="fs12 lh1_5">
							<p>{{payment.company.code}}</p>
							<p>{{payment.company.address}}</p>
							<p>{{payment.company.mobile}}</p>
							<p>{{payment.company.bank}}</p>
							<p>{{payment.company.account}}</p>
						</div>
					</template>
					<template v-else>不限</template>
				</el-descriptions-item>
				<el-descriptions-item label="收款全称" :span="2">{{payment.name}}</el-descriptions-item>
				<el-descriptions-item label="开户银行" :span="2">{{payment.bank}}</el-descriptions-item>
				<el-descriptions-item label="收款账户" :span="2">{{payment.account}}</el-descriptions-item>
				<el-descriptions-item label="备注说明" :span="2">{{payment.remarks}}</el-descriptions-item>
				<el-descriptions-item label="申请凭证" :span="2">
					<div class="attachment" v-for="attachment in payment.attachments" :key="attachment.id" @click="openFile(attachment.url)"><i class="el-icon-document"></i> {{attachment.name}}</div>
				</el-descriptions-item>
				<el-descriptions-item label="付款凭证" :span="2">
					<el-form style="width: 280px;" ref="payment_form" :model="payment" :rules="payment_rules" :size="theme.size" v-if="upload_credentials" status-icon>
						<el-form-item prop="credentials">
							<el-upload
								:action="$api.URI_UPLOADS"
								:data="{type: contract_attach_cfg.type}"
								:accept="contract_attach_cfg.ext.map(e => { return '.'+e; }).join(',')"
								:on-success="(r, f, l) => { onSuccess(l, 'credentials'); }"
								:on-remove="(f, l) => { payment = {...payment, credentials: l}; }"
								:on-preview="(f) => { openFile(f.url); }"
								:before-upload="beforeContractAttach"
								:file-list="payment.credentials"
								:limit="1">
								<el-button type="text" icon="el-icon-upload">点击上传</el-button>
								<p slot="tip" class="el-upload__tip lh1_5">
									财务上传付款凭证 {{contract_attach_cfg.ext.join('/').toUpperCase()}} 格式，且大小不超过 {{contract_attach_cfg.format_size_unit}}
								</p>
							</el-upload>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('payment_form')">确认付款</el-button>
						</el-form-item>
					</el-form>
					<template v-else-if="payment.credentials && payment.credentials.length">
						<div class="attachment" v-for="attachment in payment.credentials" :key="attachment.id" @click="openFile(attachment.url)"><i class="el-icon-document"></i> {{attachment.name}}</div>
					</template>
					<template v-else>无</template>
				</el-descriptions-item>
				<el-descriptions-item label="发票凭证" :span="2">
					<el-form style="width: 280px;" ref="payment_form" :model="payment" :rules="payment_rules" :size="theme.size" v-if="upload_documents" status-icon>
						<el-form-item prop="documents">
							<el-upload
								:action="$api.URI_UPLOADS"
								:data="{type: contract_attach_cfg.type}"
								:accept="contract_attach_cfg.ext.map(e => { return '.'+e; }).join(',')"
								:on-remove="(f, l) => { payment = {...payment, documents: l}; }"
								:on-success="(r, f, l) => { onSuccess(l, 'documents'); }"
								:on-preview="(f) => { openFile(f.url); }"
								:before-upload="beforeContractAttach"
								:file-list="payment.documents"
								:limit="1">
								<el-button type="text" icon="el-icon-upload">点击上传</el-button>
								<p slot="tip" class="el-upload__tip lh1_5">
									财务上传发票凭证 {{contract_attach_cfg.ext.join('/').toUpperCase()}} 格式，且大小不超过 {{contract_attach_cfg.format_size_unit}}
								</p>
							</el-upload>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('payment_form')">确认补票</el-button>
						</el-form-item>
					</el-form>
					<template v-else-if="payment.documents && payment.documents.length">
						<div class="attachment" v-for="attachment in payment.documents" :key="attachment.id" @click="openFile(attachment.url)"><i class="el-icon-document"></i> {{attachment.name}}</div>
					</template>
				</el-descriptions-item>
			</el-descriptions>
			<div class="pa fs38 fwb" style="color: rgba(255, 0, 0, 0.2); top: 60px; left: 50%; transform: translate(-50%, -50%) rotateZ(20deg);" v-if="status[payment.status]">{{status[payment.status]}}</div>
		</div>
		<el-form label-width="100px" ref="payment_form" class="scroll-wrapper oa pr10" style="max-height: 52vh;" :model="payment" :rules="payment_rules" :size="theme.size" v-else status-icon>
			<el-form-item label="金额" prop="amount">
				<el-input-number label="金额" v-model="payment.amount" :precision="2" :step="0.01" :min="0.01"></el-input-number>
			</el-form-item>
			<el-form-item :label="types.length > 1 ? '关联' : types[payment.common_type]" prop="common_no">
				<el-input v-model="payment.common_no" placeholder="请输入流水号。根据财务规定是否选择填写" :readonly="payment.common_id != null" clearable>
					<el-select slot="prepend" style="width: 100px;" v-model="payment.common_type" v-if="types.length > 1" placeholder="关联类型" clearable filterable>
						<el-option v-for="(type, s) in types" :key="s" :label="type" :value="s"></el-option>
					</el-select>
				</el-input>
			</el-form-item>
			<el-form-item label="付款企业" prop="company_id">
				<el-select v-model="payment.company_id" style="width: 100%;" placeholder="请选择付款企业。根据财务规定是否选择填写" clearable filterable>
					<el-option label="不限" :value="0"></el-option>
					<el-option v-for="company in companies" :key="company.id" :label="company.name" :value="company.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="付款方式" prop="way">
				<el-select v-model="payment.way" placeholder="请选择用途" clearable filterable>
					<el-option v-for="(way, s) in ways" :key="s" :label="way" :value="+s"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="收款全称" prop="name">
				<el-input v-model="payment.name" placeholder="请输入收款人全称" clearable></el-input>
			</el-form-item>
			<el-form-item label="收款账户" prop="account" v-if="payment.way != 1">
				<el-input v-model="payment.account" placeholder="请输入收款账户" clearable></el-input>
			</el-form-item>
			<el-form-item label="开户银行" prop="bank" v-if="payment.way == 2">
				<el-input v-model="payment.bank" placeholder="请输入收款账户开户行。非银行账户填写收款平台名称" clearable></el-input>
			</el-form-item>
			<el-form-item label="备注说明" prop="remarks">
				<el-input v-model="payment.remarks" type="textarea" placeholder="请输入备注说明" rows="3" maxlength="255" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="申请凭证" prop="attachments">
				<el-upload
					:action="$api.URI_UPLOADS"
					:data="{type: contract_attach_cfg.type}"
					:accept="contract_attach_cfg.ext.map(e => { return '.'+e; }).join(',')"
					:on-success="(r, f, l) => { onSuccess(l, 'attachments'); }"
					:on-remove="(f, l) => { payment = {...payment, attachments: l}; }"
					:on-preview="(f) => { openFile(f.url); }"
					:before-upload="beforeContractAttach"
					:file-list="payment.attachments"
					:limit="1">
					<el-button type="text" icon="el-icon-upload">点击上传</el-button>
					<p slot="tip" class="el-upload__tip lh1_5">
						使用 Word 办公软件整理所有凭证<br />另存为 {{contract_attach_cfg.ext.join('/').toUpperCase()}} 上传，且大小不超过 {{contract_attach_cfg.format_size_unit}}
					</p>
				</el-upload>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('payment_form')">{{payment.id ? '提交保存'  : '提交创建'}}</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<style>
	.attachment {
		cursor: pointer;
		padding: 0 10px;
		border-radius: 5px;
	}

	.attachment:hover {
		color: #c5b388;
		background-color: #f1f1f1;
	}
</style>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme', 'employee']),
			contract_attach_cfg () {
				return this.$utils.uploadConfig('payments');
			},
			is_show () {
				const { status } = this.payment;
				if (status == 2) return true;
				if (status == 4) return true;
				if (status == 5) return true;
				if (status == 6) return true;
				return false;
			},
			upload_credentials () {
				const { status } = this.payment;
				if (!this.$utils.checkAuth(this.$api.URI_EMPLOYEES_PAYMENTS, 'confirmed')) return false;
				if (status != 4) return false;
				return true;
			},
			upload_documents () {
				const { status } = this.payment;
				if (status == 5) return true;
				if (status == 6) return true;
				return false;
			}
		},
		props: {
			types: {
				type: Object
			},
			ways: {
				type: Object
			},
			companies: {
				type: Array
			},
			status: {
				type: Array
			}
		},
		methods: {
			openFile (u) {
				window.open(u);
			},
			async open (payment = {}) {
				if (!this.types[payment.common_type]) return this.$message.error('未知类型');
				if (payment.status == 4 || payment.status == 5 || payment.status == 6) {
					payment.credentials = payment.attachments.filter((a) => a.meta === 1);
					payment.documents = payment.attachments.filter((a) => a.meta === 2);
					payment.attachments = payment.attachments.filter((a) => a.meta === 0);
				}
				this.payment = {...this.payment, ...payment};
				this.payment_dialog = true;
			},
			onSuccess (list, meta) {
				this.payment[meta] =  list.map((file) => {
					const { response } = file;
					if (response) {
						const { status, id } = response.result;
						if (status == "success") {
							file.id = id;
						}
					}
					return file;
				});
			},
			beforeContractAttach (file) {
				const { size, ext, format_size_unit } = this.contract_attach_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传 '+ext.join('/').toUpperCase()+' 凭证不能超过 '+format_size_unit);
				return is;
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					const res = await this.$http.post(this.$api.URI_EMPLOYEES_PAYMENTS, this.payment);
					const { code, msg: message } = res.data;
					if (code != 0) return this.$message.error(message);
					this.$message.success({
						message,
						onClose: () => {
							this.$emit('refresh');
							this.payment_dialog = false;
						}
					});
				});
			},
			// 提供给外部组件使用方法 - begin
			onStatus (r) {
				const { id, employee_id, no, amount, status } = r;
				switch (status) {
					case 1:
					case 3:
						if (!this.$utils.create(this.$api.URI_APPROVALS)) return this.$message.error('暂无提交审核权限！');
						if (this.employee.id != employee_id) return this.$message.error('当前申请人不是你');
						this.$confirm('No.' + no + '<br /><b style="color: #c7000a; font-size: 18px;">￥'+ (amount/100).toFixed(2) + '</b>', '确定要提交付款申请审核吗？', {
							dangerouslyUseHTMLString: true,
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							const res = await this.$http.post(this.$api.URI_APPROVALS, {common_model_id: id, common_model_type: 'App\\Models\\PaymentRequest'});
							const { code, msg: message } = res.data;
							if (code != 0) return this.$message.error(message);
							this.$message.success({
								message,
								onClose: () => {
									this.$emit('refresh');
								}
							});
						});
					break;
					
					default:
						this.open({...r, amount: r.amount/100});
					break;
				}
			},
			onRestore (r) {
				this.$confirm('No.' + r.no + '<br /><b style="color: #c7000a; font-size: 18px;">￥'+ (r.amount/100).toFixed(2) + '</b>', '确定要删除付款申请吗？', {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_EMPLOYEES_PAYMENTS, {params: { action: 'delete', id: r.id }, headers: {loading: true}});
					const { code, msg: message } = res.data;
					if (code != 0) return this.$message.error(message);
					this.$message.success({
						message,
						onClose: () => {
							this.$emit('refresh');
						}
					});
				});
			}
			// 提供给外部组件使用方法 - end
		},
		data() {
			return {
				params: {},
				payment: { common_type: 'App\\Models\\Order' },
				payments: [],
				statuses: [],
				payment_dialog: false,
				payment_rules: {
					amount: [{ required: true, message: '必须填写金额', trigger: 'blur'}],
					way: [{ required: true, message: '必须填写付款方式', trigger: 'blur'}],
					bank: [{ required: true, message: '必须填写开户银行', trigger: 'blur'}],
					name: [{ required: true, message: '必须填写收款人全称', trigger: 'blur'}],
					account: [{ required: true, message: '必须填写收款人账户', trigger: 'blur'}],
				}
			}
		}
	};
</script>