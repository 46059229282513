<template>
	<el-dialog width="420px" :title="expense.id ? ('No.' + expense.no + ' 报销详情') : '申请报销'" :fullscreen="theme.width < 420" :visible.sync="expense_dialog" @closed="expense = {}" append-to-body destroy-on-close>
		<div class="pr" v-if="is_show">
			<el-descriptions style="margin-top: -20px;" :labelStyle="{width: '70px'}" :column="2" :size="theme.size" border>
				<el-descriptions-item label="申请人">{{expense.employee ? expense.employee.name : '/'}}</el-descriptions-item>
				<el-descriptions-item label="金额"><b class="cff0000">{{expense.amount}}</b></el-descriptions-item>
				<el-descriptions-item label="申请时间">{{expense.created_at}}</el-descriptions-item>
				<el-descriptions-item label="用途">{{purposes[expense.purpose] || '/'}}</el-descriptions-item>
				<el-descriptions-item label="报销企业" :span="2">{{expense.company ? expense.company.name : '不限'}}</el-descriptions-item>
				<el-descriptions-item :label="'关联'+types[expense.common_type]" :span="2">{{expense.common ? expense.common.no : '无'}}</el-descriptions-item>
				<el-descriptions-item label="备注说明" :span="2">{{expense.remarks}}</el-descriptions-item>
				<el-descriptions-item label="发票凭证" :span="2">
					<div class="attachment" v-for="attachment in expense.attachments" :key="attachment.id" @click="openFile(attachment.url)">
						<i class="el-icon-document"></i> {{attachment.name}}
					</div>
				</el-descriptions-item>
				<el-descriptions-item label="报销凭证" :span="2">
					<el-form label-width="80px" ref="expense_form" :model="expense" :rules="expense_rules" :size="theme.size" v-if="$utils.checkAuth($api.URI_EMPLOYEES_EXPENSES, 'returned') && (expense.status == 4 || expense.status == 5)" status-icon>
						<el-form-item label="报销凭证" prop="verifications">
							<el-upload
								:action="$api.URI_UPLOADS"
								:data="{type: contract_attach_cfg.type}"
								:accept="contract_attach_cfg.ext.map(e => { return '.'+e; }).join(',')"
								:on-success="(r, f, l) => { onSuccess(l, 'verifications'); }"
								:on-preview="(f) => { openFile(f.url); }"
								:on-remove="(f, l) => { expense = {...expense, verifications: l}; }"
								:before-upload="beforeInvoiceAttach"
								:file-list="expense.verifications"
								:limit="1">
								<el-button type="text" icon="el-icon-upload">点击上传</el-button>
								<p slot="tip" class="el-upload__tip lh1_5">
									{{contract_attach_cfg.ext.join('/').toUpperCase()}} 上传，且大小不超过 {{contract_attach_cfg.format_size_unit}}
								</p>
							</el-upload>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('expense_form')">确认报销</el-button>
						</el-form-item>
					</el-form>
					<template v-else>
						<div class="attachment" v-for="attachment in expense.verifications" :key="attachment.id" @click="openFile(attachment.url)">
							<i class="el-icon-document"></i> {{attachment.name}}
						</div>
					</template>
				</el-descriptions-item>
			</el-descriptions>
			<div class="pa fs38 fwb" style="color: rgba(255, 0, 0, 0.2); top: 60px; left: 50%; transform: translate(-50%, -50%) rotateZ(20deg);" v-if="status[expense.status]">{{status[expense.status]}}</div>
		</div>
		<el-form label-width="80px" ref="expense_form" :model="expense" :rules="expense_rules" :size="theme.size" v-else status-icon>
			<el-form-item label="金额" prop="amount">
				<el-input-number label="金额" v-model="expense.amount" :precision="2" :step="0.01" :min="0.01"></el-input-number>
			</el-form-item>
			<el-form-item label="用途" prop="purpose">
				<el-select v-model="expense.purpose" placeholder="请选择用途" clearable filterable>
					<el-option v-for="(status, s) in purposes" :key="s" :label="status" :value="+s"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item :label="types.length > 1 ? '关联' : types[expense.common_type]" prop="common_no">
				<el-input v-model="expense.common_no" placeholder="请输入流水号。根据财务规定是否选择填写" :readonly="expense.common_id != null" clearable>
					<el-select slot="prepend" style="width: 100px;" v-model="expense.common_type" v-if="types.length > 1" placeholder="关联类型" clearable filterable>
						<el-option v-for="(status, s) in types" :key="s" :label="status" :value="s"></el-option>
					</el-select>
				</el-input>
			</el-form-item>
			<el-form-item label="付款企业" prop="company_id">
				<el-select v-model="expense.company_id" style="width: 100%;" placeholder="请选择付款企业。根据财务规定是否选择填写" clearable filterable>
					<el-option label="不限" :value="0"></el-option>
					<el-option v-for="company in companies" :key="company.id" :label="company.name" :value="company.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="备注说明" prop="remarks">
				<el-input v-model="expense.remarks" type="textarea" placeholder="请输入备注说明" rows="3" maxlength="255" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="发票凭证" prop="attachments">
				<el-upload
					:action="$api.URI_UPLOADS"
					:data="{type: contract_attach_cfg.type}"
					:accept="contract_attach_cfg.ext.map(e => { return '.'+e; }).join(',')"
					:on-success="(r, f, l) => { onSuccess(l, 'attachments'); }"
					:on-preview="(f) => { openFile(f.url); }"
					:on-remove="(f, l) => { expense = {...expense, attachments: l}; }"
					:before-upload="beforeInvoiceAttach"
					:file-list="expense.attachments"
					:limit="1">
					<el-button type="text" icon="el-icon-upload">点击上传</el-button>
					<p slot="tip" class="el-upload__tip lh1_5">
						支持 {{contract_attach_cfg.ext.join('/').toUpperCase()}} 上传，且大小不超过 {{contract_attach_cfg.format_size_unit}}。
						建议参照<el-link type="primary" size="mini" href="/expense_claim_revised.docx">模板</el-link>填写后，另存为 PDF 上传
					</p>
				</el-upload>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('expense_form')">{{expense.id ? '提交保存'  : '提交创建'}}</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<style>
	.attachment {
		cursor: pointer;
		padding: 0 10px;
		border-radius: 5px;
	}

	.attachment:hover {
		color: #c5b388;
		background-color: #f1f1f1;
	}
</style>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme', 'employee']),
			contract_attach_cfg () {
				return this.$utils.uploadConfig('expenses');
			},
			is_show () {
				const { status } = this.expense;
				if (status == 2) return true;
				if (status == 4) return true;
				if (status == 5) return true;
				if (status == 6) return true;
				return false;
			}
		},
		props: {
			types: {
				type: Object
			},
			purposes: {
				type: Object
			},
			companies: {
				type: Array
			},
			status: {
				type: Array
			},
		},
		methods: {
			openFile (u) {
				window.open(u);
			},
			async open (expense = {}) {
				if (!this.types[expense.common_type]) return this.$message.error('未知类型');
				this.expense = {...this.expense, ...expense};
				this.expense_dialog = true;
			},
			openDialog (r) {
				if (r.status == 4 || r.status == 5) {
					r.verifications = r.attachments.filter((a) => a.meta === 1);
					r.attachments = r.attachments.filter((a) => a.meta === 0);
				}
				this.expense = { ...r };
				this.expense_dialog = true;
			},
			onSuccess (list, meta) {
				this.expense[meta] =  list.map((file) => {
					const { response } = file;
					if (response) {
						const { status, id } = response.result;
						if (status == "success") {
							file.id = id;
						}
					}
					return file;
				});
			},
			beforeInvoiceAttach (file) {
				const { size, ext, format_size_unit } = this.contract_attach_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传 '+ext.join('/').toUpperCase()+' 发票凭证不能超过 '+format_size_unit);
				return is;
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					const res = await this.$http.post(this.$api.URI_EMPLOYEES_EXPENSES, this.expense);
					const { code, msg: message } = res.data;
					if (code != 0) return this.$message.error(message);
					this.$message.success({
						message,
						onClose: () => {
							this.$emit('refresh');
							this.expense_dialog = false;
						}
					});
				});
			},
			// 提供给外部组件使用方法 - begin
			onStatus (r) {
				const { id, employee_id, no, amount, attachments, status } = r;
				switch (status) {
					case 1:
					case 3:
						if (!this.$utils.create(this.$api.URI_APPROVALS)) return this.$message.error('暂无提交审核权限！');
						if (this.employee.id != employee_id) return this.$message.error('当前申请人不是你');
						if (!attachments.length) return this.$message.error('未上传发票凭证附件');
						this.$confirm('No.' + no + '<br /><b style="color: #c7000a; font-size: 18px;">￥'+ (amount/100).toFixed(2) + '</b>', '确定要提交费用报销审核吗？', {
							dangerouslyUseHTMLString: true,
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							const res = await this.$http.post(this.$api.URI_APPROVALS, {common_model_id: id, common_model_type: 'App\\Models\\ExpenseClaim'});
							const { code, msg: message } = res.data;
							if (code != 0) return this.$message.error(message);
							this.$message.success({
								message,
								onClose: () => {
									this.$emit('refresh');
								}
							});
						});
					break;

					case 2:
					case 4:
					case 5:
						this.openDialog({...r, amount: r.amount/100});
					break;

					default:
						this.$message.warning('当前状态不可操作');
					break;

				}
			},
			onDelete (r) {
				this.$confirm('No.' + r.no + '<br /><b style="color: #c7000a; font-size: 18px;">￥'+ (r.amount/100).toFixed(2) + '</b>', '确定要删除费用报销吗？', {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_EMPLOYEES_EXPENSES, {params: { action: 'delete', id: r.id }, headers: {loading: true}});
					const { code, msg: message } = res.data;
					if (code != 0) return this.$message.error(message);
					this.$message.success({
						message,
						onClose: () => {
							this.$emit('refresh');
						}
					});
				});
			}
			// 提供给外部组件使用方法 - end
		},
		data() {
			return {
				expense: {
					common_type: 'App\\Models\\Order'
				},
				expenses: [],
				expense_dialog: false,
				params: {
					perPage: 10,
				},
				expense_rules: {
					amount: [{ required: true, message: '必须填写金额', trigger: 'blur'}],
					purpose: [{ required: true, message: '必须选择用途', trigger: 'blur'}],
					attachments: [{ required: true, type: 'array', message: '必须上传凭证附件', trigger: 'blur'}],
				}
			}
		}
	};
</script>