<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="名称/联系人/手机号/地址/备注" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="所属门店" filterable multiple collapse-tags>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.grade" placeholder="请选择级别" style="width: 100%;" filterable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="(grade, g) in grades" :key="g" :label="grade" :value="g">
							<span>{{grade}}</span>
							<span class="c9 fs12">{{counts['grade_'+g] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getSuppliers(params)">查询</el-button>
					<el-button type="primary" @click="create_drawer = true" :disabled="!$utils.create($api.URI_SUPPLIERS)">添加供应商</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table class="scroll-wrapper absolute" height="100%" :data="suppliers" :size="theme.size" @sort-change="sortChange">
					<el-table-column label="创建人" min-width="100" prop="employee_name"></el-table-column>
					<el-table-column label="名称" min-width="100">
						<template slot-scope="scope">
							<el-link type="primary" @click="supplier = { ...scope.row }; create_drawer = true;">{{scope.row.name}}</el-link>
						</template>
					</el-table-column>
					<el-table-column label="店铺" prop="shop_name" v-if="!admin_user.shop_id"></el-table-column>
					<el-table-column label="级别" min-width="60" :formatter="({grade}) => { return grades[grade] || '无'; }"></el-table-column>
					<el-table-column label="联系人" min-width="80" prop="attn"></el-table-column>
					<el-table-column label="手机号" min-width="100" prop="mobile"></el-table-column>
					<el-table-column label="地址" min-width="160" prop="address"></el-table-column>
					<el-table-column label="备注" min-width="160" prop="remarks"></el-table-column>
					<el-table-column label="供应物料" min-width="80" :formatter="({material_suppliers_count}) => { return material_suppliers_count+' 个'; }"></el-table-column>
					<template v-if="theme.width >= 1420">
						<el-table-column label="更新时间" min-width="150" prop="updated_at"></el-table-column>
						<el-table-column label="创建时间" min-width="150" prop="created_at"></el-table-column>
					</template>
					<el-table-column label="操作" width="60">
						<template slot-scope="scope">
							<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete($api.URI_SUPPLIERS)" v-if="!scope.row.deleted_at">删除</el-button>
							<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore($api.URI_SUPPLIERS)" v-else>恢复</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getSuppliers({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getSuppliers({ ...params, page: v }) }"
			background>
		</el-pagination>
		<template slot="other">
			<!-- 编辑添加 -->
			<el-drawer :size="theme.width <= 480 ? '100%' : '480px'" :title="supplier.id ? '编辑供应商' : '添加供应商'" :visible.sync="create_drawer" @closed="resetForm('create_form')">
				<div class="pl10 pr10">
					<el-form ref="create_form" label-width="80px" :model="supplier" :rules="supplier_rules" :size="theme.size" status-icon>
						<el-form-item label="名称" prop="name">
							<el-input v-model="supplier.name" autocomplete="off" placeholder="请输入名称">
								<el-select slot="prepend" style="width: 120px;" v-if="!admin_user.shop_id" v-model="supplier.shop_id" placeholder="所属门店" filterable multiple collapse-tags>
									<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
								</el-select>
							</el-input>
						</el-form-item>
						<el-form-item label="级别" prop="grade">
							<el-select v-model="supplier.grade" placeholder="请选择级别" style="width: 100%;" filterable clearable>
								<el-option v-for="(grade, g) in grades" :key="g" :label="grade" :value="g"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="联系人" prop="attn">
							<el-input v-model="supplier.attn" autocomplete="off" placeholder="请输入联系人"></el-input>
						</el-form-item>
						<el-form-item label="手机号" prop="mobile">
							<el-input v-model="supplier.mobile" autocomplete="off" placeholder="请输入手机号"></el-input>
						</el-form-item>
						<el-form-item label="地址" prop="address">
							<el-input v-model="supplier.address" autocomplete="off" placeholder="请输入地址"></el-input>
						</el-form-item>
						<el-form-item label="备注" prop="remarks">
							<el-input type="textarea" rows="3" v-model="supplier.remarks" placeholder="用一段话简单描述备注说明。" maxlength="255" show-word-limit clearable></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('create_form')">确 定</el-button>
						</el-form-item>
					</el-form>
				</div>
			</el-drawer>
		</template>
	</main-table-a>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA,
		},
		computed: {
			...mapState(['theme', 'admin_user', 'models']),
			shops () {
				return this.models.shops ? this.models.shops.data : [];
			}
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+':'+e.order) : null;
				let p = this.params;
					p.order = o;
				this.getSuppliers(this.params);
			},
			onDelete (r) {
				this.$confirm('确定要删除【'+r.name+'】供应商吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_SUPPLIERS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getSuppliers(this.params);
					this.$message.success(msg);
				})
			},
			handleEdit (r) {
				this.supplier = {...r};
				this.create_drawer = true;
			},
			onRestore (r) {
				this.$confirm('确定要恢复【'+r.name+'】供应商吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_SUPPLIERS, {params: {action: 'restore', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getSuppliers(this.params);
					this.$message.success(msg);
				})
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'create_form':
							this.submitSuppliers(this.supplier);
						break;
						default:
							this.$message.error("操作未知表单");
						break;
					}
				})
			},
			resetForm (ref) {
				this.create_drawer = false;
				this.$refs[ref].resetFields();
				this.supplier = {};
			},
			async submitSuppliers (data) {
				const res = await this.$http.post(this.$api.URI_SUPPLIERS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						if (this.create_drawer) {
							this.create_drawer = false;
						}
						this.getSuppliers(this.params);
					}
				});
			},
			async getSuppliers (p, l = false) {
				const res = await this.$http.get(this.$api.URI_SUPPLIERS, {params: p, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.suppliers = result.data;
				this.counts = result.counts || {};
				this.grades = result.grades;
				this.params = {
					...p,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				grades: [],
				suppliers: [],
				supplier: {},
				counts: {},
				create_drawer: false,
				supplier_rules: {
					name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
				},
				params: {
					perPage: 10
				}
			}
		},
		async created () {
			this.getSuppliers(this.params, true)
		}
	};
</script>