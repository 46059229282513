<template>
	<el-dialog width="480px" :title="fee.id ? ('No.' + fee.no + ' 回款详情') : (fields.sources[fee.source_type] + 'No.' + fee.source_no + ' 客户回款')" :visible.sync="fee_dialog" :fullscreen="theme.width <= 480" :close-on-click-modal="false" @closed="fee = {}" append-to-body destroy-on-close>
		<div class="pr" v-if="is_show">
			<el-descriptions style="margin-top: -20px;" :column="2" :size="theme.size" border>
				<el-descriptions-item label="创建人">{{fee.employee ? fee.employee.name : '/'}}</el-descriptions-item>
				<el-descriptions-item label="付款金额"><b style="color: #ff0000;">{{fee.price | format_price}}</b></el-descriptions-item>
				<el-descriptions-item label="付款时间">{{fee.paid_at}}</el-descriptions-item>
				<el-descriptions-item label="付款方式">{{fields.types[fee.type] || '未知'}}</el-descriptions-item>
				<el-descriptions-item label="备注说明" :span="2">{{fee.comment || '/'}}</el-descriptions-item>
				<el-descriptions-item label="附件证明" :span="2" v-if="fee.attachments && fee.attachments.length">
					<div class="attachment" v-for="attachment in fee.attachments" :key="attachment.id" @click="openFile(attachment.url)">
						<i class="el-icon-document"></i> {{attachment.name}}
					</div>
				</el-descriptions-item>
			</el-descriptions>
			<div class="pa fs38 fwb" style="color: rgba(255, 0, 0, 0.2); top: 50%; left: 50%; transform: translate(-50%, -50%) rotateZ(20deg);" v-if="fields.statuses[fee.status]">{{fields.statuses[fee.status]}}</div>
		</div>
		<el-form ref="fee_form" label-width="80px" style="max-height: 58vh;" class="scroll-wrapper pr10 oa" :model="fee" :rules="fee_rules" :size="theme.size" :disabled="fee.id && !$utils.delete($api.URI_CLIENTS_FEES)" v-else>
			<el-form-item label="方式" prop="type">
				<el-select v-model="fee.type" placeholder="请选择方式">
					<el-option v-for="(type, t) in fields.types" :key="t" :label="type" :value="+t"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="金额" prop="price">
				<el-input-number v-model.number="fee.price" placeholder="请输入金额" :precision="2" :step="0.01" :min="0.01"></el-input-number> 元
			</el-form-item>
			<el-form-item label="备注" prop="comment" :rules="[{ required: fee.type > 1 ? true : false, message: '当非在线支付时必须填写备注，方便财务审核', trigger: 'blur' }]">
				<el-input v-model="fee.comment" type="textarea" placeholder="请输入备注说明。提交后不能修改" rows="3" maxlength="255" show-word-limit clearable></el-input>
			</el-form-item>
			<template v-if="fee.type > 1">
				<el-form-item label="时间" prop="paid_at" :rules="[{ required: true, message: '当非在线支付时必须选择付款日期时间，方便财务审核', trigger: 'blur' }]">
					<el-date-picker v-model="fee.paid_at" type="datetime" placeholder="选择付款日期时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="附件" prop="attachments" :rules="[{ required: true, message: '当非在线支付时必须上传证明，方便财务审核', trigger: 'blur' }]">
					<el-upload
						list-type="picture"
						:action="$api.URI_UPLOADS"
						:data="{type: contract_fee_cfg.type}"
						:accept="contract_fee_cfg.ext.map(e => { return '.'+e; }).join(',')"
						:on-success="(r, f, l) => { onSuccess(l, 'attachments'); }"
						:on-preview="(f) => { openFile(f.url); }"
						:before-upload="beforeFeeAttach"
						:file-list="fee.attachments">
						<el-button type="text" size="mini" icon="el-icon-upload">点击上传</el-button>
						<p slot="tip" class="fs12 c9 lh1_5">只能上传 {{contract_fee_cfg.ext.join('/').toUpperCase()}} 文件，且大小不超过 {{contract_fee_cfg.format_size_unit}}<br>使用现金、刷卡、转账等第三方支付时请必须上传证明</p>
					</el-upload>
				</el-form-item>
			</template>
			<el-form-item v-if="!fee.id && fee.source_type === 'App\\Models\\Order'">
				<p class="fs12 c9 lh1_5">所有订单已支持多笔回款，请勿多笔合并为一笔提交审核<br>回款审核通过即更新订单为"待交付"状态</p>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('fee_form')">{{fee.id ? '提交更新' : '提交创建'}}</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme', 'client_fees', 'models']),
			contract_fee_cfg () {
				return this.$utils.uploadConfig('fees');
			},
			fields () {
				return this.models.client_fees || {};
			},
			is_show () {
				const { status } = this.fee;
				if (status == 2) return true;
				if (status == 4) return true;
				return false;
			}
		},
		filters: {
			format_price (value) {
				return value.toFixed(2);
			}
		},
		methods: {
			openFile (u) {
				window.open(u);
			},
			async open (fee = {}) {
				if (!this.fields.sources[fee.source_type]) return this.$message.error('未知回款类型');
				this.fee = {...this.fee, ...fee};
				this.fee_dialog = true;
			},
			beforeFeeAttach (file) {
				const { size, format_size_unit } = this.contract_fee_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传第三方付款证明不能超过 '+format_size_unit);
				return is;
			},
			onSuccess (list) {
				this.fee = {
					...this.fee,
					attachments: list.map((file) => {
						const { response } = file;
						if (response) {
							const { status, id } = response.result;
							if (status == "success") {
								file.id = id;
							}
						}
						return file;
					})
				};
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					if (!this.fields.types[this.fee.type]) return this.$message.error("请选择方式");
					const res = await this.$http.post(this.$api.URI_CLIENTS_FEES, this.fee, {headers: {loading: true}});
					const { code, msg: message } = res.data;
					if (code != 0) return this.$message.error(message);
					this.$message.success({
						message,
						onClose: () => {
							this.fee_dialog = false;
							this.$emit('refresh');
						}
					});
				});
			},
			delFee ({ id, no, price }) {
				this.$confirm('No.' + no + '<br /><b style="color: #c7000a; font-size: 18px;">￥'+ (price/100).toFixed(2) + '</b>', '确定要删除回款吗？', {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_CLIENTS_FEES, {params: {action: 'delete', id}, headers: {loading: true}});
					const { code, msg: message } = res.data;
					if (code != 0) return this.$message.error(message);
					this.$message.success({
						message,
						onClose: () => {
							this.$emit('refresh');
						}
					});
				});
			},
			onStatus (r) {
				const { id, no, price, type, status, attachments } = r;
				switch (status) {
					case 1:
						if (!this.$utils.create(this.$api.URI_APPROVALS)) return this.$message.error('暂无提交审核权限！');
						if (type === 1) return this.$message.error('回款为在线支付，请复制链接或保存付款码联系客户支付');
						if (!attachments || !attachments.length) return this.$message.error('未上传付款证明截图附件');
						this.$confirm('No.' + no + '<br /><b style="color: #c7000a; font-size: 18px;">￥'+ price.toFixed(2) + '</b>', '确定要提交审核吗？', {
							dangerouslyUseHTMLString: true,
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							const res = await this.$http.post(this.$api.URI_APPROVALS, {common_model_id: id, common_model_type: 'App\\Models\\ClientFee'});
							const { code, msg: message } = res.data;
							if (code != 0) return this.$message.error(message);
							this.$message({
								type: 'success',
								duration: 1000,
								message,
								onClose: () => {
									this.$emit('refresh');
								}
							});
						});
					break;

					default:
						this.open(r);
					break;
				}
			}
		},
		data() {
			return {
				fee: {},
				fee_dialog: false,
				fee_rules: {
					type: [{ required: true, message: '必须选择回款方式', trigger: ['blur', 'change'] }],
					price: [
						{ required: true, message: '请输入金额', trigger: ['blur', 'change'] },
						{ type: 'number', min: 0.01, message: '最小金额为1分', trigger: ['blur', 'change'] },
					]
				}
			}
		}
	};
</script>
