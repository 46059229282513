<template>
	<div>
		<el-form :inline="true" :model="params" :size="theme.size">
			<el-form-item label="关键词">
				<el-input v-model="params.keywords" placeholder="请输入商户名称关键词" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="getWechatMerchants({ ...params, page: 1})">查询</el-button>
				<el-button type="primary" :disabled="!$utils.create('wechat/merchants')" @click="drawer = true">添加商户</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="apps" :max-height="theme.height - 310" :size="theme.size">
			<el-table-column prop="id" label="ID"></el-table-column>
			<el-table-column prop="name" label="商户名称"></el-table-column>
			<el-table-column prop="mchid" label="商户号"></el-table-column>
			<el-table-column prop="created_at" label="创建时间" width="180"></el-table-column>
			<el-table-column fixed="right" label="更多操作" width="180">
				<template slot-scope="scope">
					<el-button type="text" @click="createForm = scope.row; drawer = true">编辑</el-button>
					<el-button type="text" @click="createForm = scope.row; drawer = true">编辑</el-button>
					<el-button type="text" @click="onDelete(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			@size-change="(v) => { getWechatMerchants({ ...params, page: 1, perPage: v}) }"
			@current-change="(v) => { getWechatMerchants({ ...params, page: v}) }"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			background>	
		</el-pagination>
		<el-dialog :title="createForm.id ? '编辑商户' : '添加商户'" :visible.sync="drawer" @closed="createForm = {}; drawer = false">
			<div class="pl15 pr15">
				<el-form status-icon :model="createForm" :size="theme.size" ref="createForm" label-width="90px">
					<el-form-item label="商户名称" prop="name" required>
						<el-input v-model="createForm.name" autocomplete="off" placeholder="请输入商户名称"></el-input>
					</el-form-item>
					<el-form-item label="商户号" prop="mchid" required>
						<el-input v-model="createForm.mchid" autocomplete="off" placeholder="请输入商户号" :disabled="createForm.id"></el-input>
					</el-form-item>
					<el-form-item label="商户序号" prop="serial" required>
						<el-input v-model="createForm.serial" autocomplete="off" placeholder="请输入商户序号" :disabled="createForm.id"></el-input>
					</el-form-item>
					<el-form-item label="ApiV3密钥" prop="secret_v3" required>
						<el-input v-model="createForm.secret_v3" autocomplete="off" placeholder="请输入ApiV3密钥" :disabled="createForm.id"></el-input>
					</el-form-item>
					<el-form-item label="商户私钥" prop="private_key" required>
						<el-input v-model="createForm.private_key" autocomplete="off" placeholder="请输入商户私钥" type="textarea" rows="6" :disabled="createForm.id"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button @click="resetForm('createForm')">取 消</el-button>
						<el-button type="primary" @click="submitForm('createForm')">确 定</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			onDelete (r) {
				this.$confirm('确定要删除【'+r.name+'】商户吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_WECHAT_MERCHANTS, {params: {action: 'destroy', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.getWechatMerchants(this.params);
						}
					});
				})
			},
			resetForm (ref) {
				this.drawer = false;
				this.$refs[ref].resetFields();
				this.createForm = {
					type: 0
				};
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					this.submitMerchant(this.createForm);
				})
			},
			async submitMerchant (data) {
				const res = await this.$http.post(this.$api.URI_WECHAT_MERCHANTS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 2000,
					message: msg,
					onClose: () => {
						this.drawer = false;
						this.getWechatMerchants(this.params);
					}
				});
			},
			async getWechatMerchants (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_WECHAT_MERCHANTS, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.apps = result.data;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				apps: [],
				drawer: false,
				createForm: {},
				params: {
					perPage: 10,
				}
			}
		},
		created () {
			this.getWechatMerchants(this.params, true);
		}
	};
</script>