<template>
	<el-dialog width="460px" :title="insurance.id ? '编辑投保' : '创建投保'" :visible.sync="insurance_dialog" @closed="insurance = {}" append-to-body destroy-on-close>
		<el-form label-width="90px" ref="insurance_form" :model="insurance" :rules="insurance_rules" :size="theme.size" status-icon>
			<el-form-item label="被保人" v-if="insurance.employee">
				<b>{{insurance.employee.name}}</b>
			</el-form-item>
			<el-form-item label="投保产品" prop="insurance_temp_id" v-if="!insurance.id">
				<el-select v-model="insurance.insurance_temp_id" placeholder="投保产品" filterable clearable>
					<el-option v-for="temp in temps" :key="temp.id" :label="temp.name" :value="temp.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="投保产品" v-else>《<b>{{insurance.name}}</b>》</el-form-item>
			<el-form-item label="基数/缴费" style="margin-bottom: 0;" required>
				<el-col :span="10">
					<el-form-item prop="base">
						<el-input-number style="width: 100%;" placeholder="基数" v-model="insurance.base" :precision="2" :step="0.01" :min="0.01"></el-input-number>
					</el-form-item>
				</el-col>
				<el-col class="tac" :span="2">-</el-col>
				<el-col :span="10">
					<el-form-item prop="once">
						<el-switch v-model="insurance.once" active-value="1" inactive-value="0" active-text="当月" inactive-text="每月"></el-switch>
					</el-form-item>
				</el-col>
				<el-col :span="2">
					<el-tooltip placement="top">
						<div slot="content">默认每月缴费<br>选择当月缴费即根据创建时间，且必须选择失效时间</div>
						<i class="el-icon-question"></i>
					</el-tooltip>
				</el-col>
			</el-form-item>
			<el-form-item label="失效时间" prop="expired_at" v-if="insurance.once == 1">
				<el-date-picker v-model="insurance.expired_at" type="datetime" placeholder="选择失效时间" :picker-options="{ disabledDate (time) { return time.getTime() < Date.now(); } }"></el-date-picker>
			</el-form-item>
			<el-form-item label="备注说明" prop="remarks">
				<el-input v-model="insurance.remarks" type="textarea" placeholder="请输入备注说明。非必填" rows="3" maxlength="255" show-word-limit clearable></el-input>
			</el-form-item>
			<el-form-item label="其它附件" prop="attachments">
				<el-upload
					list-type="picture"
					:action="$api.URI_UPLOADS"
					:data="{type: insurances_cfg.type}"
					:accept="insurances_cfg.ext.map(e => { return '.'+e; }).join(',')"
					:on-success="(r, f, l) => { onSuccess(l, 'attachments'); }"
					:on-preview="(f) => { openFile(f.url); }"
					:before-upload="beforeFeeAttach"
					:file-list="insurance.attachments">
					<el-button type="text" size="mini" icon="el-icon-upload">点击上传</el-button>
					<p slot="tip" class="fs12 c9 lh1_5">只能上传 {{insurances_cfg.ext.join('/').toUpperCase()}} 文件，且大小不超过 {{insurances_cfg.format_size_unit}}。<br>非必要，一般建议上传商业保单购买回执之类相关证明文件</p>
				</el-upload>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('insurance_form')">{{insurance.id ? '提交保存'  : '提交创建'}}</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		props: {
			temps: {
				type: Array
			}
		},
		computed: {
			...mapState(['theme']),
			insurances_cfg () {
				return this.$utils.uploadConfig('insurances');
			}
		},
		methods: {
			open (insurance) {
				this.insurance = {...insurance};
				this.insurance_dialog = true;
			},
			openFile (u) {
				window.open(u);
			},
			onSuccess (list) {
				this.insurance = {
					...this.insurance,
					attachments: list.map((file) => {
						const { response } = file;
						if (response) {
							const { status, id } = response.result;
							if (status == "success") {
								file.id = id;
							}
						}
						return file;
					})
				};
			},
			beforeFeeAttach (file) {
				const { size, format_size_unit } = this.insurances_cfg;
				const is = file.size < size;
				if (!is) return this.$message.error('上传相关证明文件不能超过 '+format_size_unit);
				return is;
			},
			async submitInsurance (data) {
				const res = await this.$http.post(this.$api.URI_INSURANCES, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.$emit('refresh');
						this.insurance_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'insurance_form':
							// console.log(this.insurance);
							this.submitInsurance(this.insurance);
						break;
					}
				});
			}
		},
		data() {
			return {
				insurance: {},
				insurance_dialog: false,
				insurance_rules: {
					insurance_temp_id: [{ required: true, message: '必须选择投保产品', trigger: 'blur'}],
					base: [{ required: true, message: '必须填写金额', trigger: 'blur'}],
					expired_at: [{ required: true, message: '必须选择失效时间', trigger: 'blur'}],
				}
			}
		}
	};
</script>