<template>
	<main-table-a>
		<div slot="table" class="df f1 fdc">
			<div class="df">
				<div class="p10 mr20 mb20 br5 miw100 cp pr" :style="'box-shadow: 0 0 10px 5px #f1f1f1; background-color: ' + (status.checked ? '#b7a06a' : '#e2d6ba')" v-for="(status, s) in status_tabs" :key="s" @click="toggleStatus(s)">
					<div class="df jcsb mb5">
						<div class="fs16 fw6 c3">{{counts['status_'+s] || 0}}</div>
					</div>
					<div class="fs14 c6">{{status.name}}</div>
				</div>
				<div class="mb10">
					<el-button type="primary" size="mini" @click="show_fillter = !show_fillter" :plain="!show_fillter">{{show_fillter ? '收起' : '筛选'}}</el-button>
				</div>
			</div>
			<el-form :inline="true" :model="params" :size="theme.size" v-if="show_fillter">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="流水号/订单号/合同号" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="admin_user.shop_id">
					<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item v-else>
					<el-select v-model="params.shop_id" placeholder="城市站点" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employee_id" placeholder="请选择业务" filterable  multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id">
							<span>{{employee.name}}</span>
							<span class="c9 fs12">{{counts["employee_"+employee.id] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.source" placeholder="请选择模型" clearable multiple collapse-tags>
						<el-option v-for="(source, s) in sources" :key="s" :label="source" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.type" placeholder="请选择类型" clearable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="(type, t) in types" :key="t" :label="type" :value="t">
							<span>{{type}}</span>
							<span class="c9 fs12">{{counts["type_"+t] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-cascader placeholder="请选择类目" v-model="params.categories" :options="tree_categories" :props="{expandTrigger: 'hover', value: 'id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.brand_id" placeholder="请选择品牌" filterable multiple collapse-tags>
						<el-option v-for="brand in brands" :key="brand.id" :label="brand.name" :value="brand.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.goods_type_id" placeholder="请选择类型" filterable multiple collapse-tags>
						<el-option v-for="type in goods_types" :key="type.id" :label="type.name" :value="type.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable clearable>
						<el-option label="创建时间" value="created"></el-option>
						<el-option label="更新时间" value="updated"></el-option>
						<el-option label="支付时间" value="paid"></el-option>
					</el-select>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getClientFees({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table ref="fees_table" class="scroll-wrapper pa" height="100%" :data="contract_fees" :size="theme.size">
					<el-table-column type="selection" width="42"></el-table-column>
					<el-table-column label="流水号" min-width="230">
						<template slot-scope="scope">
							<el-link type="primary" @click="$refs['fee_dialog'].open({...scope.row, source_no: scope.row.source ? scope.row.source.no : '', price: scope.row.price/100})">No.{{scope.row.no}}</el-link>
							<p v-if="scope.row.source">
								{{sources[scope.row.source_type]}}：
								<el-link type="primary" v-if="scope.row.source_type == 'App\\Models\\Order'" @click="$refs['show_dialog'].open(scope.row.source)">{{scope.row.source.no}}</el-link>
								<template v-else>{{scope.row.source.no}}</template>
							</p>
						</template>
					</el-table-column>
					<el-table-column label="客户" prop="client_name" min-width="100"></el-table-column>
					<el-table-column label="业务员" min-width="120">
						<template slot-scope="scope">
							<i class="el-icon-user"></i> {{scope.row.employee ? scope.row.employee.name : '未知业务'}}
							<template v-if="theme.width < 1420">
								<p v-if="admin_user.shop_id"><i class="el-icon-office-building"></i> {{scope.row | format_department(oa_users, oa_departments)}}</p>
								<p v-else><i class="el-icon-office-building"></i> {{ scope.row.source.shop ? scope.row.source.shop.name : theme.group }}</p>
							</template>
						</template>
					</el-table-column>
					<template v-if="theme.width >= 1420">
						<el-table-column label="归属部门" min-width="100" v-if="admin_user.shop_id">
							<template slot-scope="scope">{{scope.row | format_department(oa_users, oa_departments)}}</template>
						</el-table-column>
						<el-table-column label="城市站点" min-width="100" v-else :formatter="(r) => { return r.source.shop ? r.source.shop.name : theme.group; }"></el-table-column>
					</template>
					<el-table-column label="付款方式" min-width="100" :formatter="({type}) => { return types[type] || '/'; }"></el-table-column>
					<el-table-column label="付款金额" min-width="100" :formatter="(r) => { return (r.price/100).toFixed(2)+' 元'; }"></el-table-column>
					<el-table-column label="状态" min-width="80">
						<template slot-scope="scope">
							<el-tooltip effect="dark" placement="top" :content="'OA审核编号'+scope.row.approval.sp_no" v-if="scope.row.approval">
								<el-link type="primary" @click="$refs['fee_dialog'].onStatus({...scope.row, source_no: scope.row.source ? scope.row.source.no : '', price: scope.row.price/100})">{{statuses[scope.row.status] || '未知'}}</el-link>
							</el-tooltip>
							<el-link type="primary" @click="$refs['fee_dialog'].onStatus({...scope.row, source_no: scope.row.source ? scope.row.source.no : '', price: scope.row.price/100})" v-else>{{statuses[scope.row.status] || '未知'}}</el-link>
						</template>
					</el-table-column>
					<el-table-column label="操作时间" width="200" v-if="theme.width >= 1080">
						<template slot-scope="scope">
							<p v-if="scope.row.created_at">创建：{{scope.row.created_at}}</p>
							<p v-if="scope.row.updated_at">更新：{{scope.row.updated_at}}</p>
							<p v-if="scope.row.paid_at">支付：{{scope.row.paid_at}}</p>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="60">
						<el-dropdown slot="header" @command="batchAction">
							<el-button type="text">操作<i class="el-icon-arrow-down el-icon--right"></i></el-button>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="export">导出选中</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<template slot-scope="scope">
							<el-button type="text" @click="$refs['fee_dialog'].delFee(scope.row)" :disabled="!$utils.delete($api.URI_CLIENTS_FEES) || scope.row.deleted_at">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<template slot="footer">
			<el-pagination
				:page-size="params.perPage"
				:page-sizes="$config.PAHE_SIZES"
				:current-page="params.page"
				:layout="$config.PAGE_LAYOUT"
				:total="params.total"
				@size-change="(v) => { getClientFees({ ...params, page: 1, perPage: v }) }"
				@current-change="(v) => { getClientFees({ ...params, page: v }) }"
				background>
			</el-pagination>
			<div style="font-size: 12px; color: #666;">当前页累计 <b style="color: #ff0000;">{{ contract_fees | money_total}}</b> 元</div>
		</template>
		<template slot="other">
			<order-show ref="show_dialog"></order-show>
			<fee-show ref="fee_dialog" @refresh="getClientFees(params)"></fee-show>
		</template>
	</main-table-a>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';
	import orderShow from '@/pages/oms/orders/show';
	import feeShow from './show';
	// import vueQr from 'vue-qr';

	export default {
		components: {
			feeShow,
			orderShow,
			// vueQr,
			mainTableA
		},
		computed: {
			...mapState(['theme', 'admin_user', 'oa_users', 'oa_departments', 'goods', 'models']),
			tree_categories () {
				return this.$utils.buildTree(this.goods.categories || []);
			},
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			},
			goods_types () {
				return this.goods.goods_types || [];
			},
			brands () {
				return this.goods.brands || [];
			},
			statuses () {
				if (!this.models.client_fees) return [];
				return this.models.client_fees.statuses || [];
			},
			sources () {
				if (!this.models.client_fees) return [];
				return this.models.client_fees.sources || [];
			},
			types () {
				if (!this.models.client_fees) return [];
				return this.models.client_fees.types || [];
			}
		},
		filters: {
			format_department ({ employee }, users, departments) {
				if (!employee) return '公海';
				if (!users[employee.id]) return '未关联OA';
				const { main_department } = users[employee.id];
				if (!departments[main_department]) return '未知部门';
				return departments[main_department].name;
			},
			money_total (data) {
				let money = 0;
				data.forEach((v) => {
					money += v.price;
				});
				return (money/100).toFixed(2);
			}
		},
		methods: {
			openFile (u) {
				window.open(u);
			},
			toggleStatus (s) {
				const { status } = this.params
				const index = status.indexOf(s);
				if (index > -1) {
					status.splice(index, 1);
					this.status_tabs[s].checked = false;
				} else {
					status.push(s);
					this.status_tabs[s].checked = true;
				}
				this.getClientFees(this.params);
			},
			async batchAction (m) {
				const { selection } = this.$refs.fees_table;
				if (!selection.length) return this.$message.error('请选择操作内容');
				switch (m) {
					case 'export':
							selection.forEach((c) => {
								c.employee_name = c.employee ? c.employee.name : '未知';
								c.price_unit = (c.price/100).toFixed(2);
								c.type_name = this.types[c.type];
								c.status_name = this.statuses[c.status];
								c.department_name = '未关联OA';
								if (this.oa_users[c.employee_id]) {
									const { main_department } = this.oa_users[c.employee_id];
									c.department_name = '未知部门';
									if (this.oa_departments[main_department]) {
										c.department_name = this.oa_departments[main_department].name;
									}
								}
							});
							this.$confirm(`此操作将导出${selection.length}条内容, 是否继续？`, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								var file = "客户回款导出" + this.$moment().format('YYYYMMDDHHmmss');
								var header = [['流水号', '部门', '业务员', '客户', '金额', '收款渠道', '状态', '支付时间', '更新时间', '创建时间']];
								var filter = ['no', 'department_name', 'employee_name', 'client_name', 'price_unit', 'type_name', 'status_name', 'paid_at', 'updated_at', 'created_at'];
								this.$utils.handleDownload(header, filter, selection, file, "回款明细");
							})
						break;
				}
			},
			async getClientFees (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_CLIENTS_FEES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.contract_fees = result.data;
				this.counts = result.counts;
				this.employees = result.employees;
				var status_tabs = {};
				const status = params.status || [];
				for (var s in this.statuses) {
					status_tabs[s] = {
						name: this.statuses[s],
						checked: status.indexOf(s) >= 0 ? true : false
					};
				}
				this.status_tabs = status_tabs;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				shops: [],
				employees: [],
				status_tabs: [],
				contract_fees: [],
				show_fillter: false,
				counts: {},
				params: {
					status: [],
					perPage: 10
				}
			}
		},
		async created () {
			this.getClientFees(this.params, true);
		}
	};
</script>