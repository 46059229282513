<template>
	<el-dialog width="750px" title="产品信息" :visible.sync="show_dialog" :fullscreen="fullscreen" :custom-class="fullscreen ? 'fullscreen' : 'ufullscreen'" :close-on-click-modal="false" @closed="form = {}" append-to-body destroy-on-close>
		<el-tabs class="goods-tabs" v-model="tab_active" v-if="show_dialog">
			<el-tab-pane label="基本信息" name="basic">
				<el-card style="height: 100%;" class="scroll-wrapper oa">
					<el-form ref="goods_form" label-width="90px" :model="form" :rules="goods_rules" :size="theme.size">
						<el-form-item label="名称" prop="title">
							<el-input placeholder="请输入产品名称" type="textarea" v-model="form.title" maxlength="128" show-word-limit></el-input>
						</el-form-item>
						<el-form-item label="门店" prop="shop_id" v-if="!admin_user.shop_id">
							<el-select style="width: 100%;" v-model="form.shop_id" placeholder="所属门店" filterable clearable>
								<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="类目/类型" style="margin-bottom: 0;" required>
							<el-col :span="15">
								<el-form-item prop="categories">
									<el-cascader style="width: 100%;" placeholder="请选择产品所属类目。支持输入搜索" v-model="form.categories" :options="tree_categories" :props="{expandTrigger: 'hover', value: 'id', label: 'name'}" filterable clearable></el-cascader>
								</el-form-item>
							</el-col>
							<el-col :span="1" class="c6 tac">-</el-col>
							<el-col :span="8">
								<el-form-item prop="goods_type_id">
									<el-select style="width: 100%;" v-model="form.goods_type_id" placeholder="类型" filterable clearable>
										<el-option label="单品" :value="0"></el-option>
										<el-option v-for="type in goods_types" :key="type.id" :label="type.name" :value="type.id"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-form-item>
						<el-form-item label="品牌/编码" style="margin-bottom: 0;" required>
							<el-col :span="5">
								<el-form-item prop="brand_id">
									<el-select style="width: 100%;" placeholder="选择品牌" v-model="form.brand_id" filterable clearable>
										<el-option label="无" :value="0"></el-option>
										<el-option v-for="brand in brands" :key="brand.id" :label="brand.name" :value="brand.id"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="1" class="c6 tac">-</el-col>
							<el-col :span="5">
								<el-form-item prop="number">
									<el-input placeholder="请输入产品编码" v-model="form.number" clearable></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="1" class="c6 tac">-</el-col>
							<el-col :span="12">
								<el-form-item prop="rack">
									<el-input placeholder="请输入货架。实物建议配合仓储管理物料使用" v-model="form.rack" clearable></el-input>
								</el-form-item>
							</el-col>
						</el-form-item>
						<el-form-item label="标签" prop="tags">
							<el-select style="width: 100%;" placeholder="请选择标签。输入搜索或添加，支持多选" v-model="form.tags" :remote-method="remoteMethod" :loading="tags_loading" :multiple-limit="5" remote multiple filterable allow-create default-first-option>
								<el-option v-for="(tag, t) in tags" :key="t" :label="tag" :value="tag"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="市价" prop="market_price">
							<el-input-number v-model.number="form.market_price" placeholder="请输入金额" :precision="2" :step="0.01" :min="0.01"></el-input-number> 元。<i class="c9">即用户端产品详情画线价格</i>
						</el-form-item>
						<el-form-item label="售价" prop="shop_price">
							<el-input-number v-model.number="form.shop_price" placeholder="请输入金额" :precision="2" :step="0.01" :min="0.01"></el-input-number> 元。<i class="c9">零售会员使用此价格结算</i>
						</el-form-item>
						<el-form-item label="重量" prop="weight">
							<el-input-number v-model.number="form.weight" placeholder="请输入金额" :min="0"></el-input-number> 克。<i class="c9">0为虚拟产品</i>
						</el-form-item>
						<el-form-item label="库存" prop="stock">
							<el-input-number v-model.number="form.stock" placeholder="请输入产品库存" :min="0" :max="999999999"></el-input-number>
						</el-form-item>
						<el-form-item>
							<el-button slot="label" type="text" @click="downloadTemp" v-if="!form.id">下载模板</el-button>
							<el-button type="primary" @click="submitForm('goods_form')">{{form.id ? '保存产品' : '立即创建'}}</el-button>
							<el-button type="danger" @click="delGoods(form)" :disabled="!$utils.delete($api.URI_GOODS)" v-if="form.id">删除产品</el-button>
							<el-upload class="dib ml10 mr10" accept=".xls,.xlsx" :auto-upload="false" :show-file-list="false" :on-change="importGoods" :action="$api.URI_UPLOADS" :disabled="!$utils.create($api.URI_GOODS)" v-if="!form.id">
								<el-button type="primary" :disabled="!$utils.create($api.URI_GOODS)" plain>导入产品</el-button>
							</el-upload>
						</el-form-item>
					</el-form>
				</el-card>
			</el-tab-pane>
			<template v-if="form.id">
			<el-tab-pane label="产品属性" name="attributes" lazy>
				<goods-attributes :goods="form" v-if="tab_active === 'attributes'" @refresh="open(form)"></goods-attributes>
			</el-tab-pane>
			<el-tab-pane label="产品附件" name="attachments" lazy>
				<goods-attachments :goods="form" v-if="tab_active === 'attachments'" @refresh="open(form)"></goods-attachments>
			</el-tab-pane>
			<el-tab-pane label="下单控件" name="controls" lazy>
				<goods-controls :product="form" v-if="tab_active === 'controls'" @refresh="open(form)"></goods-controls>
			</el-tab-pane>
			<el-tab-pane label="图文详情" name="teletext" lazy>
				<div class="df fdc br5 oh" style="height: 100%; border: 1px solid #ccc;">
					<Toolbar mode="simple" style="border-bottom: 1px solid #ccc;" :editor="editor" :defaultConfig="toolbarConfig"></Toolbar>
					<Editor mode="simple" :style="'height: '+(theme.height - 140)+'px; overflow-y: hidden;'" v-model="form.source" :defaultConfig="editorConfig" @onCreated="createdEditor"></Editor>
				</div>
			</el-tab-pane>
			<el-tab-pane label="限时促销" name="discounts" v-if="$utils.index($api.URI_GOODS_DISCOUNTS)" lazy>
				<goods-discounts :goods="form" v-if="tab_active === 'discounts'"></goods-discounts>
			</el-tab-pane>
			</template>
		</el-tabs>
	</el-dialog>
</template>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style type="text/css">
	.w-e-scroll {
		&::-webkit-scrollbar-track-piece {
			background-color: #f1f1f1;
			-webkit-border-radius: 3px;
		}
		&::-webkit-scrollbar {
			width: 12px;
			height: 12px;
		}
		&::-webkit-scrollbar-thumb {
			height: 30px;
			outline-offset: -2px;
			border: 2px solid #fff;
			background-color: #e1d6ba;
			-webkit-border-radius: 7px;
		}
		&::-webkit-scrollbar-thumb:hover {
			height: 30px;
			background-color: #b7a06a;
			-webkit-border-radius: 8px;
		}
	}
	.fullscreen .el-dialog__body {
		height: calc(100% - 54px);
	}
	.ufullscreen .el-dialog__body {
		height: calc(100vh - 30vh);
	}
	.goods-tabs {
		height: 100%;
		display: flex;
		margin-top: -30px;
		flex-direction: column;
	}
	.goods-tabs .el-tabs__content,
	.goods-tabs .el-tab-pane {
		height: 100%;
	}
</style>
<script>
	import { mapState } from 'vuex';
	import XLSX from "xlsx";
	import { Boot } from '@wangeditor/editor';
	import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
	import goodsAttributes from './goods/attributes';
	import goodsAttachments from './goods/attachments';
	import goodsControls from './goods/controls';
	import goodsDiscounts from './goods/discounts';
	import { ad_export_json_to_excel_multi_sheet } from '@/utils/exportExcel';

	class saveButtonMenu {
		constructor () {
			this.title = '保存';
			this.tag = 'button';
		}
		getValue () {
			return false;
		}
		isActive () {
			return false;
		}
		isDisabled() {
			return false;
		}
		exec (editor) {
			if (this.isDisabled(editor)) return false;
			editor.emit('saveContent');
		}
	}

	export default {
		components: {
			Editor,
			Toolbar,
			goodsControls,
			goodsDiscounts,
			goodsAttributes,
			goodsAttachments
		},
		computed: {
			...mapState(['theme', 'admin_user', 'goods']),
			tree_categories () {
				return this.$utils.buildTree(this.goods.categories || []);
			},
			goods_types () {
				return this.goods.goods_types || [];
			},
			brands () {
				return this.goods.brands || [];
			},
			shops () {
				return [];
			},
			fullscreen () {
				if (this.$utils.isiPad()) return true;
				if (this.$utils.isMobile()) return true;
				if (this.theme.width < 750) return true;
				return false;
			},
			goods_detail_cfg () {
				return this.$utils.uploadConfig('goods_detail');
			},
			goods_attachment_cfg () {
				return this.$utils.uploadConfig('goods_attachment');
			},
			toolbarConfig () {
				return {
					insertKeys: {
						index: 0,
						keys: ['save_content']
					}
				};
			},
			editorConfig () {
				const { type, size, ext } = this.goods_detail_cfg;
				return {
					placeholder: '请输入图文内容...',
					maxLength: 10000,
					MENU_CONF: {
						uploadImage: {
							allowedFileTypes: ext.map((e) => { return '.'+e; }),
							maxFileSize: size,
							fieldName: 'file',
							server: this.$api.URI_UPLOADS,
							meta: { type },
							onError: (file, err) => {
								this.$message.error('上传出错：'+err);
							},
							customInsert: (res, insertFn) => {
								const { code, msg, result } = res;
								if (code) return this.$message.error(msg);
								insertFn(result.url, result.name);
							}
						}
					}
				};
			},
			table_temp () {
				const thead = ['名称', '类目ID', '品牌ID', '类型ID', '编码', '市场价', '零售价', '重量', '库存', '货架', '标签'];
				const filter = ['title', 'categories', 'brand_id', 'goods_type_id', 'number', 'market_price', 'shop_price', 'weight', 'stock', 'rack', 'tags'];
				return {
					data: [],
					sheet: '产品列表',
					thead,
					filter
				};
			}
		},
		methods: {
			async open (r = {}) {
				if (r.number) {
					const res = await this.$http.get(this.$api.URI_GOODS, {params: {action: 'show', number: r.number}, headers: {loading: true}});
					const { code, msg, result } = res.data;
					if (code) return this.$message.error(msg);
					this.form = {...result, market_price: (result.market_price || 0)/100, shop_price: (result.shop_price || 0)/100, tags: result.tags ? result.tags.map((t) => { return t.name; }) : [] };
					// this.form = {...r, market_price: (r.market_price || 0)/100, shop_price: (r.shop_price || 0)/100, tags: r.tags ? r.tags.map((t) => { return t.name; }) : [] }
				}
				this.show_dialog = true;
			},
			async remoteMethod (query) {
				if (query !== '') {
					this.tags_loading = true;
					const res = await this.$http.get(this.$api.URI_TAGS, { params: {action: 'search', word: query} });
					this.tags = res.data.result.map((tag) => { return tag.name; });
					this.tags_loading = false;
				} else {
					this.tags = [];
				}
			},
			async getMaterials (in_code, form) {
				if (!in_code) return this.$message.error('编码不能为空！');
				const res = await this.$http.get(this.$api.URI_MATERIALS, {params: {action: 'show', in_code}, headers: {loading: true}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this[form] = { ...this[form], shop_price: result.price, weight: result.weight, stock: result.inventory, rack: result.rack }
			},
			createdEditor (editor) {
				editor.on('saveContent', () => {
					this.submitGoods({action: 'content', ...this.content});
				});
				// 一定要用 Object.seal() ，否则会报错
				this.editor = Object.seal(editor);
			},
			submitForm (ref) {
				this.$refs[ref].validate((valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (ref) {
						case 'goods_form':
							this.submitGoods(this.form);
						break;
					}
				});
			},
			async submitGoods (data) {
				const res = await this.$http.post(this.$api.URI_GOODS, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.$emit('refresh');
						this.show_dialog = false;
					}
				});
			},
			async handleGoods (a, r) {
				const res = await this.$http.get(this.$api.URI_GOODS, {params: {action: a, id: r.id}, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
				this.$emit('refresh');
				this.show_dialog = false;
			},
			delGoods (r) {
				this.$confirm(`确定要删除【${r.title}】产品吗？`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.handleGoods('delete', r);
				});
			},
			importGoods ({raw}) {
				const fileReader = new FileReader();
				fileReader.readAsBinaryString(raw);
				fileReader.onload = async ({target}) => {
					try {
						const { filter, thead } = this.table_temp;
						const { Sheets, SheetNames } = XLSX.read(target.result, {type: "binary"});
						const data = XLSX.utils.sheet_to_json(Sheets[SheetNames[0]], {header: filter});
						data.shift();
						if (data.length <= 0) return this.$message.error("无需要导入记录！");
						let index = 0;
						let notify = this.$notify({
							title: '提示',
							dangerouslyUseHTMLString: true,
							message: '<p>正在准备导入产品...</p>',
							showClose: false,
							duration: 0
						});
						for (var i in data) {
							const { title, categories, goods_type_id, brand_id, number, market_price, shop_price, stock, weight, tags } = data[i];
							if (!title) {
								data[i].status = "提交名称不能为空";
								continue;
							}
							if (!categories) {
								data[i].status = "提交类目不能为空";
								if (typeof categories !== 'number') {
									data[i].status = "提交类目ID必须为数值";
								}
								continue;
							}
							if (!goods_type_id) {
								data[i].status = "提交类型不能为空";
								if (typeof goods_type_id !== 'number') {
									data[i].status = "提交类型ID必须为数值";
								}
								continue;
							}
							if (!brand_id) {
								data[i].status = "提交品牌不能为空";
								if (typeof brand_id !== 'number') {
									data[i].status = "提交品牌ID必须为数值";
								}
								continue;
							}
							if (!number) {
								data[i].status = "提交编码不能为空";
								continue;
							}
							if (!market_price) {
								data[i].status = "提交市场价不能为空";
								continue;
							}
							if (!shop_price) {
								data[i].status = "提交零售价不能为空";
								continue;
							}
							if (weight && typeof weight !== 'number') {
								data[i].status = "产品重量必须为数值";
								continue;
							}
							if (stock && typeof stock !== 'number') {
								data[i].status = "产品库存必须为数值";
								continue;
							}
							if (tags) {
								data[i].tags = tags.replace("，", ",").split(",");
							}
							index += 1;
							notify.message = '<p>正在导入 ' + index + ' / ' + data.length + '</p>';
							const res = await this.$http.post(this.$api.URI_GOODS, {...data[i]});
							const { code, msg } = res.data;
							data[i].status = msg;
							if (code != 0) continue;
						}
						if (index === data.length) {
							notify.message = '<p>导入完成！</p>';
							this.$utils.handleDownload([[...thead, '状态']], [...filter, 'status'], data, "产品导入结果" + this.$moment().format('YYYYMMDDHHmmss'), "导入明细");
							setTimeout(() => {
								notify.close();
								this.$emit('refresh');
								this.show_dialog = false;
							}, 3000);
						}
					} catch (e) {
						console.log(e);
					}
				}
			},
			downloadTemp () {
				const all_categories = this.goods.categories || [];

				const categories = {};
				all_categories.forEach((r) => {
					categories[r.id] = r.name;
					r.parent_name = categories[r.parent_id] || '顶级'; 
				});
				const template = [
					{
						filter: ['id', 'name'],
						thead: ['ID', '名称'],
						sheet: '品牌',
						data: this.brands
					}, {
						filter: ['id', 'name'],
						thead: ['ID', '名称'],
						sheet: '类型',
						data: this.goods_types
					}, {
						filter: ['id', 'name', 'parent_name'],
						thead: ['ID', '名称', '上级'],
						sheet: '分类',
						data: all_categories
					}
				];
				template.unshift(this.table_temp);
				ad_export_json_to_excel_multi_sheet(template, '产品导入模板_生成于' + this.$moment().format('YYYYMMDD'));
			}
		},
		data() {
			return {
				tags: [],
				form: {},
				editor: null,
				show_dialog: false,
				tags_loading: false,
				tab_active: 'basic',
				goods_rules: {
					title: [{ required: true, message: '请输入名称！', trigger: 'blur' }],
					number: [{ required: true, message: '请输入编码！', trigger: 'blur' }],
					shop_id: [{ required: true, type: 'number', message: '请选择门店！', trigger: 'change' }],
					brand_id: [{ required: true, type: 'number', message: '请选择品牌！', trigger: 'change' }],
					goods_type_id: [{ required: true, type: 'number', message: '请选择类型！', trigger: 'change' }],
					stock: [{ type: 'number', message: '请输入整数！', trigger: 'change' }],
					weight: [{ type: 'number', message: '请输入整数！', trigger: 'change' }],
					shop_price: [{ required: true, message: '请输入零售价！', trigger: 'blur' }],
					market_price: [{ required: true, message: '请输入市场价！', trigger: 'blur' }],
					categories: [{ required: true, type: 'array', message: '请选择类目！', trigger: 'change' }]
				}
			}
		},
		mounted () {
			Boot.registerModule({
				menus: [
					{
						key: 'save_content',
						factory() {
							return new saveButtonMenu();
						}
					}
				]
			});
		}
	};
</script>