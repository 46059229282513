<template>
	<div>
		<el-table ref="wallet_table" class="bill scroll-wrapper" height="46vh" :data="holi" :size="theme.size">
			<el-table-column label="名称" min-width="60" prop="name"></el-table-column>
			<el-table-column label="OAID" min-width="60" prop="vacation_id"></el-table-column>
			<el-table-column label="时间刻度" min-width="70" :formatter="({time_attr}) => { return time_attr ? '小时' : '天'}"></el-table-column>
			<el-table-column label="扣薪比例" min-width="120">
				<template slot-scope="scope">
					<el-input ref="followInput" v-model="day.deduct" placeholder="请输入扣薪比例" @keyup.enter.native="saveFollow" @blur="saveFollow" :size="theme.size" v-if="day.id === scope.row.id">
						<template slot="append">%</template>
					</el-input>
					<el-link type="primary" :disabled="!$utils.update($api.URI_HOLIDAY)" @click="inputFollow(scope.row)" v-else>{{scope.row.deduct}} %</el-link>
				</template>
			</el-table-column>
			<el-table-column prop="updated_at" label="更新时间" min-width="150"></el-table-column>
			<el-table-column prop="created_at" label="创建时间" min-width="150">
				<template slot="header">创建时间 <el-tooltip effect="dark" content="创建来源于每月统计考勤同步OA系统"><i class="el-icon-info ch"></i></el-tooltip></template>
			</el-table-column>
		</el-table>
		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getHoliday({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getHoliday({ ...params, page: v }) }"
			background>	
		</el-pagination>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme'])
		},
		methods: {
			inputFollow (r) {
				this.day = { ...r };
				this.$nextTick(() => {
					this.$refs.followInput.focus();
				});
			},
			saveFollow () {
				const { deduct } = this.day;
				if (deduct) {
					if (!Number.isFinite(+deduct)) return this.$message.error("请输入0-100数值");
					if (deduct > 100) {
						this.day = { ...this.day, deduct: 100 }
					}
					if (deduct < 0) {
						this.day = { ...this.day, deduct: 0 }
					}
					this.submitHoliday(this.day, () => {
						this.getHoliday(this.params);
					});
				}
				this.day = {};
			},
			async getHoliday (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_HOLIDAY, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.holi = result.data;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async submitHoliday (data, onClose) {
				const res = await this.$http.post(this.$api.URI_HOLIDAY, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({ message, onClose });
			}
		},
		data() {
			return {
				day: {},
				holi: [],
				params: {
					perPage: 10,
				}
			}
		},
		mounted () {
			this.getHoliday(this.params, true);
		}
	};
</script>