<template>
	<main-table-a>
		<div class="f1 df fdc">
			<el-form slot="header" :inline="true" :model="params" size="mini">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="内容关键词"></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="所属门店" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getTopics({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table ref="topicsTable" class="scroll-wrapper absolute" height="100%" :data="topics" :size="theme.size">
					<el-table-column fixed="left" type="selection" width="42"></el-table-column>
					<el-table-column prop="content" label="内容"></el-table-column>
					<el-table-column prop="shop.name" label="站点"></el-table-column>
					<el-table-column prop="user.name" label="用户"></el-table-column>
					<el-table-column prop="replies_total" label="回复数量"></el-table-column>
					<el-table-column prop="at" label="操作时间" width="210">
						<template slot-scope="scope">更新：{{scope.row.updated_at}}<br>创建：{{scope.row.created_at}}</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getTopics({...params, page: 1, perPage: v}) }"
			@current-change="(v) => { getTopics({ ...params, page: val}) }"
			background>
		</el-pagination>
	</main-table-a>
</template>

<style>

</style>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			async getTopics (params) {
				const res = await this.$http.get(this.$api.URI_TOPICS, { params });
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.topics = result.data;
				this.shops = result.shops;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				shops: [],
				topics: [],
				params: {
					perPage: 10,
					type: []
				}
			}
		},
		async created () {
			this.getTopics(this.params);
		}
	};
</script>