<template>
	<div>
		<el-table ref="wallet_table" class="bill scroll-wrapper" height="46vh" :data="exceptions" :size="theme.size">
			<el-table-column label="规则描述" min-width="280" show-overflow-tooltip>
				<el-link slot-scope="scope" type="primary" @click="exception = {...scope.row}; exception_dialog = true;">{{scope.row | format_at(types)}}</el-link>
			</el-table-column>
			<el-table-column prop="updated_at" label="更新时间" min-width="150"></el-table-column>
			<el-table-column prop="created_at" label="创建时间" min-width="150"></el-table-column>
			<el-table-column min-width="50">
				<el-link slot="header" type="primary" @click="exception = {fixed: 0}; exception_dialog = true;">添加</el-link>
				<template slot-scope="scope">
					<el-popconfirm title="确定删除这条规则吗？" @confirm="handleExceptions({action: 'delete', id: scope.row.id})">
						<el-link slot="reference" type="primary">删除</el-link>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getExceptions({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getExceptions({ ...params, page: v }) }"
			background>	
		</el-pagination>
		<el-dialog :title="exception.id ? '编辑规则' : '添加规则'" width="360px" :visible.sync="exception_dialog" append-to-body destroy-on-close>
			<el-form label-width="60px" ref="exception_form" :model="exception" :rules="exception_rules" :size="theme.size" status-icon>
				<el-form-item label="类型" style="margin-bottom: 0;" required>
					<el-col :span="10">
						<el-form-item prop="exception">
							<el-select v-model="exception.exception" :disabled="exception.id" placeholder="类型" filterable>
								<el-option v-for="(type, t) in types" :key="t" :label="type" :value="+t"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col class="tac" :span="1">-</el-col>
					<el-col :span="13">
						<el-form-item prop="type">
							<el-switch v-model="exception.type" :active-value="1" :inactive-value="0" active-text="时长" inactive-text="次数"></el-switch>
							<el-tooltip effect="dark" content="同一类型可以添加创建多次规则，且次数/时长必须一致只能选其一"><i class="el-icon-info ch ml5"></i></el-tooltip>
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item label="超" prop="minimum">
					<el-input-number placeholder="最低(含)" v-model="exception.minimum" :min="1"></el-input-number> {{exception.type ? '分钟' : '次'}}
				</el-form-item>
				<el-form-item label="每" prop="step">
					<el-input-number placeholder="反复间隔" v-model="exception.step" :min="1" :max="exception.minimum"></el-input-number> {{exception.type ? '分钟' : '次'}}
				</el-form-item>
				<el-form-item label="扣" style="margin-bottom: 0;" required>
					<el-col :span="9">
						<el-form-item prop="fixed">
							<el-select v-model="exception.fixed" placeholder="类型" filterable>
								<el-option label="工时比例" :value="0"></el-option>
								<el-option label="固定金额" :value="1"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col class="tac" :span="1">-</el-col>
					<el-col :span="14">
						<el-form-item prop="deduct">
							<el-input-number placeholder="金额比例" v-model="exception.deduct" :precision="2" :step="0.01" :min="0.01"></el-input-number> {{exception.fixed ? '元' : '%'}}
						</el-form-item>
					</el-col>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('exception_form')">{{exception.id ? '提交保存'  : '提交创建'}}</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState(['theme'])
		},
		filters: {
			format_at ({minimum, type, step, exception, deduct, fixed}, types) {
				return (types[exception] || '未知') + (minimum ? '超 ' + (minimum + (type ? ' 分钟(含)以上' : ' 次(含)以上')) : '') + '每 ' + step + (type ? ' 分钟扣 ' : ' 次扣 ') + (+deduct) + (fixed ? ' 元' : '% 工时');
			}
		},
		methods: {
			async handleExceptions (params) {
				const res = await this.$http.get(this.$api.URI_EXCEPTION, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getExceptions(this.params);
				this.$message.success(msg);
			},
			async submitException (data) {
				const res = await this.$http.post(this.$api.URI_EXCEPTION, data);
				const { code, msg: message } = res.data;
				if (code != 0) return this.$message.error(message);
				this.$message.success({
					message,
					onClose: () => {
						this.getExceptions(this.params);
						this.exception_dialog = false;
					}
				});
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error("提交表单有误！");
					switch (ref) {
						case 'exception_form':
							this.submitException(this.exception);
						break;
					}
				});
			},
			async getExceptions (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_EXCEPTION, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.exceptions = result.data;
				this.types = result.exceptions;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				exception: {},
				exceptions: [],
				types: [],
				exception_dialog: false,
				exception_rules: {
					exception: [{ required: true, message: '必须选择类型', trigger: 'blur'}],
					minimum: [{ required: true, message: '必须填写最低门槛', trigger: 'blur'}],
					step: [{ required: true, message: '必须填写间隔', trigger: 'blur'}],
					deduct: [{ required: true, message: '必须填写扣除金额比例', trigger: 'blur'}]
				},
				params: {
					perPage: 10,
				}
			}
		},
		mounted () {
			this.getExceptions(this.params, true);
		}
	};
</script>