<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size" @submit.native.prevent v-if="!theme.is_mobile">
				<el-form-item>
					<el-input v-model="params.title" placeholder="产品名称/编码" @keyup.enter.native.prevent="getGoods(params)" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-cascader placeholder="请选择产品所属类目" v-model="params.categories" :props="categories" clearable></el-cascader>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.brand" placeholder="产品品牌" filterable clearable>
						<el-option v-for="brand in brands" :key="brand.id" :label="brand.name" :value="brand.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.goods_type_id" placeholder="产品类型" filterable clearable>
						<el-option :key="0" label="单产品" :value="0"></el-option>
						<el-option v-for="type in goods_types" :key="type.id" :label="type.name" :value="type.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getGoods(params)">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<!-- 产品表格 -->
				<el-table ref="goods_table" class="scroll-wrapper absolute" height="100%" :data="goods" @sort-change="sortChange" :size="theme.size">
					<el-table-column fixed="left" label="商品图片" width="120">
						<el-image slot-scope="scope" class="dis_thumb" fit="fit" :src="scope.row.goods.img" lazy>
							<i slot="error" class="el-icon-picture-outline"></i>
						</el-image>
					</el-table-column>
					<el-table-column prop="goods.number" label="商品信息">
						<template slot-scope="scope">
							<p>{{scope.row.goods.title}}</p>
							<p>{{scope.row.goods.number}}</p>
						</template>
					</el-table-column>
					<el-table-column prop="goods.market_price" label="市场价(元)" width="100">
						<template slot-scope="scope">{{scope.row.goods.market_price/100}}</template>
					</el-table-column>
					<el-table-column prop="goods.shop_price" label="零售价(元)" width="100">
						<template slot-scope="scope">{{scope.row.goods.shop_price/100}}</template>
					</el-table-column>
					<el-table-column prop="goods.stock" label="库存" width="100" sortable="custom"></el-table-column>
					<el-table-column prop="discount_desc" label="限时促销" width="120"></el-table-column>
					<el-table-column prop="once" label="一单限购" width="120">
						<template slot-scope="scope">{{scope.row.once?scope.row.once:'不限'}}</template>
					</el-table-column>
					<el-table-column prop="more" label="多单限购" width="120">
						<template slot-scope="scope">{{scope.row.more?scope.row.more:'不限'}}</template>
					</el-table-column>
					<el-table-column prop="at" label="活动时间" width="210">
						<template slot-scope="scope">更新：{{scope.row.begin_at}}<br>创建：{{scope.row.end_at}}</template>
					</el-table-column>
					<el-table-column prop="at" label="操作时间" width="210">
						<template slot-scope="scope">更新：{{scope.row.updated_at}}<br>创建：{{scope.row.created_at}}</template>
					</el-table-column>
					<el-table-column label="操作" width="160">
						<template slot-scope="scope">
							<el-dropdown v-if="!scope.row.deleted_at" @command="handleGoods">
								<span class="el-dropdown-link">更多操作<i class="el-icon-arrow-down el-icon--right"></i></span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="({t: 'on_edit', r: scope.row})" :disabled="!$utils.update('goods/discounts')">编辑</el-dropdown-item>
									<el-dropdown-item :command="({t: 'on_view', r: scope.row})">浏览</el-dropdown-item>
									<el-dropdown-item :command="({t: 'on_delete', r: scope.row})" :disabled="!$utils.delete('goods/discounts')">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
							<el-button type="text" @click="onRestore(scope.row)" :disabled="!$utils.restore('goods/discounts')" v-else>恢复</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<!-- 列表分页 -->
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getGoods({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getGoods({ ...params, page: v }) }"
			background>
		</el-pagination>
	</main-table-a>
</template>

<style>
	.dis_thumb {
		width: 60px;
		height: 60px;
		font-size: 30px;
		line-height: 60px;
		text-align: center;
		background: #f1f1f1;
	}
	.el-drawer__body {
		overflow: auto;
	}
</style>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		computed: {
			...mapState(['theme']),
			is_type () {
				let t = this.edit_rorm.field;
				if (t == 'boutique') return true;
				if (t == 'recommend') return true;
				if (t == 'shelf') return true;
				if (t == 'hot') return true;
				if (t == 'promote') return true;
				if (t == 'present') return true;
				if (t == 'discount') return true;
				return false
			}
		},
		methods: {
			sortChange (e) {
				let o = e.order ? (e.prop+'_'+e.order) : null;
				var p = this.params;
					p.order = o;
				this.getGoods(p);
			},
			async getGoods (p, l = false) {
				const res = await this.$http.get(this.$api.URI_GOODS_DISCOUNTS, {params: p, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				const { data, brands, goods_types, total, per_page, current_page } = result;
				this.goods = data;
				brands && (this.brands = brands);
				goods_types && (this.goods_types = goods_types);
				this.params = {
					...p,
					total: total,
					perPage: parseInt(per_page),
					page: parseInt(current_page)
				}
			},
			handleGoods (e) {
				const { title, id } = e.r;
				switch (e.t) {
					case 'on_edit':
						this.$utils.toRoute('/goods/'+id);
					break;
					case 'on_view':
						window.open(this.$config.ROOTPATH+'goods/'+id);
					break;
					case 'on_delete':
						this.$confirm('确定要删除【'+title+'】商品吗？', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(async () => {
							const res = await this.$http.get(this.$api.URI_GOODS, {params: {action: 'delete', id: id}, headers: {loading: true}});
							const { code, msg } = res.data;
							if (code != 0) return this.$message.error(msg);
							this.$message.success(msg);
							this.getGoods(this.params);
						})
					break;
				}
			},
			onRestore (r) {
				this.$confirm('确定要恢复此条内容吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					console.log(r);
					this.$message.error("接口还没接上。。。");
				})
			}
		},
		data() {
			return {
				goods: [],
				brands: [],
				categories: {
					lazy: true,
					value: 'id',
					label: 'name',
					checkStrictly: true,
					lazyLoad: async ({ value }, resolve) => {
						const res = await this.$http.get(this.$api.URI_CATEGORIES, {params: {action: 'category', category: value ? value : 0}, headers: {loading: true}});
						resolve(res.data.result);
					}
				},
				goods_types: [],
				type_attributes: [],
				edit_rorm: {
					value: 0
				},
				suk_form: {
					goods_type_id: 0
				},
				activeForm: {
					minimum: 1,
					amount: 1,
					type: 0,
					method: 0,
					once: 0,
					more: 0
				},
				active_rules: {
					minimum: [
						{ required: true, message: '请输入最低限度门槛' },
						{ type: 'number', min: 1, max: 999, message: '优惠金额必须大于等于1或小于等于999'}
					], 
					once: [
						{ required: true, message: '请输入一次下单数量限制' },
						{ type: 'number', min: 0, message: '一次下单数量限制必须大于等于0'}
					], 
					more: [
						{ required: true, message: '请输入多次下单数量限制' },
						{ type: 'number', min: 0, message: '多次下单数量限制必须大于等于0'}
					], 
				},
				dialogs: {
					sku_form: false,
					edit_rorm: false,
					activeForm: false,
				},
				params: {
					page: 1,
					perPage: 10,
					type: []
				}
			}
		},
		async created () {
			this.getGoods(this.params, true);
		}
	};
</script>