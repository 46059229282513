<template>
	<main-table-a>
		<div slot="table" class="df f1 fdc">
			<div class="df fww">
				<div class="p10 mr20 mb20 br5 miw100 cp" :style="'box-shadow: 0 0 10px 5px #f1f1f1; background-color: ' + (status.checked ? '#b7a06a' : '#e2d6ba')" v-for="(status, s) in status_tabs" :key="s" @click="toggleStatus(s)">
					<div class="df jcsb mb5">
						<div class="fs16 fw6 c3">{{counts["status_"+s] || 0}}</div>
					</div>
					<div class="fs14 c6">{{status.name}}</div>
				</div>
				<div class="mb10">
					<el-button type="primary" size="mini" @click="show_fillter = !show_fillter" :plain="!show_fillter">{{show_fillter ? '收起' : '筛选'}}</el-button>
					<el-button type="primary" size="mini" @click="$refs['show_dialog'].open()" :disabled="!$utils.create($api.URI_POSITIONS_CANDIDATES)">添加</el-button>
				</div>
			</div>
			<el-form :inline="true" :model="params" :size="theme.size" v-if="show_fillter">
				<el-form-item >
					<el-input v-model="params.keyword" placeholder="职位/姓名/手机/邮箱/最近工作单位/备注说明" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="城市站点" filterable multiple collapse-tags>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.employees" placeholder="请选择邀请人" filterable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id">
							<span>{{employee.name}}</span>
							<span class="c9 fs12">{{counts["employee_"+employee.id] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.source" placeholder="来源渠道" filterable multiple collapse-tags>
						<el-option class="df aic jcsb" v-for="(source, s) in fields.source" :key="s" :label="source" :value="s">
							<span>{{source}}</span>
							<span class="c9 fs12">{{counts["source_"+s] || 0}}</span>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getCandidates({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table ref="position_table" class="bill scroll-wrapper pa" height="100%" :data="candidates" :size="theme.size">
					<el-table-column label="邀请人" min-width="90">
						<template slot-scope="scope">
							<p>{{scope.row.employee.name}}</p>
							<p v-if="!admin_user.shop_id">{{scope.row.employee.shop ? scope.row.employee.shop.name : '未知'}}</p>
						</template>
					</el-table-column>
					<el-table-column label="应聘职位" prop="recruitment.title" min-width="100"></el-table-column>
					<el-table-column label="来源渠道" min-width="80" :formatter="({source}) => { return fields.source[source]; }"></el-table-column>
					<el-table-column label="姓名" min-width="70">
						<el-link slot-scope="scope" type="primary" :disabled="!$utils.update($api.URI_POSITIONS_CANDIDATES)" @click="$refs['show_dialog'].open(scope.row)">{{scope.row.name}}</el-link>
					</el-table-column>
					<el-table-column label="性别" min-width="50" :formatter="({gender}) => { return fields.gender[gender] || '/'; }"></el-table-column>
					<el-table-column label="联系方式" min-width="120">
						<template slot-scope="scope">
							<p v-if="scope.row.mobile">{{scope.row.mobile}}</p>
							<p v-if="scope.row.mail">{{scope.row.mail}}</p>
						</template>
					</el-table-column>
					<el-table-column label="工龄/年龄" min-width="80" :formatter="({age, work_years}) => { return work_years + ' / ' + age; }"></el-table-column>
					<el-table-column label="最近工作单位" prop="nearest" min-width="150"></el-table-column>
					<el-table-column label="备注说明" prop="remarks" min-width="120" show-overflow-tooltip></el-table-column>
					<el-table-column label="状态" min-width="70">
						<el-link slot-scope="scope" type="primary" @click="$refs['show_dialog'].onStatus(scope.row)">{{statuses[scope.row.status]}}</el-link>
					</el-table-column>
					<template  v-if="theme.width >= 1450">
						<el-table-column label="更新时间" prop="updated_at" min-width="150"></el-table-column>
						<el-table-column label="创建时间" prop="created_at" min-width="150"></el-table-column>
					</template>
					<el-table-column label="操作" width="60">
						<template slot-scope="scope">
							<el-popconfirm title="确定恢复这个职位吗？" @confirm="handleCandidate({action: 'restore', id: scope.row.id})" v-if="scope.row.deleted_at">
								<el-button slot="reference" type="text" :disabled="!$utils.restore($api.URI_POSITIONS_CANDIDATES)">恢复</el-button>
							</el-popconfirm>
							<el-popconfirm title="确定删除这个职位吗？" @confirm="onDelete(scope.row)" v-else>
								<el-button slot="reference" type="text" :disabled="!$utils.delete($api.URI_POSITIONS_CANDIDATES)">删除</el-button>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getCandidates({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getCandidates({ ...params, page: v }) }"
			background>	
		</el-pagination>
		<template slot="other">
			<show-candidate ref="show_dialog" :fields="fields" :recruitments="recruitments" @refresh="getCandidates(params)"></show-candidate>
		</template>
	</main-table-a>
</template>

<script>
	import { mapState } from 'vuex';
	import showCandidate from './show';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA,
			showCandidate
		},
		computed: {
			...mapState(['theme', 'admin_user', 'models']),
			fields () {
				return this.models.candidates || {};
			},
			statuses () {
				return this.fields.status || {};
			},
			shops () {
				return this.models.shops ? this.models.shops.data : [];
			}
		},
		methods: {
			toggleStatus (s) {
				const { status } = this.params
				const index = status.indexOf(s);
				if (index > -1) {
					status.splice(index, 1);
					this.status_tabs[s].checked = false;
				} else {
					status.push(s);
					this.status_tabs[s].checked = true;
				}
				this.getCandidates(this.params);
			},
			async getCandidates (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_POSITIONS_CANDIDATES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.candidates = result.data;
				this.recruitments = result.recruitments;
				this.employees = result.employees;
				this.counts = result.counts;
				var status_tabs = {};
				const status = params.status || [];
				for (var s in this.statuses) {
					status_tabs[s] = {
						name: this.statuses[s],
						checked: status.indexOf(s) >= 0 ? true : false
					};
				}
				this.status_tabs = status_tabs;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			},
			async handleCandidate (params) {
				const res = await this.$http.get(this.$api.URI_POSITIONS_CANDIDATES, {params, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getCandidates(this.params);
				this.$message.success(msg);
			},
			onDelete (r) {
				if (r.employees_count) return this.$message.error(`还有 ${r.employees_count} 人在使用`);
				this.handleCandidate({action: 'delete', id: r.id});
			}
		},
		data() {
			return {
				employees: [],
				candidates: [],
				status_tabs: [],
				recruitments: [],
				show_fillter: false,
				counts: {},
				params: {
					status: [],
					perPage: 10,
				}
			}
		},
		created () {
			this.getCandidates(this.params, true);
		}
	};
</script>