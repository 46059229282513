<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input placeholder="键名 OR 键值" v-model="params.keyword" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.models" placeholder="请选择模型" style="width: 100%;" filterable multiple collapse-tags>
						<el-option v-for="(model, m) in dict_models" :key="m" :label="model" :value="m"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getDictionaries({ ...params, page: 1})">查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table class="scroll-wrapper absolute" height="100%" :data="logs" :size="theme.size">
					<el-table-column prop="model" label="模型" :formatter="({ model }) => { return dict_models[model] || '未知'; }"></el-table-column>
					<el-table-column prop="field" label="字段" :formatter="({ model, field }) => { return dict_fields[model][field] || '未知'; }"></el-table-column>
					<el-table-column prop="name" label="键名"></el-table-column>
					<el-table-column prop="value" label="键值">
						<el-link
							type="primary"
							slot-scope="scope"
							@click="$refs['show_dictionary'].open({...scope.row})"
							:disabled="scope.row.shop_id == 0 || !$utils.update($api.URI_MODELS_DICTIONARIES)"
						>{{scope.row.value}}</el-link>
					</el-table-column>
					<el-table-column prop="updated_at" label="更新时间"></el-table-column>
					<el-table-column prop="created_at" label="创建时间"></el-table-column>
					<el-table-column label="操作" width="60">
						<template slot-scope="scope">
							<el-button type="text" :disabled="!$utils.restore($api.URI_MODELS_DICTIONARIES)" v-if="scope.row.deleted_at">恢复</el-button>
							<el-button type="text" :disabled="scope.row.shop_id == 0 || !$utils.delete($api.URI_MODELS_DICTIONARIES)" v-else>删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getDictionaries({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getDictionaries({ ...params, page: v }) }"
			background>
		</el-pagination>
		<template slot="other">
			<show-dictionary ref="show_dictionary" @refresh="getDictionaries(params)"></show-dictionary>
		</template>
	</main-table-a>
</template>


<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';
	import showDictionary from '@/pages/system/dictionaries/show';

	export default {
		components: {
			showDictionary,
			mainTableA
		},
		computed: {
			...mapState(['theme', 'admin_user', 'models']),
			model_dict () {
				if (!this.models) return {};
				return this.models.model_dictionaries || {};
			},
			dict_models () {
				return this.model_dict.models || {};
			},
			dict_fields () {
				return this.model_dict.fields || {};
			}
		},
		methods: {
			async getDictionaries (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_MODELS_DICTIONARIES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.logs = result.data;
				this.shops = result.shops;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				logs: [],
				shops: [],
				params: {
					perPage: 10,
					keywords: '',
				},
			}
		},
		async created () {
			this.getDictionaries(this.params, true)
		}
	};
</script>