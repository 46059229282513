<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.keywords" placeholder="客户/备注说明" clearable></el-input>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.type" placeholder="类型" clearable  multiple collapse-tags>
						<el-option v-for="(type, t) in fields.types" :key="t" :label="type" :value="t"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.status" placeholder="状态" clearable  multiple collapse-tags>
						<el-option v-for="(status, s) in statuses" :key="s" :label="status" :value="s"></el-option>
					</el-select>
				</el-form-item>
				<template v-if="admin_user.shop_id">
					<!-- 当角色只能看到自己客户时，此项返回数据为空，可不需要显示 -->
					<el-form-item>
						<el-cascader style="width: 100%;" placeholder="请选择所属部门" v-model="params.departments" :options="tree_departments" :props="{expandTrigger: 'hover', value: 'unique_id', label: 'name', checkStrictly: true}" filterable clearable></el-cascader>
					</el-form-item>
				</template>
				<el-form-item v-else>
					<el-select v-model="params.shop_id" placeholder="城市站点" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<!-- 当角色只能看到自己客户时，此项返回数据为空，可不需要显示 -->
				<el-form-item>
					<el-select v-model="params.employee_id" placeholder="业务" filterable multiple collapse-tags>
						<el-option v-for="employee in employees" :key="employee.id" :label="employee.name" :value="employee.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select slot="label" v-model="params.date_type" placeholder="请选择时间类型" filterable clearable>
						<el-option label="创建时间" value="created"></el-option>
						<el-option label="更新时间" value="updated"></el-option>
						<el-option label="下次跟进" value="next"></el-option>
					</el-select>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getClientsFollows({ ...params, page: 1})" plain>查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table class="scroll-wrapper absolute" height="100%" :data="clients_follows" :size="theme.size">
					<el-table-column label="业务">
						<template slot-scope="scope">
							<p><i class="el-icon-user"></i> {{scope.row.employee.name}}</p>
							<!-- <p v-if="admin_user.shop_id"><i class="el-icon-office-building"></i> {{scope.row | format_department(oa_users, oa_departments)}}</p>
							<p v-else><i class="el-icon-office-building"></i> {{scope.row.client.shop ? scope.row.client.shop.name : theme.group}}</p> -->
						</template>
					</el-table-column>
					<el-table-column label="客户" prop="client.name">
						<el-link slot-scope="scope" type="primary" @click="$refs['show_client'].open({id: scope.row.client_id})"><i class="el-icon-user"></i> {{scope.row.client.name}}</el-link>
					</el-table-column>
					<el-table-column label="类型" min-width="60" :formatter="(r) => { return fields.types[r.type] || '未知'; }"></el-table-column>
					<el-table-column label="状态" min-width="60" :formatter="(r) => { return statuses[r.status]; }"></el-table-column>
					<el-table-column label="备注说明" prop="comment" min-width="200" show-overflow-tooltip></el-table-column>
					<el-table-column label="下次跟进" prop="next_at" min-width="120"></el-table-column>
					<el-table-column label="更新时间" prop="updated_at" min-width="120" v-if="theme.width >= 1240"></el-table-column>
					<el-table-column label="创建时间" prop="created_at" min-width="120" v-if="theme.width >= 1240"></el-table-column>
					<el-table-column label="操作" width="80">
						<template slot-scope="scope">
							<el-button type="text" @click="onDelete(scope.row)" :disabled="!$utils.delete('clients/follows') || scope.row.deleted_at">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:layout="theme.is_mobile ? 'total, prev, pager, next' : $config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			@size-change="(v) => { getClientsFollows({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getClientsFollows({ ...params, page: v }) }"
			background>
		</el-pagination>
		<template slot="other">
			<show-client ref="show_client"></show-client>
		</template>
	</main-table-a>
</template>

<style>

</style>

<script>
	import { mapState } from 'vuex';
	import showClient from './clients/show';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA,
			showClient
		},
		computed: {
			...mapState(['theme', 'admin_user', 'oa_users', 'oa_departments', 'models']),
			tree_departments () {
				return this.$utils.buildTree(Object.values(this.oa_departments));
			},
			fields () {
				return this.models.client_follows || {};
			},
			statuses () {
				const { statuses } = this.models.clients;
				return statuses || []
			}
		},
		filters: {
			format_user ({ user }, users) {
				if (!user) return '公海';
				if (!users[user.id]) return user.name;
				return users[user.id].name;
			},
			format_department ({ user }, users, departments) {
				if (!user) return '公海';
				if (!users[user.id]) return '未关联OA';
				const { main_department } = users[user.id];
				if (!departments[main_department]) return '未知部门';
				return departments[main_department].name;
			}
		},
		methods: {
			onDelete (r) {
				this.$confirm(r.comment, '确定要删除【'+r.client.name+'】跟进记录吗？', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					const res = await this.$http.get(this.$api.URI_CLIENTS_FOLLOWS, {params: {action: 'delete', id: r.id}, headers: {loading: true}});
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.getClientsFollows(this.params);
					this.$message.success(msg);
				})
			},
			async getClientsFollows (params, l = false) {
				const res = await this.$http.get(this.$api.URI_CLIENTS_FOLLOWS, {params, headers: {loading: l}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.clients_follows = result.data;
				this.shops = result.shops;
				this.employees = result.employees;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				shops: [],
				employees: [],
				clients_follows: [],
				params: {
					perPage: 10,
					keywords: ''
				}
			}
		},
		async created () {
			this.getClientsFollows(this.params, true);
		}
	};
</script>