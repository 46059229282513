<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<el-form :inline="true" :model="params" :size="theme.size">
				<el-form-item>
					<el-input v-model="params.name" placeholder="请输入流水号/标题" clearable></el-input>
				</el-form-item>
				<el-form-item v-if="!admin_user.shop_id">
					<el-select v-model="params.shop_id" placeholder="门店" filterable clearable>
						<el-option v-for="shop in shops" :key="shop.id" :label="shop.name" :value="shop.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.user_id" placeholder="请选择用户" filterable clearable>
						<el-option v-for="user in users" :key="user.id" :label="user.nickname" :value="user.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-select v-model="params.type" placeholder="请选择类型" filterable clearable>
						<el-option v-for="(type, t) in types" :key="t" :label="type" :value="t"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-date-picker v-model="params.date" :picker-options="$config.DATE_PICKER" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getScores({ ...params, page: 1})" plain>查询</el-button>
				</el-form-item>
			</el-form>
			<div class="f1 pr">
				<el-table ref="scoreTable" class="scroll-wrapper absolute" height="100%" :data="scores" :size="theme.size">
					<el-table-column fixed="left" type="selection" width="42"></el-table-column>
					<el-table-column prop="no" label="流水号"></el-table-column>
					<el-table-column prop="wallet.user.name" label="用户">
						<template slot-scope="scope">{{scope.row.wallet.user.name}}<template v-if="!admin_user.shop_id">@{{scope.row.wallet.shop ? scope.row.wallet.shop.name : theme.group}}</template></template>
					</el-table-column>
					<el-table-column prop="title" label="标题"></el-table-column>
					<el-table-column prop="type_name" label="类型" width="100"></el-table-column>
					<el-table-column prop="type_change" label="收支" width="100"></el-table-column>
					<el-table-column prop="balance" label="结余" width="100"></el-table-column>
					<el-table-column prop="at" label="操作时间" width="210">
						<template slot-scope="scope">更新：{{scope.row.updated_at}}<br>创建：{{scope.row.created_at}}</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			@size-change="(v) => { getScores({ ...params, page: 1, perPage: v }) }"
			@current-change="(v) => { getScores({ ...params, page: v }) }"
			:layout="$config.PAGE_LAYOUT"
			:page-sizes="$config.PAHE_SIZES"
			:page-size="params.perPage"
			:current-page="params.page"
			:total="params.total"
			background>	
		</el-pagination>
	</main-table-a>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		computed: {
			...mapState(['theme', 'admin_user'])
		},
		methods: {
			async getScores (params, loading = false) {
				const res = await this.$http.get(this.$api.URI_SCORES, {params, headers: { loading }});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.scores = result.data;
				this.shops = result.shops;
				this.types = result.types;
				this.users = result.users;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				types: [],
				shops: [],
				users: [],
				scores: [],
				params: {
					perPage: 10
				}
			}
		},
		created () {
			this.getScores(this.params, true);
		}
	};
</script>