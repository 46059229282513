<template>
	<main-table-a>
		<div slot="table" class="f1 df fdc">
			<div class="f1 pr">
				<el-table class="scroll-wrapper absolute" height="100%" :data="goods_types" :size="theme.size">
					<el-table-column prop="name" label="类型名称"></el-table-column>
					<el-table-column prop="attr_desc" label="类型属性" :formatter="(r) => { return r.type_attributes.map((attr) => { return attr.name; }).join('_'); }"></el-table-column>
					<el-table-column prop="attr_count" label="属性数量" :formatter="(r) => { return r.type_attributes.length; }"></el-table-column>
					<el-table-column prop="goods_count" label="已关联商品"></el-table-column>
					<el-table-column prop="disabled" label="是否禁用" :formatter="(r) => { return r.disabled ? '是' : '否'; }"></el-table-column>
					<el-table-column prop="at" label="操作时间" width="210">
						<template slot-scope="scope">
							<p>更新：{{scope.row.updated_at}}</p>
							<p>创建：{{scope.row.created_at}}</p>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="更多操作" width="140">
						<el-button slot="header" type="text" icon="el-icon-plus" :disabled="!$utils.create('goods/types')" @click="type_dialog = true">新增</el-button>
						<template slot-scope="scope" v-if="scope.row.id">
							<template v-if="!scope.row.deleted_at">
								<el-button type="text" :disabled="!$utils.update('goods/types')" @click="type_form = {...scope.row}; type_dialog = true;">编辑</el-button>
								<el-button type="text" class="mr10" @click="getTypeAttr(scope.row)" :disabled="!$utils.update('goods/types')">属性</el-button>
								<el-popconfirm title="确定要删除这条记录吗？" @confirm="handleType('delete', scope.row)">
									<el-button slot="reference" type="text" :disabled="!$utils.delete('goods/types')">删除</el-button>
								</el-popconfirm>
							</template>
							<el-popconfirm title="确定要恢复这条记录吗？"  @confirm="handleType('restore', scope.row)" v-else>
								<el-button slot="reference" type="text" :disabled="!$utils.restore('goods/types')">恢复</el-button>
							</el-popconfirm>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<el-pagination
			slot="footer"
			:total="params.total"
			:layout="$config.PAGE_LAYOUT"
			:page-size="params.perPage"
			:page-sizes="$config.PAHE_SIZES"
			:current-page="params.page"
			@size-change="(v) => { getGoodsTypes({...params, page: 1,  perPage: v}) }"
			@current-change="(v) => { getGoodsTypes({ ...params, page: v}) }"
			background>
		</el-pagination>
		<template slot="other">
			<el-drawer class="scrollbar-wrapper" :size="theme.width <= 720 ? '100%' : '720px'" :title="'【'+goods_type.name+'】类型属性'" :visible.sync="attributes_drawer" @closed="goods_type = {}">
				<el-scrollbar :style="'height: ' + (theme.height - 140) + 'px' ">
					<el-alert title="笛卡尔积一般指笛卡尔乘积" type="success" description="笛卡尔乘积是指在数学中，两个集合X和Y的笛卡尔积，又称直积，表示为X × Y，第一个对象是X的成员而第二个对象是Y的所有可能有序对的其中一个成员。"></el-alert>
					<el-table :data="goods_type.type_attributes" :size="theme.size">
						<el-table-column prop="name" label="属性名" min-width="100" fixed="left"></el-table-column>
						<el-table-column prop="cross" label="笛卡尔积" width="80" :formatter="(r) => { return r.cross ? '是' : '否'; }"></el-table-column>
						<el-table-column prop="required" label="是否必选" width="80" :formatter="(r) => { return r.required ? '是' : '否'; }"></el-table-column>
						<el-table-column prop="multiple" label="是否多选" width="80" :formatter="(r) => { return r.multiple ? '是' : '否'; }"></el-table-column>
						<el-table-column prop="maximum" label="最大选择数" width="80"></el-table-column>
						<el-table-column prop="sort" label="排序" width="60"></el-table-column>
						<el-table-column prop="sort" label="关联值" width="60"></el-table-column>
						<el-table-column prop="name" label="操作" width="140" fixed="right">
							<el-button slot="header" type="text" icon="el-icon-plus" @click="addTypeAttr({common_model_type: 'App\\Models\\GoodsType', common_model_id: goods_type.id, count: goods_type.type_attributes.length})" :disabled="!$utils.create('type/attributes')">新增</el-button>
							<template slot-scope="scope">
								<el-popconfirm title="确定要恢复这条记录吗？" v-if="scope.row.deleted_at" @confirm="handleTypeAttr('restore', scope.row)">
									<el-button slot="reference" type="text" icon="el-icon-refresh-left" :disabled="!$utils.restore('type/attributes')">恢复</el-button>
								</el-popconfirm>
								<template v-else>
									<el-popconfirm title="确定要删除这条记录吗？" @confirm="handleTypeAttr('delete', scope.row)">
										<el-button slot="reference" type="text" icon="el-icon-delete" :disabled="!$utils.delete('type/attributes')">删除</el-button>
									</el-popconfirm>
									<el-button type="text" class="ml10" icon="el-icon-edit-outline" @click="addTypeAttr(scope.row)" :disabled="!$utils.update('type/attributes')">编辑</el-button>
								</template>
							</template>
						</el-table-column>
					</el-table>
				</el-scrollbar>
			</el-drawer>
			<!-- 类型名 -->
			<el-dialog title="类型名" width="520px" :visible.sync="type_dialog" @closed="type_form = {}">
				<el-form label-width="100px" ref="type_form" :model="type_form" :size="theme.size" status-icon>
					<el-form-item label="类型名" prop="name" :rules="[{ required: true, message: '必须填写类型名', trigger: 'blur'}]">
						<el-input v-model="type_form.name" placeholder="请输入类型名"></el-input>
					</el-form-item>
					<el-form-item label="是否禁用" prop="disabled">
						<el-switch :active-value="1" :inactive-value="0" active-text="是" inactive-text="否" v-model="type_form.disabled"></el-switch>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('type_form')">确 定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<!-- 属性名 -->
			<el-dialog title="属性名" width="520px" :visible.sync="add_attribute_name_dialog">
				<el-form label-width="100px" ref="add_attribute_name_form" :model="add_attribute_name_form" :size="theme.size" status-icon>
					<el-form-item label="属性名" prop="name" :rules="[{ required: true, message: '必须填写属性名', trigger: 'blur'}]">
						<el-input v-model="add_attribute_name_form.name" placeholder="请输入属性名"></el-input>
					</el-form-item>
					<el-form-item label="笛卡尔积" prop="cross">
						<el-switch :active-value="1" :inactive-value="0" active-text="是" inactive-text="否" v-model="add_attribute_name_form.cross"></el-switch>
					</el-form-item>
					<template v-if="!add_attribute_name_form.cross">
						<el-form-item label="是否必选" prop="required">
							<el-switch :active-value="1" :inactive-value="0" active-text="是" inactive-text="否" v-model="add_attribute_name_form.required"></el-switch>
						</el-form-item>
						<el-form-item label="是否多选" prop="multiple">
							<el-switch :active-value="1" :inactive-value="0" active-text="是" inactive-text="否" v-model="add_attribute_name_form.multiple"></el-switch>
						</el-form-item>
						<template v-if="add_attribute_name_form.multiple">
							<el-form-item label="最小选择数" prop="minimum">
								<el-input v-model.number="add_attribute_name_form.minimum" :min="0" :max="add_attribute_name_form.maximum" placeholder="最小选择数"></el-input>
							</el-form-item>
							<el-form-item label="最大选择数" prop="maximum">
								<el-input v-model.number="add_attribute_name_form.maximum" :mini="add_attribute_name_form.minimum" placeholder="最大选择数"></el-input>
							</el-form-item>
						</template>
						<el-form-item label="排序" prop="sort">
							<el-input v-model.number="add_attribute_name_form.sort" placeholder="排序"></el-input>
						</el-form-item>
					</template>
					<el-form-item>
						<el-button type="primary" @click="submitForm('add_attribute_name_form')">确 定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</template>
	</main-table-a>
</template>

<script>
	import { mapState } from 'vuex';
	import mainTableA from '@/components/main-table-a';

	export default {
		components: {
			mainTableA
		},
		computed: {
			...mapState(['theme'])
		},
		methods: {
			async submitType (data) {
				if (!data.name) return this.$message.error('必须填写类型名称');
				const res = await this.$http.post(this.$api.URI_GOODS_TYPES, data);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.getGoodsTypes(this.params);
						this.type_dialog = false;
					}
				});
			},
			submitForm (r) {
				this.$refs[r].validate(async (valid) => {
					if (!valid) return this.$message.error("表单提交有误！");
					switch (r) {
						case 'type_form':
							this.submitType(this.type_form);
						break;
						case 'add_attribute_name_form':
							this.submitTypeAttr(this.add_attribute_name_form);
						break;
					}
				});
			},
			async submitTypeAttr (r) {
				if (!r.name) return this.$message.error('必须填写属性名称');
				const res = await this.$http.post(this.$api.URI_TYPE_ATTRIBUTES, r);
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message({
					type: 'success',
					duration: 1000,
					message: msg,
					onClose: () => {
						this.getTypeAttr({id: r.common_model_id});
						this.getGoodsTypes(this.params);
						this.add_attribute_name_dialog = false;
					}
				});
			},
			async handleType (a, r) {
				const res = await this.$http.get(this.$api.URI_GOODS_TYPES, {params: {action: a, id: r.id}, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.$message.success(msg);
				this.getGoodsTypes(this.params);
			},
			addTypeAttr (r) {
				if (r.count >= 6) return this.$message.error('添加类型属性已达最大限制');
				this.add_attribute_name_form = { ...r };
				this.add_attribute_name_dialog = true;
			},
			async handleTypeAttr (a, r) {
				const res = await this.$http.get(this.$api.URI_TYPE_ATTRIBUTES, {params: {action: a, id: r.id}, headers: {loading: true}});
				const { code, msg } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.getTypeAttr({id: r.common_model_id});
				this.getGoodsTypes(this.params);
				this.$message.success(msg);
			},
			async getTypeAttr (r) {
				const res = await this.$http.get(this.$api.URI_GOODS_TYPES, {params: {action: 'update', id: r.id}});
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.attributes_drawer = true;
				this.goods_type = result;
			},
			async getGoodsTypes (params) {
				const res = await this.$http.get(this.$api.URI_GOODS_TYPES, { params });
				const { code, msg, result } = res.data;
				if (code != 0) return this.$message.error(msg);
				this.goods_types = result.data;
				this.params = {
					...params,
					total: result.total,
					perPage: parseInt(result.per_page)
				}
			}
		},
		data() {
			return {
				goods_types: [],
				goods_type: {},
				type_form: {},
				add_attribute_name_form: {},
				type_dialog: false,
				attributes_drawer: false,
				add_attribute_name_dialog: false,
				params: {
					perPage: 10,
					type: []
				}
			}
		},
		async created () {
			this.getGoodsTypes(this.params);
		}
	};
</script>